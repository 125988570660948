import { Injectable } from '@angular/core';
import { EnvironmentService } from '../environment/environment.service';
import { Router } from '@angular/router';
import { EmbeddedRouter } from 'src/app/easyvax-embed/embedded-router';
import { getEmbedded } from '../../constants/general';

@Injectable({
  providedIn: 'root',
})
export class RouteService {
  get campaignId(): string {
    const { campaignId } = this.environmentService;
    return campaignId;
  }

  constructor(
    private environmentService: EnvironmentService,
    private router: Router,
    private embeddedRouter: EmbeddedRouter
  ) {}

  getRouter() {
    const isEmbedded = getEmbedded();

    if (isEmbedded) {
      return this.embeddedRouter;
    } else {
      return this.router;
    }
  }

  /**
   * Location/Appointment Search screen
   * @returns
   */
  getAppointmentsRoute(): string {
    return `${this.campaignId}/appointments`;
  }

  /**
   * Coadmin vaccine selection screen
   * @returns
   */
  getCoadminRoute(): string {
    return `${this.campaignId}/coadmin`;
  }

  /**
   * Config error screen
   * @returns
   */
  getConfigErrorRoute(): string {
    return `${this.campaignId}/config-error`;
  }

  /**
   * Reservation confirmation screen
   * @returns
   */
  getConfirmationRoute(): string {
    return `${this.campaignId}/confirmation`;
  }

  /**
   *
   * @param locationId
   * @returns
   */
  getLocationDetailsRoute(locationId: string): string {
    return `${this.campaignId}/appointments/${locationId}`;
  }

  /**
   * Reservation form screen
   * @returns
   */
  getReservationRoute(): string {
    return `${this.campaignId}/reservation`;
  }

  /**
   * Reservation error screen
   * @returns
   */
  getReservationErrorRoute(): string {
    return `${this.campaignId}/reservation-error`;
  }

  /**
   * Reservation Review screen
   * @returns
   */
  getReviewRoute(): string {
    return `${this.campaignId}/review`;
  }

  /**
   * Selecting a vaccine type
   * @returns
   */
  getServicesRoute(): string {
    return `${this.campaignId}`;
  }

  /**
   * Selecting a vaccine brand
   * @returns
   */
  getVaccinesRoute(): string {
    return `${this.campaignId}/vaccines`;
  }
}
