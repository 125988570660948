import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LocationIntegrationLevelBaseComponent } from '../location-integration-level-base/location-integration-level-base.component';

@Component({
  selector: 'app-location-integration-level-two',
  standalone: true,
  imports: [CommonModule, LocationIntegrationLevelBaseComponent, TranslateModule],
  templateUrl: './location-integration-level-two.component.html',
  styleUrls: ['./location-integration-level-two.component.scss'],
})
export class LocationIntegrationLevelTwoComponent extends LocationIntegrationLevelBaseComponent {}
