import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { AlertModule } from 'ngx-bootstrap/alert';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { VaccineEligibilityService } from 'src/app/common/services/vaccine-eligibility/vaccine-eligibility.service';
import { transition, trigger, useAnimation } from '@angular/animations';
import { FADE_IN_ANIMATION, FADE_OUT_ANIMATION } from 'src/app/common/constants/animations';
import { Service } from 'src/app/common/model/service';
import { AnalyticsEvent } from 'src/app/common/model/event';
import { DateTime } from 'luxon';
import { RouteService } from 'src/app/common/services/route/route.service';
import { EventService } from 'src/app/common/services/event/event.service';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { AppointmentService } from 'src/app/common/services/appointment/appointment.service';
import { getEmbedded } from 'src/app/common/constants/general';
import { DEFAULT_START_ROUTE } from 'src/app/app.routes';
import { AppInitService } from 'src/app/app-init.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { CurrentScreenType } from 'src/app/common/types/current-screen-type';

@Component({
  selector: 'app-vaccine-eligibility-toast',
  standalone: true,
  imports: [CommonModule, AlertModule, TranslateModule],
  templateUrl: './vaccine-eligibility-toast.component.html',
  styleUrls: ['./vaccine-eligibility-toast.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition(':enter', [useAnimation(FADE_IN_ANIMATION)]),
      transition(':leave', [useAnimation(FADE_OUT_ANIMATION)]),
    ]),
  ],
})
export class VaccineEligibilityToastComponent {
  @Input() currentScreen: CurrentScreenType = 'vaccine-picker';
  @Output() isLoading = new EventEmitter(false);

  loadingObj: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loading$: Observable<boolean>;

  get isEligibilityToastOpen(): boolean {
    return this.vaccineEligibilityService.isEligibilityToastOpen;
  }

  set loading(isLoading: boolean) {
    this.loadingObj.next(isLoading);
    this.isLoading.emit(isLoading);
  }

  get loading(): boolean {
    return this.loadingObj.getValue();
  }

  get vaccineCount(): number | undefined {
    return this.vaccineEligibilityService.vaccineEligibilityResponse?.recommendations.length;
  }

  constructor(
    private vaccineEligibilityService: VaccineEligibilityService,
    private translateService: TranslateService,
    private routeService: RouteService,
    private eventService: EventService,
    private environmentService: EnvironmentService,
    private appointmentService: AppointmentService,
    private location: Location,
    private appInitService: AppInitService
  ) {
    this.loading$ = this.loadingObj.asObservable();
  }

  dismissToast() {
    this.vaccineEligibilityService.isEligibilityToastOpen = false;
  }

  getTranslatedRecommendations(): Array<{ translatedName: string; rec: Service }> {
    const recommendations =
      this.vaccineEligibilityService.vaccineEligibilityResponse?.recommendations;

    if (!recommendations || recommendations.length === 0) {
      return [];
    }

    return recommendations
      .filter((rec) => !!this.translateService.instant(rec.serviceNameTC)) // Filter out untranslated names
      .map((rec) => ({
        translatedName: this.translateService.instant(rec.serviceNameTC), // Translate serviceNameTC
        rec, // Include the original `rec`
      }));
  }

  async handleServiceClick(service: Service) {
    this.loading = true;

    // Set the selected service for the analytics event
    this.environmentService.selectedService = service;
    // if we only have 1 vaccine for the selectedService pre-select it and skip vaccine selection
    if (service?.vaccines.length === 1) {
      this.environmentService.selectedVaccine = service?.vaccines[0];
    }

    // Send analytics event
    const eventTimestamp = DateTime.local().toUTC().toString();
    const analyticsEvent: AnalyticsEvent = {
      eventType: 'get-eligibility',
      eventTimestamp: eventTimestamp,
      trigger: 'eligibility-toast-navigation',
    };
    this.eventService.setAnalyticsEvent(analyticsEvent);
    this.eventService.sendAnalyticsEvent();

    // Handle confirmation screen requirements
    if (this.currentScreen === 'appointment-confirmation') {
      // create a new session for the user after leaving confirmation
      this.environmentService.recreateUserId();
      // clear current saved reservation
      this.appointmentService.reservation = undefined;
    }

    // if this is an embedded experience, we don't want to redirect the user to the default route
    // they have to stay on the same campaign
    if (!getEmbedded()) {
      // clear current campaign state since we're defaulting to 0
      this.environmentService.configuration = null;
      this.environmentService.selectedService = undefined;
      this.environmentService.selectedVaccine = undefined;

      this.environmentService.campaignId = DEFAULT_START_ROUTE;

      // update the path in the url so when we configure in the next step we're routed properly to default
      this.location.replaceState(`${DEFAULT_START_ROUTE}`);
    }

    // init the app again because we have to call the config service again since we are switching campaigns
    let path = '';

    try {
      await this.appInitService
        .init(false)
        .catch((error) => {
          this.loading = false;
        })
        .then(() => {
          this.appInitService.configureUrl();
          // reconfigure selected vaccines so the routes are correct
          this.environmentService.selectedService = service;
          path = this.routeService.getVaccinesRoute();
          if (service?.vaccines.length === 1) {
            this.environmentService.selectedVaccine = service?.vaccines[0];
            path = this.routeService.getAppointmentsRoute();
          }

          this.loading = false;
        });
    } catch (error) {
      this.loading = false;
    }

    if (path) {
      const router = this.routeService.getRouter();
      router.navigate([path]);
    }
  }
}
