/* eslint-disable no-irregular-whitespace */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, concatMap, Observable, of, retry, throwError, timer } from 'rxjs';
import {
  AppointmentHoldRequest,
  AppointmentHoldResponse,
  AppointmentReservation,
  AppointmentSelection,
  AppointmentTimesResponse,
} from '../../model/appointment';
import { Location } from '../../model/location';
import { EnvironmentService } from '../environment/environment.service';
import {
  API_RETRY_LIMIT,
  getEmbedded,
  getEmbeddedAssetsUrl,
  ServiceErrorCodes,
} from '../../constants/general';
import { DateUtil } from '../../utils/date';
import { ModalType } from '../../types/modal-type';
import { Address } from '../../model/address';
import { DateTime } from 'luxon';
import { arraysAreEqualIgnoreOrder } from '../../utils/general';
import { Service } from '../../model/service';
import { Vaccine } from '../../model/vaccine';
import { VaxError, VaxErrorDetail } from '../../model/error';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AppointmentService {
  dateTimePickerShowAllHours = false;
  isRetryForLocationGroupId: string | undefined = undefined;

  get startDate() {
    return DateUtil.currentDateYearMonthDay;
  }
  get startTime() {
    return DateUtil.nextHalfHourTime();
  }

  private _appointmentHold?: AppointmentHoldResponse;
  private _appointmentHoldRequest?: AppointmentHoldRequest;
  private _reservation?: AppointmentReservation;
  private _errors?: VaxErrorDetail[];
  private _selectedAdditionalServices: Array<Service> | undefined;
  private _selectedAdditionalVaccines: Array<Vaccine> | undefined;
  private _selectedLocation?: Location;

  get appointmentHold(): AppointmentHoldResponse | undefined {
    return this._appointmentHold;
  }

  set appointmentHold(data: AppointmentHoldResponse | undefined) {
    this._appointmentHold = data;
  }

  get appointmentHoldRequest(): AppointmentHoldRequest | undefined {
    return this._appointmentHoldRequest;
  }

  set appointmentHoldRequest(data: AppointmentHoldRequest | undefined) {
    this._appointmentHoldRequest = data;
  }

  get errors(): VaxErrorDetail[] | undefined {
    return this._errors;
  }

  set errors(value: VaxErrorDetail[] | undefined) {
    this._errors = value;
  }

  get selectedAdditionalServices(): Array<Service> | undefined {
    return this._selectedAdditionalServices;
  }

  set selectedAdditionalServices(value: Array<Service> | undefined) {
    this._selectedAdditionalServices = value;
  }

  get selectedAdditionalVaccines(): Array<Vaccine> | undefined {
    return this._selectedAdditionalVaccines;
  }

  set selectedAdditionalVaccines(value: Array<Vaccine> | undefined) {
    this._selectedAdditionalVaccines = value;
  }

  get selectedAdditionalVaccineCodes(): Array<string> | undefined {
    return this.selectedAdditionalVaccines?.flatMap((vaccine) => vaccine.vaccineCode);
  }

  setErrors(errors: VaxErrorDetail[] | undefined) {
    this.errors = errors;
  }

  clearAdditionalVaccines() {
    this.selectedAdditionalServices = undefined;
    this.selectedAdditionalVaccines = undefined;
  }

  clearErrors() {
    this.errors = undefined;
  }

  get reservation(): AppointmentReservation | undefined {
    return this._reservation;
  }

  set reservation(value: AppointmentReservation | undefined) {
    this._reservation = value;
  }

  get selectedAppointmentPrettyDate() {
    const date = this.selectedAppointment?.date;
    if (date) {
      // set prettyDate based on reservation date
      const formattedDate = DateUtil.formatDateIsoToYearMonthDay(date);
      return DateUtil.formatDateLong(formattedDate);
    }
    return '';
  }

  get selectedAppointmentPrettyTime() {
    const time = this.selectedAppointment?.time;
    if (time) {
      // set prettyTime based on reservation date
      const formattedTime = DateUtil.formatTime(time);
      const localTimeZone = DateUtil.localTimeZoneAbbr;
      return `${formattedTime} ${localTimeZone}`;
    }
    return '';
  }

  private _selectedAppointment?: AppointmentSelection;

  get selectedAppointment(): AppointmentSelection | undefined {
    return this._selectedAppointment;
  }

  set selectedAppointment(value: AppointmentSelection | undefined) {
    if (
      value == undefined ||
      value.location.locationGroupId !== this._selectedAppointment?.location.locationGroupId
    ) {
      this.clearAdditionalVaccines();
    }
    this._selectedAppointment = value;
  }

  get selectedLocation(): Location | undefined {
    return this._selectedLocation;
  }

  set selectedLocation(value: Location | undefined) {
    this._selectedLocation = value;
  }

  constructor(
    private http: HttpClient,
    private environmentService: EnvironmentService,
    private translateService: TranslateService
  ) {}

  /**
   * Returns the list of coadmin vaccines available for the selected campaign and location
   * @returns
   */
  getCoadminVaccines(locationId: string) {
    const { apiBaseUrl, campaignId } = this.environmentService;
    const url = `${apiBaseUrl}/coadmin`;
    const payload = {
      campaignId,
      locationId,
    };

    let retryCount = 0;

    return this.http.post<any>(url, payload).pipe(
      catchError((error, caught) => {
        if (error.status === 500 && retryCount < API_RETRY_LIMIT) {
          retryCount++;
          console.warn(`Retrying coadmin... Attempt ${retryCount}`);
          return timer(1000).pipe(concatMap(() => caught));
        }

        console.error('Max retries reached or non-500 error encountered.');
        return throwError(() => error);
      })
    );
  }

  /**
   * Given a partial address provided by the user, will return a list of possible matching addresses.
   * Used for the appointment search typeahead feature
   * @param qry The address or partial address for which to search
   * @returns
   */
  getAddresses(qry: string | null): Observable<Address[]> {
    // Return an empty array if the query is empty or null
    if (!qry) {
      return of([]);
    }

    const { apiBaseUrl } = this.environmentService;
    const url = `${apiBaseUrl}/addresses?qry=${encodeURIComponent(qry)}`;

    let retryCount = 0;

    return this.http.get<any>(url).pipe(
      catchError((error, caught) => {
        if (error.status === 500 && retryCount < API_RETRY_LIMIT) {
          retryCount++;
          console.warn(`Retrying addresses... Attempt ${retryCount}`);
          return timer(1000).pipe(concatMap(() => caught));
        }

        console.error('Max retries reached or non-500 error encountered.');
        return throwError(() => error);
      })
    );
  }

  getAppointmentTimes(
    locationId: string,
    endDate?: string | undefined
  ): Observable<AppointmentTimesResponse> {
    const { startDate } = this;
    const { apiBaseUrl, campaignId, selectedService, selectedVaccine } = this.environmentService;
    let url = `${apiBaseUrl}/locations/${locationId}/appointments?campaignId=${campaignId}`;

    if (selectedService?.serviceCode) {
      url += `&serviceCode=${selectedService.serviceCode}`;
    }

    if (selectedVaccine?.vaccineCode) {
      url += `&vaccineCode=${selectedVaccine.vaccineCode}`;
    }

    // set the startDate
    const formattedStartDate = DateUtil.formatDateStartOfDay(startDate);
    url += `&startDate=${formattedStartDate}`;

    // set the endDate to the end of the startDate's day
    if (endDate) {
      const formattedEndDate = DateUtil.formatDateEndOfDay(endDate);
      url += `&endDate=${formattedEndDate}`;
    } else {
      const formattedEndDate = DateUtil.formatDateEndOfDay(startDate);
      url += `&endDate=${formattedEndDate}`;
    }

    let retryCount = 0;

    return this.http.get<any>(url).pipe(
      catchError((error, caught) => {
        if (error.status === 500 && retryCount < API_RETRY_LIMIT) {
          retryCount++;
          console.warn(`Retrying appointment times... Attempt ${retryCount}`);
          return timer(1000).pipe(concatMap(() => caught));
        }

        console.error('Max retries reached or non-500 error encountered.');
        return throwError(() => error);
      })
    );
  }

  getCurrentLocation(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!('geolocation' in navigator)) {
        reject(new Error('Geolocation is not supported by this browser.'));
        return;
      }

      navigator.permissions
        .query({ name: 'geolocation' })
        .then((permissionStatus) => {
          if (permissionStatus.state === 'granted' || permissionStatus.state === 'prompt') {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                resolve(position);
              },
              (error) => {
                reject(error);
              }
            );
          } else {
            reject(new Error('Location permission denied.'));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get a List of Locations that offer the requested service and or vaccines with available appointment times for each.
   *
   * Documentation - https://gskfindvax.docs.apiary.io/#reference/0/locations/location-search
   * @param zip
   * @param lat
   * @param lng
   * @param endDate
   * @returns
   */
  getLocations(address?: string | null, lat?: number, lng?: number): Observable<Array<Location>> {
    const { startDate } = this;
    const { apiBaseUrl, campaignId, selectedService, selectedVaccine } = this.environmentService;
    let url = `${apiBaseUrl}/locations?campaignId=${campaignId}`;

    if (address) {
      url += `&qry=${encodeURIComponent(address)}`;
    }

    if (lat) {
      url += `&lat=${lat}`;
    }

    if (lng) {
      url += `&lng=${lng}`;
    }

    url += `&serviceCode=${selectedService?.serviceCode}`;
    url += `&vaccineCode=${selectedVaccine?.vaccineCode}`;

    const formattedStartDate = DateUtil.formatDateStartOfDay(startDate);
    url += `&startDate=${formattedStartDate}`;

    // set the endDate to the end of the startDate's day
    const formattedEndDate = DateUtil.formatDateEndOfDay(startDate);
    url += `&endDate=${formattedEndDate}`;

    const radius = 20;
    url += `&radius=${radius}`;

    let retryCount = 0;

    return this.http.get<any>(url).pipe(
      catchError((error, caught) => {
        if (error.status === 500 && retryCount < API_RETRY_LIMIT) {
          retryCount++;
          console.warn(`Retrying locations... Attempt ${retryCount}`);
          return timer(1000).pipe(concatMap(() => caught));
        }

        console.error('Max retries reached or non-500 error encountered.');
        return throwError(() => error);
      })
    );
  }

  /**
   * Fetches HTML content based on the type
   * @returns
   */
  getHtmlBody(type: ModalType): Observable<string> {
    const { appointmentHold } = this;
    const { locale } = this.environmentService;
    let url: string | undefined;
    let error: Error;

    // TEMP - ALLOW FOR TESTING OF CORS ISSUE
    // Get the current URL
    const currentUrl = new URL(window.location.href);

    // Get the URLSearchParams object
    const params = new URLSearchParams(currentUrl.search);

    // Get the 'ct' parameter if it exists
    const ct = params.get('ct');

    if (ct) {
      console.log(`The 'ct' parameter value is: ${ct}`);
    } else {
      console.log("The 'ct' parameter does not exist.");
    }

    if (getEmbedded() && !ct) {
      const captializedLocale = locale.charAt(0).toUpperCase() + locale.slice(1);

      switch (type) {
        case 'privacy':
          return of(this.embeddedHtml['postbookingPrivacyHtml' + captializedLocale]);
        case 'terms':
          return of(this.embeddedHtml['postbookingTermsHtml' + captializedLocale]);
        case 'prebooking-privacy':
          return of(this.embeddedHtml['prebookingPrivacyHtml' + captializedLocale]);
        case 'prebooking-terms':
          return of(this.embeddedHtml['prebookingTermsHtml' + captializedLocale]);
        default:
          break;
      }
    }

    switch (type) {
      case 'privacy': {
        url = `${appointmentHold?.privacyPolicyPath}/${locale}/index.html`;
        error = new Error('Privacy Policy URL is not defined');
        break;
      }
      case 'terms': {
        url = `${appointmentHold?.termsAndConditionsPath}/${locale}/index.html`;
        error = new Error('Terms and Conditions URL is not defined');
        break;
      }
      case 'prebooking-privacy': {
        url = `/a/prebooking-privacy/${locale}/index.html`;
        error = new Error('Pre-Booking Privacy Policy URL is not defined');
        break;
      }
      case 'prebooking-terms': {
        url = `/a/prebooking-terms/${locale}/index.html`;
        error = new Error('Pre-Booking Terms & Conditions URL is not defined');
        break;
      }
      default: {
        break;
      }
    }

    // TEMP - USED TO TEST THE CORS ISSUE
    if (getEmbedded()) {
      url = `${getEmbeddedAssetsUrl()}${url}`;
    }

    if (!url) {
      return throwError(() => error);
    }
    console.log('loading resource from url:', url);
    return this.http.get(url, { responseType: 'text' });
  }

  /**
   * Holds the requested appointment and get the necessary dynamic form
   * @returns AppointmentHoldResponse
   */
  holdAppointment(): Observable<AppointmentHoldResponse | any> {
    const { apiBaseUrl, campaignId, selectedVaccine, locale } = this.environmentService;
    const { selectedAppointment, selectedAdditionalVaccineCodes } = this;

    const url = `${apiBaseUrl}/reserve/hold`;

    // determine if we need a new hold or if we can use the existing hold
    if (this.appointmentHold && this.appointmentHoldRequest) {
      // we have an existing hold, can we use it?
      // has to be the same appointmentId and vaccineCode (appointment id includes the locationId, date/time, etc.)
      const isAdditionalVaccineCodesEqual = arraysAreEqualIgnoreOrder(
        this.appointmentHoldRequest.additionalVaccineCodes,
        selectedAdditionalVaccineCodes
      );
      if (
        this.appointmentHoldRequest.appointmentId === selectedAppointment?.appointmentId &&
        this.appointmentHoldRequest.vaccineCode === selectedVaccine?.vaccineCode &&
        isAdditionalVaccineCodesEqual
      ) {
        // so far so good, there is an existing hold and the appointmentId matches
        if (DateTime.fromISO(this.appointmentHold.holdExpiration) > DateTime.now()) {
          // the hold is still valid, return the existing hold
          return of(this.appointmentHold);
        }
      }
    }

    // blank out any existing hold data
    // then get a new hold and save the request in the appointmentHoldRequest
    this.appointmentHold = undefined;
    this.appointmentHoldRequest = {
      campaignId,
      locationId: selectedAppointment?.locationId ?? '',
      date: selectedAppointment?.time ?? '',
      vaccineCode: selectedVaccine?.vaccineCode ?? '',
      additionalVaccineCodes: selectedAdditionalVaccineCodes,
      appointmentId: selectedAppointment?.appointmentId ?? '',
      locale,
    };

    let retryCount = 0;

    return this.http.put<any>(url, this.appointmentHoldRequest).pipe(
      catchError((error, caught) => {
        if (error.status === 500 && retryCount < API_RETRY_LIMIT) {
          retryCount++;
          console.warn(`Retrying hold... Attempt ${retryCount}`);
          return timer(1000).pipe(concatMap(() => caught));
        }

        console.error('Max retries reached or non-500 error encountered.');
        return throwError(() => error);
      })
    );
  }

  processError(error: VaxError): { details: VaxErrorDetail[] } {
    let msg = '';
    const errorDetail = error.details || [];
    const details: VaxErrorDetail[] = [];

    switch (error.subCode) {
      case ServiceErrorCodes.SUBCODE_VALIDATION_ERROR: {
        msg = this.translateService.instant('ERRORS.1000.MESSAGE');
        errorDetail.forEach((detail: VaxErrorDetail) => {
          const reason = detail.reason ?? '';
          details.push({
            name: detail.name,
            reason: `${msg} (${detail.name}: ${reason})`,
            displayInline: false,
          });
        });
        break;
      }
      case ServiceErrorCodes.SUBCODE_APPOINTMENT_NOT_AVAILABLE: {
        msg = this.translateService.instant('ERRORS.1001.MESSAGE');
        errorDetail.forEach((detail: VaxErrorDetail) => {
          details.push({
            name: detail.name,
            reason: msg,
            displayInline: false,
          });
        });
        break;
      }
      case ServiceErrorCodes.SUBCODE_RETAILER_ERROR: {
        msg = this.translateService.instant('ERRORS.1002.MESSAGE');
        errorDetail.forEach((detail: VaxErrorDetail) => {
          const reason = detail.reason ?? '';
          details.push({
            name: detail.name,
            reason: `${msg} (${detail.name}: ${reason})`,
            displayInline: false,
          });
        });
        break;
      }
      case ServiceErrorCodes.SUBCODE_RECAPTCHA_ERROR: {
        msg = this.translateService.instant('ERRORS.1004.MESSAGE');
        errorDetail.forEach((detail: VaxErrorDetail) => {
          details.push({
            name: detail.name,
            reason: msg,
            displayInline: false,
          });
        });
        break;
      }
      case ServiceErrorCodes.SUBCODE_PRESCRIPTION_REQUIRED_ERROR: {
        // We receive vaccineCode's but we need to translate those
        const vaccineCode1TC = `VACCINE.${error.vaccineCode1}`;
        const vaccineName1 = this.translateService.instant(vaccineCode1TC);

        // Set message to the translated message and translated vaccineCodes
        msg = this.translateService.instant('ERRORS.1005.MESSAGE', {
          vaccineName1: vaccineName1 || '',
        });
        errorDetail.forEach((detail: VaxErrorDetail) => {
          details.push({
            name: detail.name,
            reason: msg,
            displayInline: true,
          });
        });
        break;
      }
      case ServiceErrorCodes.SUBCODE_SEASONAL_VACCINE_NOT_AVAILABLE: {
        // We receive vaccineCode's but we need to translate those
        const vaccineCode1TC = `VACCINE.${error.vaccineCode1}`;
        const vaccineName1 = this.translateService.instant(vaccineCode1TC);

        // Set message to the translated message and translated vaccineCodes
        msg = this.translateService.instant('ERRORS.1006.MESSAGE', {
          vaccineName1: vaccineName1 || '',
        });
        errorDetail.forEach((detail: VaxErrorDetail) => {
          details.push({
            name: detail.name,
            reason: msg,
            displayInline: false,
          });
        });
        break;
      }
      case ServiceErrorCodes.SUBCODE_PATIENT_AGE_RESTRICTION: {
        // We receive vaccineCode's but we need to translate those
        const vaccineCode1TC = `VACCINE.${error.vaccineCode1}`;
        const vaccineName1 = this.translateService.instant(vaccineCode1TC);
        const minAge = error.minAge || 0;
        const maxAge = error.maxAge || 100;

        // Set message to the translated message and translated vaccineCodes
        msg = this.translateService.instant('ERRORS.1007.MESSAGE', {
          minAge: minAge,
          maxAge: maxAge,
          vaccineName1: vaccineName1 || '',
        });

        errorDetail.forEach((detail: VaxErrorDetail) => {
          details.push({
            name: detail.name,
            reason: msg,
            displayInline: false,
          });
        });
        break;
      }
      case ServiceErrorCodes.SUBCODE_PATIENT_INELIGIBLE: {
        msg = this.translateService.instant('ERRORS.1008.MESSAGE');
        errorDetail.forEach((detail: VaxErrorDetail) => {
          details.push({
            name: detail.name,
            reason: msg,
            displayInline: false,
          });
        });
        break;
      }
      case ServiceErrorCodes.SUBCODE_INCOMPATIBLE_VACCINES: {
        // We receive vaccineCode's but we need to translate those
        const vaccineCode1 = `VACCINE.${error.vaccineCode1}`;
        const vaccineCode2 = `VACCINE.${error.vaccineCode2}`;
        const vaccineName1 = this.translateService.instant(vaccineCode1);
        const vaccineName2 = this.translateService.instant(vaccineCode2);

        // Set message to the translated message and translated vaccineCodes
        msg = this.translateService.instant('ERRORS.1009.MESSAGE', {
          vaccineName1: vaccineName1 || '',
          vaccineName2: vaccineName2 || '',
        });
        errorDetail.forEach((detail: VaxErrorDetail) => {
          details.push({
            name: detail.name,
            reason: msg,
            displayInline: false,
          });
        });
        break;
      }
      default: {
        errorDetail.forEach((detail: VaxErrorDetail) => {
          const reason = detail.reason ?? '';
          details.push({
            name: detail.name,
            reason: reason,
            displayInline: false,
          });
        });
        break;
      }
    }

    return { details };
  }

  reserveAppointment() {
    const { apiBaseUrl } = this.environmentService;
    const { reservation } = this;
    const url = `${apiBaseUrl}/reserve`;

    let retryCount = 0;

    return this.http.put<any>(url, reservation).pipe(
      catchError((error, caught) => {
        if (error.status === 500 && retryCount < API_RETRY_LIMIT) {
          retryCount++;
          console.warn(`Retrying reserve... Attempt ${retryCount}`);
          return timer(1000).pipe(concatMap(() => caught));
        }

        console.error('Max retries reached or non-500 error encountered.');
        return throwError(() => error);
      })
    );
  }

  reserveValidateAppointment() {
    const { apiBaseUrl } = this.environmentService;
    const { reservation } = this;
    const url = `${apiBaseUrl}/reserve/validate`;

    let retryCount = 0;

    return this.http.put<any>(url, reservation).pipe(
      catchError((error, caught) => {
        if (error.status === 500 && retryCount < API_RETRY_LIMIT) {
          retryCount++;
          console.warn(`Retrying reserve/validate... Attempt ${retryCount}`);
          return timer(1000).pipe(concatMap(() => caught));
        }

        console.error('Max retries reached or non-500 error encountered.');
        return throwError(() => error);
      })
    );
  }

  selectAppointment(appointment: AppointmentSelection) {
    this.selectedAppointment = appointment;
  }

  clearRetryState() {
    this.isRetryForLocationGroupId = undefined;
  }

  setRetryState(locationGroupId: string | undefined) {
    this.isRetryForLocationGroupId = locationGroupId;
  }

  isInRetryState() {
    return !!this.isRetryForLocationGroupId;
  }

  updateExistingReservation(
    appointment: AppointmentSelection,
    appointmentHold?: AppointmentHoldResponse
  ) {
    if (appointmentHold) {
      // set the new appointment hold data that will be used for the reservation
      this.appointmentHold = appointmentHold;

      // update the existing reservation
      if (this.reservation) {
        this.reservation.locationId = appointment.locationId;
        this.reservation.date = appointment.time;
        this.reservation.appointmentId = appointment.appointmentId;
        this.reservation.holdId = appointmentHold.holdId;
        this.reservation.vaccineCode = appointmentHold.vaccineCode;
        this.reservation.additionalVaccineCodes = appointmentHold.additionalVaccineCodes;
      }
    }
  }

  embeddedHtml: any = {
    prebookingPrivacyHtmlEn: `
  <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <title></title>
    <meta
      name="viewport"
      content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
    />
  </head>
  <body>
    <h1>Privacy Notice</h1>
    <p>Effective Date: January 24, 2024</p>
    <p>
      Use of the EasyVax scheduler (“EasyVax”) is subject to our
      <a id="prebooking-terms" href="/a/prebooking-terms">Terms of Use</a>. By providing your information in EasyVax you
      acknowledge that EasyVax collects only the information needed for EasyVax to locate pharmacies close to the
      location you have provided that administer the vaccine(s) you have selected. The information is used by EasyVax
      solely to register you for vaccine administration scheduling. EasyVax and its sponsors are provided with
      de-identified (no information which identifies you) data only to allow the sponsors to understand how many users
      are utilizing EasyVax and for continuous improvement. If you have questions about how your selected retailer uses
      any information it may collection, please review their privacy policies.
    </p>
  </body>
</html>
    `,
    prebookingPrivacyHtmlEs: `
    <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <title></title>
    <meta
      name="viewport"
      content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
    />
  </head>
  <body>
    <h1>Aviso de prácticas de privacidad</h1>
    <p>Fecha de entrada en vigor: 24 de enero de 2024</p>
    <p>
      El uso del programador EasyVax (en adelante, «EasyVax») está sujeto a nuestras
      <a id="prebooking-terms" href="/a/prebooking-terms">Condiciones de uso</a>. Al brindar su información en EasyVax,
      reconoce que EasyVax recopila únicamente aquella información necesaria para localizar farmacias cercanas a la
      ubicación que proporcionó como referencia y que administran la colocación de las vacunas que seleccionó. EasyVax
      usa esta información solo para registrarlo en el sistema de turnos de la administración de vacunas. EasyVax y sus
      patrocinadores reciben datos anonimizados —es decir, sin información que permita identificarlo de forma personal—
      con el único fin de permitir que los patrocinadores comprendan cuántos usuarios utilizan EasyVax, así como para
      aplicar mejoras continuas. Si tiene alguna duda acerca de cómo usa el Punto de vista minorista seleccionado
      cualquier información que haya recopilado, consulte las políticas de privacidad de ese Punto de venta minorista.
    </p>
  </body>
</html>

    `,
    prebookingTermsHtmlEn: `
    <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <title></title>
    <meta
      name="viewport"
      content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
    />
  </head>
  <body>
    <h1>Terms of Use</h1>
    <p>
      These Terms of Use define the terms by which you may use the EasyVax scheduler, platform and services provided by
      EasyVax and are an agreement between you, Lextech Global Services Corporation, the application developer and GSK,
      LLC the application owner and their subsidiaries (“Company”, “we” or “us”). These Terms of Use apply to your use
      of the EasyVax scheduler, The scheduler can be used by end users (“End Users”) and licensed healthcare
      professionals (“Professional Users”) (referred to collectively as “you” or “Users”). The
      <a id="prebooking-privacy" href="/a/prebooking-privacy">Privacy Notice</a> also forms part of these Terms of Use.
      Any reference to our Terms of Use includes all such policies.
    </p>
    <p>
      By using the EasyVax scheduler, you expressly acknowledge that you have read, accept, and agree to be bound by
      them, as well as any applicable laws hereunder. You should not use the EasyVax scheduler if you do not agree to
      these Terms of Use.
    </p>

    <ol>
      <li>
        CHANGES TO THE TERMS OF SERVICE. We reserve the right, at our sole discretion, to change, modify or alter the
        Terms of Use, inclusive of the Privacy Notice, at any time. Such changes shall become effective immediately upon
        the posting thereof. You must review the Terms of Use on a regular basis to keep yourself apprised of any
        changes.
      </li>
      <li>
        ELIGIBILITY TO USE OUR SERVICES. Only adults, who are at least eighteen (18) years of age, located in the United
        States of America, are eligible to use our scheduler. You may use the scheduler to facilitate obtaining or
        obtain healthcare services for a minor if you are the minor’s parent or legal guardian. In addition, you must be
        fully competent to enter into and to comply with these Terms of Use. You represent and warrant that you have the
        right, authority, and capacity to enter into these Terms of Use and can abide by all of the terms and conditions
        set forth herein.
      </li>
      <li>
        COMPANY DOES NOT PROVIDE MEDICAL ADVICE. The scheduler enables consumers and healthcare providers to schedule
        appointments for vaccinations at retailers. The scheduler does not constitute medical advice or endorse any
        course of treatment. Company expressly disclaims all liability with respect to any healthcare information you
        obtain through the scheduler and any actions taken or not taken based on your use of our scheduler. Any reliance
        on such scheduler is at your own risk. No licensed medical professional/patient relationship is created between
        you and Company when you use our scheduler.
      </li>
      <li>
        RELATIONSHIPS BETWEEN RETAIL OUTLETS AND END USERS. RETAIL OUTLETS relationships with End Users, including
        patients and non-patients, is directly between the RETAIL OUTLET and the End Users.
      </li>
      <li>
        RETAIL OUTLETS AND USERS COMPLIANCE WITH APPLICABLE LAWS, REGULATION AND LICENSURE REQUIREMENTS. To the extent
        Company provides information, template documents or forms to assist Retail Outlets allowing patients to schedule
        appointments, Company does so solely as a convenience to Retail Outlets. Company is not providing legal or
        business advice to Retail Outlets. Retail Outlets should seek qualified legal counsel to confirm applicability
        or and/or customize any template documents before utilizing such document in the provision of healthcare
        services. Retail Outlets assume sole liability and responsibility for complying with all relevant state and
        federal laws, reimbursement rules and third party payor agreements.
      </li>
      <li>
        LIMITATIONS AND CANCELLATIONS. We may at any time create limitations of your use of our scheduler including, but
        not limited to, the number of times you can access the scheduler. All Users are prohibited from and may be
        blocked from using the Scheduler if:
        <ol type="a">
          <li>you have abused your rights to use the scheduler;</li>
          <li>
            you have breached any of the terms specified in this document, including but not limited to acted in a
            manner that violates our prohibited conduct policy;
          </li>
          <li>you submit false information;</li>
          <li>you have performed any act or omission that violates any applicable law, rules, or regulations;</li>
          <li>
            you have performed any act or omission which is harmful or likely to be harmful to us, or any other third
            party, including other users and partners of Company;
          </li>
          <li>
            you made use of our scheduler to perform an illegal act, or for the purpose of enabling, facilitating,
            assisting or inducing the performance of such an act;
          </li>
          <li>you use the scheduler for any private commercial purpose without our express consent;</li>
        </ol>
      </li>
      <li>
        PROHIBITED CONDUCT. The scheduler should be used only for lawful purposes. We specifically prohibit you from
        engaging in any of the following conduct:
        <ol type="a">
          <li>Any activity that produces software viruses or code harmful to other computers;</li>
          <li>
            Submitting to the scheduler any information in which you impersonate or claim to be any third party, or in
            which you misrepresent your affiliation with another person or entity;
          </li>
          <li>Sharing information that you are under an obligation not to disclose;</li>
          <li>
            Taking any action that disrupts, tampers with, interferes with, or imposes an unreasonable burden on the
            scheduler infrastructure, servers, data, or network or those of any third party via our Services;
          </li>
          <li>
            Using or attempting to use any engine, software tool, agent, or other device or mechanism (including without
            limitation browsers, spiders, robots, avatars, or intelligent agents) to navigate or search the scheduler
            other than generally available third party browsers;
          </li>
          <li>
            Using any means of automatically searching or mining data from the scheduler, or in any way attempting to
            interfere with the proper working of the scheduler;
          </li>
          <li>Collecting or storing personal information about any customer or visitor of the scheduler;</li>
          <li>
            Using any scheduling, communications, or transactional services on the scheduler in an abusive or improper
            manner or in any way that disrupts or impedes the intended use of such features by other users and/or
            providers.
          </li>
        </ol>
        You agree not to decompile or reverse engineer or otherwise attempt to discover any source code contained in the
        scheduler. Unless you receive explicit permission, you agree not to reproduce, duplicate, copy, sell, resell or
        exploit for any commercial purposes, any aspect of the scheduler.
      </li>
      <li>
        SCHEDULING. If a Retail Outlet is enabled, you will be able to submit a general request for an appointment. The
        scheduler collects the information requested by the Retail Outlet. By using this functionality, you expressly
        authorize us to provide to the Retail Outlet the health information you give to us. If you obtain healthcare
        from a provider by using our scheduler you are entirely responsible for your healthcare expenses. You must
        resolve any dispute between you and a Retail Outlet or healthcare provider directly with the Retail Outlet and
        healthcare provider.
      </li>
      <li>
        OPERATION OF THE SERVICES. We use commercially reasonable efforts to maintain our scheduler and to keep them
        operating with high availability and free from bugs, errors, technical problems, or defects. If, at any time in
        the future, we identify any bugs, errors, technical problems, or defects, then we will assign technicians to
        address and resolve the issue. We cannot guarantee that your access to the scheduler will be uninterrupted, or
        that the scheduler will be available at all times. We can assume no liability or responsibility for any delay,
        interruption, or downtime. We use commercially reasonable efforts to ensure that our scheduler is protected from
        viruses and other destructive software, but we cannot guarantee that the scheduler will at all times be free
        from viruses. We reserve the right to discontinue operation of our scheduler at any time for any reason at our
        sole discretion, and to make modifications to the operation of the scheduler at any time at our sole discretion.
      </li>
      <li>
        INTELLECTUAL PROPERTY. We, our partner, affiliates, or our licensors shall retain all respective right, title,
        and interest in the marks, logos, codes, databases, Content, text, designs, photos, and other materials posted
        to our scheduler (“Intellectual Property”). Except as otherwise expressly stated herein, you may only display
        and view the Intellectual property posted to the scheduler, and you may not reproduce, display, distribute,
        create derivative works of, misappropriate, or otherwise use for any purpose any portion of our scheduler
        without the express written permission of us or our licensors as appropriate. Using the Intellectual Property on
        our scheduler on any other website or in any other services or product for any commercial purpose is expressly
        prohibited.
      </li>
      <li>
        WARRANTY. The use of our scheduler or your reliance on any of the foregoing shall be at your own risk. We can
        make no warranty that the scheduler will meet your needs or that their operation will be continuous,
        uninterrupted, bug-free, error-free, virus-free, free of defects, free of technical problems.
      </li>
      <li>
        CONSEQUENTIAL DAMAGES; LIMITATION OF LIABILITY. UNDER NO CIRCUMSTANCES SHALL WE BE LIABLE FOR DIRECT, INDIRECT,
        INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES, RESULTING FROM ANY ASPECT OF YOUR USE OF THE WEBSITE OR SERVICES
        INCLUDING BUT NOT LIMITED TO DAMAGES THAT ARISE FROM YOUR INABILITY TO USE THE WEBSITE OR THE SERVICE; LOST
        PROFITS; LOST GOODWILL; LOST DATA; ANY INTERRUPTION, MODIFICATION, OR TERMINATION OF THE WEBSITE OR ANY SERVICE
        OR PART THEREOF; OR OTHER INTANGIBLE LOSSES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES).
        Our liability to you shall in no event exceed the total aggregate amount of $500 for all claims. Some
        jurisdictions do not allow the limitation of liability, so these limitations may not apply to you.
      </li>
      <li>
        RELEASE OF CLAIMS. To the maximum extent permitted by applicable law, you hereby release and waive all claims
        against us and our officers, directors, employees, independent contractors, representatives, affiliates and
        agents and from any and all liability for claims, damages (actual and or consequential), costs and expenses
        (including litigation costs and attorneys’ fees) of every kind and nature arising from or in any way related to
        our Services. If applicable, you waive your rights under California Civil Code Section 1542, which states, “A
        general release does not extend to claims which the creditor does not know or suspect to exist in his or her
        favor at the time of executing the release, which if known by him or her must have materially affected his
        settlement with the debtor.” You understand that any fact relating to any matter covered by this release may be
        found to be other than now believed to be true, and accept and assume the risk of such possible differences in
        fact. In addition, you expressly waive and relinquish any and all rights which you may have had under any other
        state or federal statute or common law principle of similar effect, to the fullest extent permitted by law.
      </li>
      <li>
        INDEMNIFICATION. You agree to indemnify and hold us and our officers, directors, subsidiaries, affiliates,
        successors, assigns, agents, service providers, suppliers and employees, harmless from any third party claim or
        demand arising from your use of the scheduler; (c) your violation of the Terms of Use; (d) your breach of any of
        the representations and warranties herein; (e) your violation of any rights of another, including but not
        limited to any liability or expenses arising from any and all claims, demands, losses, damages, costs, expenses,
        suits, judgments, litigation costs, or reasonable attorney fees and court costs; or (f) your violation of any
        law, regulation, or guidelines imposed by any administrative body.
      </li>
      <li>
        GOVERNING LAW; DISPUTE RESOLUTION. These Terms of Service shall be governed by and construed in accordance with
        the laws of the State of New York, without regard to conflicts of law principles. All disputes with the Company
        arising under this Agreement shall be submitted to binding arbitration under the Commercial Rules of the
        American Arbitration Association in New York by one arbitrator mutually agreed upon by you and us in accordance
        with the aforementioned Rules. The costs of arbitration, including administrative and arbitrators’ fees, shall
        be shared equally by you and us. The arbitration award shall be final and each of us shall comply in good faith
        to the entry of the arbitrator’s award in any court having jurisdiction. If judicial enforcement or review is
        sought, then the prevailing party shall be entitled to costs and reasonable attorney’s fees. All claims you
        bring against us or the Services must be resolved in accordance with this Section. All claims filed or brought
        against us contrary to the terms of this Section shall be considered improperly filed. Should you file a claim
        contrary to this Section, you agree that we may recover attorneys’ fees and costs for the improperly filed
        claim, provided that we have notified you in writing of the issue and you have failed to properly withdraw the
        claim.
      </li>
    </ol>
  </body>
</html>

    `,
    prebookingTermsHtmlEs: `
    <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <title></title>
    <meta
      name="viewport"
      content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
    />
  </head>
  <body>
    <h1>Condiciones de uso</h1>
    <p>
      En estas Condiciones de uso se definen las condiciones para el uso del programador EasyVax, la plataforma y los
      servicios proporcionados por EasyVax. Esta Condiciones constituyen además un acuerdo entre usted,
      Lextech Global Services Corporation, el desarrollador de la aplicación, GSK, LLC, el propietario de la aplicación,
      y las demás empresas controladas (en adelante, la «Empresa», «nosotros» o «nos»). Estas Condiciones de uso rigen
      su uso del programador EasyVax. Además, el programador EasyVax está destinado al uso por parte de usuarios finales
      (en adelante, los «Usuarios finales») y profesionales de la salud con licencia (en adelante, los «Usuarios
      profesionales») (en adelante y de forma colectiva, «usted» o los «Usuarios», respectivamente). El
      <a id="prebooking-privacy" href="/a/prebooking-privacy">Aviso de prácticas de privacidad</a> también forma parte
      de estas Condiciones de uso. De este modo, toda referencia a nuestras Condiciones de uso incluye todas estas
      políticas.
    </p>
    <p>
      Al usar el programador EasyVax, reconoce expresamente que ha leído, acepta y se compromete a respetar las
      Condiciones de uso, así como cualquier ley aplicable, en virtud del presente. Si no está de acuerdo con estas
      Condiciones de uso, no debe usar el programador EasyVax.
    </p>

    <ol>
      <li>
        CAMBIOS A ESTAS CONDICIONES DE USO. Nos reservamos el derecho, a nuestra entera discreción, a cambiar, modificar
        o, de cualquier otro modo, alterar las Condiciones de uso, incluido el Aviso de prácticas de privacidad, en
        cualquier momento. Cualquiera de cambios entrarán en vigor inmediatamente después de la publicación. A fin de
        mantenerse informado de cualquier cambio aplicado, debe revisar estas Condiciones de uso de forma regular.
      </li>
      <li>
        IDONEIDAD PARA EL USO DE NUESTROS SERVICIOS. Aquellas personas que tengan dieciocho (18) años, como mínimo, y
        que se encuentran en Estados Unidos de América reúnen los requisitos para el uso de nuestro programador. Si
        usted es el padre o tutor legal de un menor, puede usar el programador para acceder a servicios de atención
        médica destinados a ese menor, o bien para facilitar tal acceso. Además, debe estar en pleno uso de sus
        facultades para celebrar y cumplir con estas Condiciones de uso. Por el presente, declara y asegura que tiene el
        derecho, la autoridad y la capacidad para celebrar estas Condiciones de uso, así como que puede cumplir con
        todos los términos y condiciones que se establecen en este documento.
      </li>
      <li>
        LA EMPRESA NO BRINDA CONSEJO MÉDICO. El programador permite que consumidores y proveedores de atención de la
        salud programen una cita para vacunarse en tiendas minoristas. El programador no tiene por objetivo brindar
        consejo médico ni respalda ningún curso de tratamiento. La Empresa renuncia expresamente a toda responsabilidad
        en relación a cualquier información de atención de la salud que se obtenga a través del programador. Renuncia
        además a cualquier acción tomada producto del uso que usted realice de nuestro programador, o bien a las
        acciones que no se hayan tomado por el mismo motivo. Cualquier decisión que se tome basada en la confianza en el
        programador corre por su propia cuenta. El uso de nuestro programador no implica ni genera ninguna relación de
        tipo profesional de atención de la salud-paciente entre usted y la Empresa.
      </li>
      <li>
        RELACIONES ENTRE LOS PUNTOS DE VENTA MINORISTAS Y LOS USUARIOS FINALES. Las relaciones entre los PUNTOS DE VENTA
        MINORISTAS y los Usuarios finales, incluidos pacientes y no pacientes, se establecen directamente entre un PUNTO
        DE VENTA MINORISTA y el Usuario final.
      </li>
      <li>
        CUMPLIMIENTO DE LAS LEYES APLICABLES, LOS REGLAMENTOS Y LOS REQUISITOS DE LICENCIA POR PARTE DE LOS PUNTOS DE
        VENTA MINORISTAS Y LOS USUARIOS. En el caso de que la Empresa proporcione información, plantillas de documentos
        o formularios con el objetivo de ayudar a los Puntos de venta minoristas a que los pacientes programen citas, se
        deja constancia de que la Empresa lo hace únicamente para comodidad de los Puntos de venta minoristas. Se
        entiende, por lo tanto, que la Empresa no brinda ningún tipo de asesoramiento legal o comercial a los Puntos de
        venta minoristas. Los Puntos de venta minoristas deben buscar asesoramiento legal calificado a fin de confirmar
        la idoneidad de uso o para adaptar cualquier documento de plantilla antes de usar tal documento para la
        prestación de servicios de atención de la salud. Los Puntos de venta minoristas asumen la responsabilidad
        exclusiva de cumplir con todas las leyes estatales y federales pertinentes, las reglas de reembolso y los
        acuerdos de terceros pagadores.
      </li>
      <li>
        LIMITACIONES Y CANCELACIONES. Podemos, en cualquier momento, crear limitaciones de su uso para nuestro
        programador, incluido, entre otros, la cantidad de veces que puede acceder a esta herramienta. En cualquiera de
        los siguientes casos, los Usuarios tienen prohibido el uso del programador y, de hacerlo, pueden quedar
        bloqueados:
        <ol type="a">
          <li>cuando se haya abusado de los derechos de uso del programador;</li>
          <li>
            cuando se haya incumplido cualquiera de las condiciones que se indican en este documento, incluido, entre
            otros, actuar de una manera que infrinja nuestra política de conductas prohibidas;
          </li>
          <li>cuando se envíe información falsa;</li>
          <li>
            cuando se haya realizado algún acto que infrinja cualquier ley, regla o reglamento aplicables, o bien no
            haberlo hecho para cumplir con estos;
          </li>
          <li>
            cuando se haya realizado algún acto que sea perjudicial, o que pueda serlo, para nosotros o para cualquier
            otro tercero, incluidos otros usuarios y socios de la Empresa, o bien no haberlo hecho para evitar tal daño;
          </li>
          <li>
            cuando haya usado nuestro programador para realizar un acto penado por la ley, o bien con el propósito de
            permitir, facilitar, ayudar o inducir la realización de tal acto;
          </li>
          <li>
            cuando haya usado nuestro programador para cualquier propósito comercial privado, sin antes contar con
            nuestro consentimiento expreso.
          </li>
        </ol>
      </li>
      <li>
        CONDUCTAS PROHIBIDAS. El programador se debe usar únicamente para fines legales. Por medio del presente, queda
        expresamente prohibido participar en cualquiera de las siguientes conductas:
        <ol type="a">
          <li>cualquier actividad que produzca virus de software o códigos dañinos en otros equipos informáticos;</li>
          <li>
            enviar al programador cualquier información en la que se haga pasar un tercero o en la que afirme serlo, así
            como cualquier información que tergiverse su grado de afiliación con otra persona o entidad;
          </li>
          <li>compartir información sujeta a restricciones de divulgación que haya aceptado cumplir;</li>
          <li>
            llevar a cabo cualquier acción que interrumpa, altere, interfiera o imponga una carga excesiva en la
            infraestructura, los servidores, los datos o la red del programador o los de cualquier tercero a través de
            nuestros Servicios;
          </li>
          <li>
            usar, o intentar usar, cualquier motor, herramienta de software, agente o cualquier otro dispositivo o
            mecanismo —incluido, entre otros, navegadores, arañas, robots, avatares o agentes inteligentes— para navegar
            por el programador o realizar una búsqueda en este que no sean navegadores de terceros de uso general y
            habitual;
          </li>
          <li>
            usar cualquier medio de búsqueda o extracción automática de datos en el programador o, de cualquier manera,
            interferir con el funcionamiento adecuado del programador;
          </li>
          <li>recopilar o almacenar información personal de cualquier cliente o visitante del programador;</li>
          <li>
            usar cualquier método de programación o comunicación o servicio comercial en el programador de manera
            abusiva o inapropiada, o bien hacerlo de cualquier otra manera que interrumpa o impida el uso previsto de
            tales funciones por parte de otros usuarios o proveedores.
          </li>
        </ol>
        Acepta no descompilar, realizar ingeniería inversa ni intentar descubrir ningún código fuente que se incluya en
        el programador. A menos que reciba una autorización explícita, acepta no reproducir, duplicar, copiar, vender,
        revender o explotar para ningún aspecto del programador para propósito comercial.
      </li>
      <li>
        PROGRAMACIÓN. Si un Punto de venta minorista se encuentra debidamente habilitado, podrá enviar una solicitud
        general para programar una cita. El programador recopila la información solicitada por el Punto de venta
        minorista. Al usar esta funcionalidad, nos autoriza expresamente a brindar al Punto de venta minorista la
        información de salud que nos facilita. Si obtiene algún servicio de atención de la salud de un proveedor
        mediante el uso de nuestro programador, usted asume la responsabilidad total por los gastos de atención médica
        pertinentes. Debe resolver cualquier controversia que surja entre usted y un Punto de venta minorista o
        proveedor de atención de la salid directamente con ese Punto de venta minorista y ese proveedor de atención de
        la salud.
      </li>
      <li>
        FUNCIONAMIENTO DE LOS SERVICIOS. Tomamos todas las medidas razonables desde el punto de vista comercial para
        mantener operativo nuestro programador, a fin de que brinde servicio con una alta disponibilidad y libre de
        errores, fallos, problemas técnicos o defectos. Si, en algún momento en el futuro, identificamos algún error,
        problema técnico o defecto, asignaremos personal técnico para abordar y resolver ese problema. No podemos
        garantizar un acceso ininterrumpido al programador, así como tampoco que esté disponible en todo momento. En el
        mismo sentido, no asumimos responsabilidad alguna por ningún retraso, interrupción o tiempo de inactividad.
        Tomamos todas las medidas razonables desde el punto de vista comercial para garantizar que nuestro programador
        esté protegido contra virus y otros software destructivos, pero no podemos garantizar que esté libre de virus en
        todo momento. Nos reservamos el derecho a interrumpir el funcionamiento, o realizar modificaciones en el
        funcionamiento, de nuestro programador en cualquier momento y por cualquier motivo a nuestra entera discreción.
      </li>
      <li>
        PROPIEDAD INTELECTUAL. Nosotros, nuestros socios, empresas controladas o nuestros licenciantes conservaremos
        todos los derechos, títulos e intereses respectivos sobre las marcas, logotipos, códigos, bases de datos,
        contenido, texto, diseños, fotografías y otros materiales que se publiquen en nuestro programador (en adelante,
        la «Propiedad intelectual»). Salvo que se indique expresamente lo contrario en este documento, solo puede
        mostrar y ver la Propiedad intelectual publicada en el programador; por lo tanto, no puede reproducir, mostrar,
        distribuir, crear obras derivadas, apropiarse indebidamente ni, de cualquier otra manera, usar ninguna parte de
        nuestro programador para ningún propósito sin antes contar con una autorización expresa por escrito de nosotros
        o nuestros licenciantes, según corresponda. Queda expresamente prohibido el uso de la Propiedad intelectual
        publicada en nuestro programador en cualquier otro sitio web o en cualquier otro servicio o producto para
        cualquier propósito comercial.
      </li>
      <li>
        GARANTÍA. El uso de nuestro programador, o cualquier decisión que se tome basada en la confianza en este, para
        cualquiera de los puntos mencionados más arriba corre bajo su propio riesgo. No ofrecemos ninguna garantía de
        que el programador satisfaga sus necesidades o que el funcionamiento de esta herramienta sea ininterrumpido y
        continuo ni que esté libre de errores, virus, defectos y problemas técnicos.
      </li>
      <li>
        DAÑOS CONSECUENTES; LIMITACIÓN DE RESPONSABILIDAD. BAJO NINGUNA CIRCUNSTANCIA ASUMIMOS RESPONSABILIDAD ALGUNA
        POR NINGÚN DAÑO DIRECTO, INDIRECTO, INCIDENTAL, ESPECIAL O CONSECUENTE QUE SURJAN DE CUALQUIER ASPECTO
        RELACIONADO CON EL USO QUE REALICE DEL SITIO WEB O LOS SERVICIOS, INCLUIDOS, ENTRE OTROS, DAÑOS RELACIONADOS CON
        SU FALTA DE CAPACIDAD PARA USAR EL SITIO WEB O EL SERVICIO; PÉRDIDA DE GANANCIAS, DE DATOS O DE BIENES
        INMATERIALES; CUALQUIER INTERRUPCIÓN, MODIFICACIÓN O CANCELACIÓN DEL SITIO WEB O DE CUALQUIER SERVICIO O PARTE
        DE ESTOS; ASÍ COMO CUALQUIER OTRA PÉRDIDA INTANGIBLE (INCLUSO CUANDO HAYAMOS SIDO ADVERTIDOS DE LA POSIBILIDAD
        DE TALES DAÑOS). Nuestra obligación de garantía no superará en ningún caso el importe total de $500 para las
        reclamaciones de cualquier tipo. En algunas jurisdicciones no se permite la limitación de responsabilidad, por
        ello es posible que estas limitaciones no se apliquen a su caso.
      </li>
      <li>
        EXENCIÓN DE RECLAMACIONES. En la medida máxima permitida por la ley aplicable, por el presente exime y renuncia
        a todas las reclamaciones contra nosotros y nuestros funcionarios, directores, empleados, contratistas
        independientes, representantes, empresas controladas y agentes. Además, nos exime de toda responsabilidad por
        cualquier reclamación, daño —ya sea real o consecuente—, costo y gasto —incluidos los costos de litigios y los
        honorarios de abogados— de todo tipo y naturaleza que surjan de o, de cualquier manera, estén relacionados con
        nuestros Servicios. Si corresponde, renuncia a sus derechos en virtud de la sección 1542 del Código Civil de
        California, en la que se establece: «una exención general no se extiende a reclamaciones que el acreedor o la
        parte exonerante no conoce o sospecha que existan a su favor al momento de hacer la exención y que, de haberlas
        conocido, habrían afectado materialmente su acuerdo con el deudor». usted comprende que cualquier hecho
        relacionado con cualquier asunto cubierto por esta exención se puede considerar de forma distinta de lo que
        ahora se considere cierto y acepta y asume el riesgo de tales posibles diferencias de hecho. Además, renuncia y
        cede expresamente a todos y cada uno de los derechos que pueda haber tenido en virtud de cualquier otro estatuto
        estatal o federal o principio de derecho consuetudinario de efecto similar, en la máxima medida permitida por la
        ley.
      </li>
      <li>
        INDEMNIZACIÓN. Acepta eximirnos de toda responsabilidad a nosotros y a nuestros funcionarios, directores,
        subsidiarias, empresas controladas, sucesores, cesionarios, agentes, proveedores de servicios, proveedores y
        empleados por cualquier reclamación o demanda de terceros que surja del uso del programador por su parte;
        (c) por cualquier infracción que cometa de las Condiciones de uso; (d) por cualquier falta cumplimiento que
        cometa de cualquiera de las declaraciones y garantías que se incluyen en este documento; (e) por cualquier
        infracción que cometa de cualquier derecho de terceros, incluido, entre otros, cualquier responsabilidad o gasto
        que surja de todas y cada una de las reclamaciones, demandas, pérdidas, daños, costos, gastos, procesos
        judiciales, sentencias, costos de litigios o honorarios razonables de abogados y costas judiciales; o (f) por
        cualquier infracción que cometa de cualquier ley, reglamento o pauta impuesta por cualquier organismo
        administrativo.
      </li>
      <li>
        LEGISLACIÓN APLICABLE; RESOLUCIÓN DE CONTROVERSIAS. Estos Términos de servicio se regirán e interpretarán de
        acuerdo con las leyes del Estado de Nueva York, sin tener en cuenta los conflictos de principios legales. Todas
        las controversias de la Empresa que surjan en virtud de este Acuerdo se someterán a un procedimiento de
        arbitraje vinculante, de acuerdo con las normas comerciales de la Asociación Americana de Arbitraje de
        Nueva York. Este arbitraje estará a cargo de un árbitro que se haya elegido de común acuerdo entre usted y
        nosotros, al amparo de las normas antes mencionadas. Los costos del arbitraje, incluidos los costos
        administrativos y los honorarios de los árbitros, se compartirán por igual entre usted y nosotros. El laudo
        arbitral será definitivo y cada uno de nosotros cumplirá de buena fe con la presentación de este en cualquier
        tribunal que tenga jurisdicción. En el caso de que se solicite una ejecución o revisión judicial, la parte
        ganadora tendrá derecho a reclamar las costas y los honorarios razonables de los abogados. Todas las
        reclamaciones que presente contra nosotros o los Servicios se deben resolver de acuerdo con lo establecido en
        esta sección. Cualquier reclamación que se presente contra nosotros y que contravenga los términos de esta
        sección se considerará presentada incorrectamente. En el caso de que presente una reclamación que contravenga lo
        establecido en esta sección, acepta que podemos reclamar las costas y los honorarios de los abogados en concepto
        de presentación incorrecta, siempre que le hayamos notificado por escrito el problema y que no haya retirado tal
        reclamación de forma debida.
      </li>
    </ol>
  </body>
</html>

    `,
    postbookingPrivacyHtmlEn: `
    <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <title></title>
    <meta
      name="viewport"
      content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
    />
  </head>
  <body>
    <h1>Privacy Notice</h1>
    <p>Effective Date: January 24, 2024</p>

    <section style="padding: 8px 0">
      <p>
        EasyVax is a vaccine scheduling platform (the "Platform") that allows you to find and schedule vaccine
        appointments by checking availability at multiple pharmacy retailers ("Retailer(s)"), finding available times,
        and allowing you to schedule with your chosen Retailer and added to your calendar all at once (the "Services").
      </p>
      <p>
        This Privacy Notice sets out how EasyVax collects, uses, transfers, processes, and discloses your data and sets
        out our security practices. We respect your privacy and are committed to protecting your personal information.
      </p>
      <p>
        When we say "EasyVax," "we," "us" or our, we are referring to GSK, LLC the Platform owner and our subsidiaries
        (collectively, "GSK"), which was developed by Lextech Global Services Corporation, the application developer
        ("Lextech").
      </p>
      <p>
        EasyVax can be used by end users and licensed healthcare professionals (referred to collectively as "you" or
        "Users").
      </p>
      <p>
        Our <a id="postbooking-terms" href="/a/terms">Terms of Use</a> also forms part of this Privacy Notice and is
        binding upon Users. The Services are intended for users who are at least 18 years old. Persons under the age of
        18 are not permitted to use the Services.
      </p>
      <p>
        The information provided via the Platform is for informational purposes only and is not meant to replace your
        pharmacist's or healthcare professional's medical advice or information from your plan about preferred
        pharmacies or doctors.
      </p>
      <p>
        Please note, our privacy practices are subject to the applicable laws of the places in which we operate. We may
        change this Privacy Notice from time to time. We encourage you to review this Privacy Notice periodically. We
        recommend that you print a copy of this Privacy Policy for your records.
      </p>
      <p>
        By using the Services, you consent to the terms of this Privacy Notice and our Terms of Use. If you disagree in
        any way, you must immediately discontinue using the Services.
      </p>
    </section>

    <section style="padding: 8px 0">
      <h2>How our Services work?</h2>
      <p>
        Our Services can be accessed via QR Code Scan, the EasyVax mobile app, or website located at
        <a target="_blank" href="https://www.easyvax.com">www.easyvax.com</a>. Once you access the Platform you will be
        asked to provide information that to help identify what vaccine or vaccines you would like to receive and
        Retailers close to the location you identified that can administer that vaccine or those vaccines to you.
      </p>
      <p>
        You may search by ZIP code or other location means. You may also narrow your search by provider type of specific
        provider name. Any information you provide through the Platform at this point is used solely to help you find a
        Retailer that can administer the vaccine or vaccines that you have indicated you would like to receive. Search
        results are pulled from a publicly-available nationwide database of pharmacies and the vendor's network of
        participating healthcare professionals. While it is updated regularly, the list may not have the latest provider
        information and results shown may not reflect all of the adult vaccine providers in your area.
      </p>
      <p>
        Inclusion of a pharmacy or doctor in the list of Retailers via the Services is not and does not imply a
        referral, an endorsement or a recommendation by us or any of our products. Participation by a Retailer in payer
        networks may vary. You should verify a Retailer's participation/acceptance of your health insurance.
      </p>
    </section>

    <section style="padding: 8px 0">
      <h2 id="pii-usage">What personal information do we collect about you and how do we collect it?</h2>
      <p>
        For our purposes, we collect only information that we need to find and connect you with a Retailer who can
        provide vaccine services to you. For our part of the Services to work, we only need the location you wish to
        receive the vaccine, such as ZIP code, and type of vaccine you want to receive.
      </p>
      <p>
        When you choose a Retailer from those identified as available to provide the requested services to you in your
        selected location, you may be asked to provide additional information to that Retailer in order for that
        Retailer to schedule you for their vaccination services at a selected location. This may include personal
        information, as that Retailer may require, which may include sensitive personal information, which may include
        the following:
      </p>
      <table aria-describedby="pii-usage" border="1">
        <thead>
          <tr style="border-width: 1px">
            <th>Categories of PI Collected</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr style="border-width: 1px">
            <td>Name, Contact information, and Unique Identifiers</td>
            <td>
              Identifiers, such as a real name, alias, postal address, telephone number, email address, or other similar
              identifiers as well as demographic information such as date of birth.
            </td>
          </tr>
          <tr style="border-width: 1px">
            <td>Medical Information</td>
            <td>
              Any information in possession of or derived from yourself, a healthcare provider, healthcare insurer,
              healthcare service plan, pharmaceutical company, or contractor regarding an individual's medical history,
              mental or physical condition, or treatment. This includes an individual’s insurance policy number or
              subscriber identification number, any unique identifier used by a health insurer to identify the
              individual, or any information in the individual’s application and claims history (including prescription
              information).
            </td>
          </tr>
          <tr style="border-width: 1px">
            <td>Protected Characteristics</td>
            <td>
              Characteristics of legally protected classifications such as race, gender, age, nationality, physical or
              mental disability, and religion.
            </td>
          </tr>
          <tr style="border-width: 1px">
            <td>Biometric Information</td>
            <td>
              Physiological, biological, or behavioral characteristics that can establish an individual’s identity,
              including DNA, face, iris or retina imagery, fingerprint, voice recordings and sleep, health, or exercise
              data that contain identifying information.
            </td>
          </tr>
        </tbody>
      </table>
    </section>

    <section style="padding: 8px 0">
      <h2>How do we use your information?</h2>
      <p>
        We use the information you provide to us to locate a Retailer close to the location you identified you desired
        to receive a particular vaccination to locate Retailers that match your criteria in order to provide the
        Services.
      </p>
    </section>

    <section style="padding: 8px 0">
      <h2>How long do we keep your personal information?</h2>
      <p>
        Any information we retain is de-identified, which means that we do not retain any information that identifies
        you.
      </p>
      <p>
        If you have questions about how your selected Retailer retains your personal information please review their
        privacy policies.
      </p>
      <p>Lextech retains no information.</p>
    </section>

    <section style="padding: 8px 0">
      <h2>With whom do we share your personal information?</h2>
      <p>
        Any personal information you provide is information required by your selected Retailer for their purposes, not
        ours. This information is end-to-end encrypted in order to transfer it to your selected Retailer. This means
        that the personal information is made undecipherable and irreversible during the transfer process. You are
        solely responsible for communications and interactions with any of the listed Retailers and providers working on
        behalf of those Retailers when you interact with them, and any information you provide to them is not governed
        by this Privacy Notice. Please review your chosen Retailer's privacy policy to learn what they do with your
        personal information and about any rights you may have in regards to that Retailer's use and collection of your
        information.
      </p>
      <p>
        LexTech shares de-identified user activity data only with our token service provider and sponsor, GSK, to help
        understand how many users are utilizing the Platform, including what Services are being sought, to improve the
        Services, and to conduct anonymous-based research. This is done through a token service provider that tokenizes
        the data prior to sharing it for the purposes set forth above. A token is a piece of data that stands for
        another, more valuable piece of information. Tokens have no value and are only useful because they represent
        something valuable. A token is like a poker chip. Instead of filling a table with cash, which can easily be
        stolen, players use chips as placeholders. However, poker chips cannot be used as money, even if they are
        stolen. They must be first exchanged for their representative value. This is what happens through the Services
        when you provide the personal information for the Retailer. It is tokenized and only made available to us in
        tokenized form. We never see, never store, and never use the personal information you provide and that is
        provided to the Retailer; the only information we see, store and otherwise have access is in tokenized form.
      </p>
      <p>
        We do not retain personal information. Lextech does not retain any information, including user activity data or
        personal information.
      </p>
      <p>
        We will share the information we may retain with local or foreign regulators, or government and law enforcement
        authorities as necessary or appropriate, in particular when we have a legal obligation to do so. These may be in
        or outside your country of residence. We may also disclose your information for other legal reasons, such as to
        enforce our terms and conditions; and protect our rights, privacy, safety or property, and/or that of our
        affiliates, you or others.
      </p>
      <p>
        We may share the information we may retain in connection with a sale or business transaction, such as a
        reorganization, merger, sale, joint venture, assignment, transfer or other disposition of all or any portion of
        our business, assets, or stock (including in connection with any bankruptcy or similar proceedings).
      </p>
    </section>

    <section style="padding: 8px 0">
      <h2>In what instances do we transfer your personal information outside of your home country?</h2>
      <p>
        The Services are only available in the United States are intended only for individuals within the United States.
      </p>
    </section>

    <section style="padding: 8px 0">
      <h2>How we protect your personal information?</h2>
      <p>
        Transfer of information to any Retailer is via end-to-end encryption. Transfer to GSK is via the tokenization
        process, which de-identifies data. We take appropriate legal, organizational, and technical measures to protect
        your personal information consistent with applicable privacy and data security laws. As set forth above,
        transfer of your information not Retailer is via a tokenization process and use of you information by us is only
        in de-identified form.
      </p>
      <p>
        Unfortunately, the transmission of information via the Internet or a mobile phone network connection is not
        completely secure. Although we will do our best to protect your personal information, we cannot guarantee the
        security of the personal information you transmit to our websites or mobile applications: any transmission is at
        your own risk. While we cannot guarantee that loss, misuse or alteration to data will not occur, once we have
        received your information, we will employ appropriate technical security measures to help prevent such
        unfortunate occurrences.
      </p>
    </section>

    <section style="padding: 8px 0">
      <h2>What are your rights and choices regarding your personal information?</h2>
      <p>
        We do not retain information that personally identifies you. This means that we are unable to respond to any
        data subject requests because we cannot identify any information that may pertain to you or your use of the
        Services. To the extent you may have exercisable data subject rights with any of the Retailers, please review
        their privacy policies to determine how you can exercise your data subject rights with respect to personal data
        they may have collected about you.
      </p>
      <p>
        <strong>Do-Not-Track Signals, Global Privacy Control, and Similar Mechanisms.</strong> Some web browsers
        transmit "do-not-track" signals to websites. Because the “do-not track” browser-based standard signal has yet to
        gain widespread acceptance, we do not currently respond to those signals.
      </p>
    </section>

    <section style="padding: 8px 0">
      <h2>Information about children?</h2>
      <p>
        Our Services are not directed to those under 16 and we do not knowingly collect, sell, or share for targeted
        advertising any personal information from minors under 16 years of age.
      </p>
    </section>

    <section style="padding: 8px 0">
      <h2>How we update this Privacy Notice?</h2>
      <p>
        From time to time, we will update this Privacy Notice. Any changes become effective when we post the revised
        Privacy Notice, although we may elect to otherwise notify you in some cases where changes are significant or
        where required by law. This Privacy Notice was last updated as of the "Last Updated" date shown above.
      </p>
    </section>

    <section style="padding: 8px 0">
      <h2>Contact Information and Privacy point of contact</h2>
      <p>
        If you have any questions about this Privacy Notice, need more information or would like to raise a privacy
        concern, please contact us at <a href="mailto:US.CPA@gsk.com">US.CPA@gsk.com</a> or Lextech at
        <a href="mailto:security@lextech.com">security@lextech.com</a>.
      </p>
      <p>GSK Pharmaceutical and Vaccine products: <a href="tel:+18888255249">1.888.825.5249</a></p>
      <p>Lextech: <a href="tel:+16304209670">1.630.420.9670</a></p>
    </section>

    <section style="padding: 8px 0">
      <h2>Additional Information for California and Colorado Residents</h2>
      <p>
        This section applies only to personal information that is subject to California Consumer Privacy Act of 2018 as
        amended ("CCPA") and the Colorado Privacy Act ("CPA").
      </p>
      <p>
        In the past 12 months, we have collected and disclosed for our business purposes the categories of personal
        information described above in the
        <strong>"What personal information do we collect about you and how do we collect it?"</strong> section.
      </p>
    </section>

    <section style="padding: 8px 0">
      <h2>Rights to Opt Out of Sale or Sharing of Your Personal Information</h2>
      <p>
        We do not, and over the past 12 month, have not sold or shared your personal information as those terms of
        defined in the CCPA and CPA.
      </p>
    </section>

    <section style="padding: 8px 0">
      <h2>Transparency Regarding Data Requests</h2>
      <p>
        We do not retain information that personally identifies you. This means that we are unable to respond to any
        data subject requests because we cannot identify any information that may pertain to you or your use of the
        Services. To the extent you may have exercisable data subject rights with any of the Retailers, please review
        their privacy policies to determine how you can exercise your data subject rights with respect to personal data
        they may have collected about you.
      </p>
      <p>
        For California residents, this Platform was not functional in 2023 so no transparency information as required by
        the CCPA is available.
      </p>
    </section>

    <section style="padding: 8px 0">
      <h2>Colorado Resident Right to Appeal</h2>
      <p>
        If you disagree with our handling of your data subject request, in full or in part, you may appeal our decision
        by contacted us as set forth above. If we deny your appeal, you may also contact your state Attorney General to
        lodge a complaint. We will process requests to appeal within the timeframes established under applicable law,
        but typically within 45 days.
      </p>
    </section>
  </body>
</html>

    `,
    postbookingPrivacyHtmlEs: `
    <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <title></title>
    <meta
      name="viewport"
      content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
    />
  </head>
  <body>
    <h1>Aviso de prácticas de privacidad</h1>
    <p>Fecha de entrada en vigor: 24 de enero de 2024</p>

    <section style="padding: 8px 0">
      <p>
        EasyVax es una plataforma para programar turnos de vacunación (en adelante, la «Plataforma») que le permite
        encontrar y programar turnos. Para ello, la Plataforma revisa la disponibilidad de dosis y los horarios libres
        en múltiples farmacias de venta minorista (en adelante, el «Punto de venta minorista»). Además de programar el
        turno con el Punto de venta minorista elegido, esta Plataforma le permite añadirlo a su calendario en único paso
        (en adelante, los «Servicios»).
      </p>
      <p>
        En este Aviso de prácticas de privacidad, se establece cómo EasyVax recopila, usa, transfiere, procesa y divulga
        sus datos. Se establecen además las prácticas de seguridad de nuestra empresa. Respetamos su privacidad y hemos
        asumido el compromiso de proteger su información personal.
      </p>
      <p>
        Siempre que mencionemos «EasyVax», «nosotros», «nos» o nuestro, nos referimos a GSK, LLC, el propietario de la
        Plataforma y a nuestras subsidiarias (en adelante y de forma colectiva, «GSK»), así como Lextech Global
        Services Corporation, el desarrollador de la aplicación (en adelante, «Lextech»).
      </p>
      <p>
        EasyVax está destinada al uso por parte de usuarios finales y profesionales de la salud con licencia (en
        adelante y de forma colectiva, «usted» o los «Usuarios», respectivamente).
      </p>
      <p>
        Nuestras <a id="postbooking-terms" href="/a/terms">Condiciones de uso</a> también forman parte de este Aviso de
        prácticas de privacidad y tienen carácter vinculante para los Usuarios. Los Servicios están destinados a
        usuarios que tengan 18 años, como mínimo. Las personas menores de 18 años no pueden usar los Servicios.
      </p>
      <p>
        La información que se proporciona a través de la Plataforma tiene un carácter exclusivamente informativo y no
        está destinada a reemplazar el consejo médico de ningún farmacéutico o profesional de la salud. Esta información
        tampoco tiene como finalidad reemplazar la información que le brinde su plan sobre farmacias o médicos
        preferidos.
      </p>
      <p>
        Tenga en cuenta que nuestras prácticas de privacidad están sujetas a las leyes aplicables de los lugares en los
        que operamos. Es posible que, de forma eventual, modifiquemos este Aviso de prácticas de privacidad. Por ello,
        le recomendamos que revise este Aviso de forma periódica. Le recomendamos además que imprima una copia de esta
        Política de privacidad a fin de que obre en sus registros.
      </p>
      <p>
        Al usar los Servicios, acepta los términos de este Aviso de prácticas de privacidad y nuestras Condiciones de
        uso. Si, de alguna manera, no está de acuerdo con tales términos, debe dejar de usar los Servicios de inmediato.
      </p>
    </section>

    <section style="padding: 8px 0">
      <h2>¿Cómo funcionan nuestros Servicios?</h2>
      <p>
        Se puede acceder a nuestros Servicios a través de un código QR, la aplicación para dispositivos móviles EasyVax
        o el sitio web ubicado en <a target="_blank" href="https://www.easyvax.com">www.easyvax.com</a>. Una vez que
        accede a la Plataforma, se le solicitará que proporcione cierta información para identificar qué vacuna o
        vacunas le gustaría recibir. Esta información también nos permite encontrar los Puntos de venta minoristas más
        cercanos a la ubicación proporcionada que pueden administrarle las vacunas que busca.
      </p>
      <p>
        La búsqueda se puede realizar por código postal o por cualquier otro medio de ubicación. Además, puede filtrar
        la búsqueda por tipo de proveedor o nombre de proveedor en particular. Cualquier información que proporcione a
        través de la Plataforma en este paso se usa únicamente para ayudarle a encontrar un Punto de venta minorista que
        pueda administrar las vacunas que ha indicado que le gustaría recibir. Los resultados de búsqueda se extraen de
        una base de datos pública y de alcance nacional de farmacias, así como de la red de profesionales de la salud
        participantes. Si bien esta base de datos y la red se actualizan de forma regular, es posible que en la lista no
        se incluya la información más reciente del proveedor o que los resultados que se muestren no reflejen a todos
        los proveedores de vacunas para adultos en el área que indicó.
      </p>
      <p>
        La inclusión de una farmacia o un médico en la lista de Puntos de venta minoristas en los resultados de búsqueda
        de los Servicios no implica una referencia, un respaldo ni una recomendación nuestra o de cualquiera de nuestros
        productos, ni lo implicará en el futuro. La participación de un Punto de venta minorista en las redes de
        pagadores puede variar. Debe comprobar que un determinado Punto de venta minorista acepte su seguro de salud o
        participe de la red de este.
      </p>
    </section>

    <section style="padding: 8px 0">
      <h2 id="pii-usage">¿Qué información personal recopilamos sobre usted y cómo la recopilamos?</h2>
      <p>
        Para los fines indicados, solo recopilamos la información que necesitamos para encontrar y ponerlo en contacto
        con un Punto de venta minorista que pueda proporcionarle servicios relacionados con la administración de
        vacunas. A fin de que nuestra parte de los Servicios funcione, solo necesitamos la ubicación en la que desea
        colocarse la vacuna, por ejemplo, el código postal y el tipo de vacuna que desea recibir.
      </p>
      <p>
        Cuando elija un Punto de venta minorista de entre aquellos identificados como disponibles para brindarle los
        servicios solicitados en la ubicación seleccionada, es posible que se le solicite que proporcione información
        adicional a ese Punto de venta minorista. Esta información se solicita para programar los servicios de
        vacunación en una ubicación seleccionada. De acuerdo con los requisitos de los Puntos de vista de minoristas,
        entre la información personal (IP) que se puede solicitar, se incluye información personal confidencial, entre
        ella:
      </p>
      <table aria-describedby="pii-usage" border="1">
        <thead>
          <tr style="border-width: 1px">
            <th>Categorías de información personal recopilada</th>
            <th>Descripción</th>
          </tr>
        </thead>
        <tbody>
          <tr style="border-width: 1px">
            <td>Nombre, información de contacto e identificadores únicos</td>
            <td>
              Identificadores, como nombre real, alias, dirección postal, número de teléfono, dirección de correo
              electrónico u otros identificadores de igual naturaleza, así como información demográfica, por ejemplo, la
              fecha de nacimiento.
            </td>
          </tr>
          <tr style="border-width: 1px">
            <td>Información médica</td>
            <td>
              Cualquier información que maneje en relación con su historial médico, estado mental o condición física o
              tratamiento que se le haya indicado, o bien esta misma información derivada de un proveedor de atención de
              la salud, una aseguradora de atención de la salud, un plan de servicios de atención de la salud, una
              compañía farmacéutica o un contratista. En esta información, se incluye el número de póliza de seguro de
              una persona o el número de identificación del suscriptor, cualquier identificador único utilizado por una
              aseguradora de salud para identificar a la persona, o bien cualquier información que se incluya en una
              solicitud y en el historial de reclamaciones de esa persona (incluida la información de recetas).
            </td>
          </tr>
          <tr style="border-width: 1px">
            <td>Características protegidas</td>
            <td>
              Características de las clasificaciones que gozan de protección legal, por ejemplo, raza, género, edad,
              nacionalidad, discapacidad física o mental y religión.
            </td>
          </tr>
          <tr style="border-width: 1px">
            <td>Información biomédica</td>
            <td>
              Características fisiológicas, biológicas o de comportamiento que puedan determinar la identidad de una
              persona, incluido cualquier muestra de ADN, imágenes del rostro, el iris o la retina, huellas dactilares,
              grabaciones de voz y datos de sueño, salud o ejercicio que incluyan información sujeta a identificación.
            </td>
          </tr>
        </tbody>
      </table>
    </section>

    <section style="padding: 8px 0">
      <h2>¿Cómo usamos su información?</h2>
      <p>
        Usamos la información que nos proporciona para localizar un Punto de venta minorista cerca de la ubicación que
        identificó y que satisfaga los criterios de búsqueda, a fin de recibir los Servicios de vacunación que desea
        obtener.
      </p>
    </section>

    <section style="padding: 8px 0">
      <h2>¿Durante cuánto tiempo conservamos su información personal?</h2>
      <p>
        Toda información que conservamos es de tipo anónima; esto significa que no conservamos ninguna información que
        permita identificarlo.
      </p>
      <p>
        Si tiene alguna duda acerca de cómo conserva su información personal el Punto de vista minorista seleccionado,
        consulte las políticas de privacidad de ese Punto de venta minorista.
      </p>
      <p>Lextech no conserva ninguna información.</p>
    </section>

    <section style="padding: 8px 0">
      <h2>¿Con quién compartimos su información personal?</h2>
      <p>
        Toda información personal que proporcione es información requerida por el Punto de venta minorista seleccionado
        para los fines de este, no los nuestros. Esta información está sujeta a un proceso de cifrado de extremo a
        extremo para transferirla al Punto de venta minorista seleccionado. Esto significa que, durante el proceso de
        transferencia, la información personal es indescifrable e irreversible. Usted asume la responsabilidad exclusiva
        por las comunicaciones y las interacciones que mantenga con cualquiera de los Puntos de venta minoristas que se
        incluyan en los resultados de búsqueda, así como con los proveedores que trabajen en nombre de estos. Por este
        motivo, cualquier información que les proporcione no se rige por este Aviso de prácticas de privacidad. Revise
        la política de privacidad del Punto de venta minorista seleccionado para saber cómo tratan su información
        personal, así como para consultar cualquier derecho que pueda tener con respecto al uso y recopilación de su
        información por parte de ese Punto de venta minorista.
      </p>
      <p>
        LexTech comparte datos de actividad de usuario no identificados solo con nuestro proveedor y patrocinador de
        servicios de tóquenes, GSK. Los fines por los que se comparten tales datos es comprender mejor cuántos usuarios
        utilizan la Plataforma, incluidos los Servicios que se buscan, mejorar los Servicios y realizar investigaciones
        anónimas. Los datos se comparten través de un proveedor de servicios de especialidad que convierte esos datos a
        tóquenes antes de compartirlos para los fines establecidos más arriba. Un toquen es una pieza de datos que
        representa otra pieza de información de mayor valor. Los tóquenes no tienen valor y solo son útiles porque
        representan algo de valor. En otras palabras, un toquen es como una ficha de póquer. En lugar de llenar una mesa
        con dinero en efectivo, que se puede robar con facilidad, los jugadores usan fichas como marcadores de posición.
        Sin embargo, las fichas de póquer no se pueden usar como dinero, incluso si las roban. Primero se deben cambiar
        por su valor representativo. Esta misma lógica se aplica a través de los Servicios cuando proporciona
        información personal al Punto de venta minorista: esa información está toquenizada y solo podemos acceder a ella
        de ese modo. Nunca vemos, nunca almacenamos y nunca usamos la información personal que proporciona y que se
        proporciona al Punto de venta minorista. La única información que vemos, almacenamos y a la que tenemos acceso
        es en forma de toquen.
      </p>
      <p>
        No conservamos información personal. Lextech no conserva ningún tipo de información, incluidos los datos de
        actividad de usuario o la información personal.
      </p>
      <p>
        Compartiremos aquella información que podemos conservar con las agencias de regulación locales o del extranjero,
        o bien con las autoridades policiales y del gobierno, según sea necesario o resulte apropiado, en particular
        cuando tengamos la obligación legal de hacerlo. Como se mencionó los destinatarios de esta información pueden
        encontrarse en su país de residencia o en el extranjero. Además, es posible que divulguemos su información por
        cualquier otro motivo de índole legal, por ejemplo, para hacer cumplir nuestros términos y condiciones; también
        podemos divulgar su información para proteger nuestros derechos, nuestra privacidad y seguridad o nuestra
        propiedad, y la de nuestras empresas controladas, la suya o la terceros.
      </p>
      <p>
        Es posible que compartamos la información que podemos conservar por motivo de venta o por transacciones
        comerciales; por ejemplo, una reorganización, fusión, venta, empresa conjunta, cesión, transferencia de la
        Empresa, o bien por cualquier otra disposición relacionada con la totalidad o parte de nuestro negocio, activos
        o acciones —incluso en relación con cualquier proceso de quiebra o procedimiento de igual naturaleza—.
      </p>
    </section>

    <section style="padding: 8px 0">
      <h2>¿En qué casos transferimos su información personal fuera de su país de origen?</h2>
      <p>
        Los Servicios solo se ofrecen en Estados Unidos y están destinados solo a personas que se encuentren en este
        país.
      </p>
    </section>

    <section style="padding: 8px 0">
      <h2>¿Cómo protegemos su información personal?</h2>
      <p>
        La transferencia de información a cualquier Punto de venta minorista se realiza mediante un proceso de cifrado
        de extremo a extremo. La transferencia a GSK se realiza a través de un proceso de toquenización, que convierte
        los datos en información anónima. Tomamos las medidas legales, organizativas y técnicas adecuadas para proteger
        su información personal, de acuerdo con todas las leyes de privacidad y seguridad de datos aplicables. Como se
        explicó más arriba, la transferencia de su información, no la del Punto de venta minorista, se realiza a través
        de un proceso de toquenización y el uso de su información por parte de nosotros es solo en forma anónima.
      </p>
      <p>
        Lamentablemente, la transmisión de información a través de Internet o de una conexión de red de telefonía móvil
        nunca es del todo segura. Aunque tomaremos todas las medidas necesarias para proteger su información personal,
        no podemos garantizar la seguridad de la información que comparta con nuestros sitios web o nuestras
        aplicaciones para dispositivos móviles: cualquier transmisión de información corre por su propio riesgo. Si bien
        no podemos garantizar que no se produzca ninguna pérdida, uso indebido o alteración de datos, una vez que
        hayamos recibido su información, implementaremos todas las medidas de seguridad técnicas pertinentes para
        prevenir tales incidentes desafortunados.
      </p>
    </section>

    <section style="padding: 8px 0">
      <h2>¿Cuáles son sus derechos y opciones con respecto a su información personal?</h2>
      <p>
        No conservamos ningún tipo de información que permita identificarlo de forma personal. Esto significa que no
        podemos responder a las solicitudes de ningún titular de los datos, ya que no podemos identificar ninguna
        información que se relacione con usted ni con el uso que realiza de los Servicios. En la medida en que goce de
        derechos por ser el titular de los datos, consulte las políticas de privacidad de los Puntos de venta
        minoristas, a fin de determinar cómo puede ejercerlos en relación con los datos personales que puedan haber
        recopilado sobre usted.
      </p>
      <p>
        <strong>Señales de 'No rastrear', control general de privacidad y mecanismos similares.</strong> Algunos
        navegadores web transmiten señales de 'Do-Not-Track Signals' o 'No rastrear' a los sitios web. Debido a que la
        señal estándar 'No rastrear' basada en el navegador aún no goza de una aceptación generalizada, en este momento
        no respondemos a esas señales.
      </p>
    </section>

    <section style="padding: 8px 0">
      <h2>¿Qué sucede con la información de los menores?</h2>
      <p>
        Nuestros Servicios no están dirigidos a menores de 16 años. Por ello, no recopilamos, vendemos ni compartimos de
        forma deliberada información personal de menores para fines de publicidad dirigida.
      </p>
    </section>

    <section style="padding: 8px 0">
      <h2>¿Cómo actualizamos este Aviso de prácticas de privacidad?</h2>
      <p>
        Es posible que, de forma eventual, actualicemos este Aviso de prácticas de privacidad. Cualquier cambio entrará
        en vigor cuando publiquemos el Aviso de prácticas de privacidad revisado, aunque, en algunos casos, podemos
        optar por notificarle de otro modo si la naturaleza de los cambios es significativa o cuando lo exija la ley.
        Este Aviso de prácticas de privacidad se actualizó por última vez en la fecha que figura en el título «Última
        actualización» que se muestra arriba.
      </p>
    </section>

    <section style="padding: 8px 0">
      <h2>Información de contacto y punto de contacto por motivos de privacidad</h2>
      <p>
        Si tiene alguna duda relacionada con este Aviso de prácticas de privacidad, si necesita más información o si
        quiere plantear una inquietud de privacidad, póngase en contacto con nosotros mediante un mensaje a esta
        dirección <a href="mailto:US.CPA@gsk.com">US.CPA@gsk.com</a>. Si así lo desea, también puede comunicarse con
        Lextech en esta dirección <a href="mailto:security@lextech.com">security@lextech.com</a>.
      </p>
      <p>Vacunas y productos farmacéuticos de GSK: <a href="tel:+18888255249">1 888‑825 5249</a></p>
      <p>Lextech: <a href="tel:+16304209670">1 630‑420‑9670</a></p>
    </section>

    <section style="padding: 8px 0">
      <h2>Información adicional para residentes de California y Colorado</h2>
      <p>
        El contenido de esta sección rige únicamente para la información personal que está sujeta a la Ley de Privacidad
        del Consumidor de California de 2018 (CCPA, sigla en inglés), y sus enmiendas, así como a la Ley de Privacidad
        de Colorado (CPA, sigla en inglés).
      </p>
      <p>
        Durante los últimos 12 meses, hemos recopilado y divulgado para nuestros fines comerciales las categorías de
        información personal que se describen más arriba en la sección
        <strong>«¿Qué información personal recopilamos sobre usted y cómo la recopilamos?»</strong>.
      </p>
    </section>

    <section style="padding: 8px 0">
      <h2>
        Sus derechos para excluirse de forma voluntaria de la venta de información personal o para limitar el uso
        compartido de esta
      </h2>
      <p>
        Durante los últimos 12 meses, no hemos vendido ni compartido su información personal, de acuerdo con los
        términos establecidos en la CCPA y la CPA.
      </p>
    </section>

    <section style="padding: 8px 0">
      <h2>Transparencia con respecto a las solicitudes de datos</h2>
      <p>
        No conservamos ningún tipo de información que permita identificarlo de forma personal. Esto significa que no
        podemos responder a las solicitudes de ningún titular de los datos, ya que no podemos identificar ninguna
        información que se relacione con usted ni con el uso que realiza de los Servicios. En la medida en que goce de
        derechos por ser el titular de los datos, consulte las políticas de privacidad de los Puntos de venta
        minoristas, a fin de determinar cómo puede ejercerlos en relación con los datos personales que puedan haber
        recopilado sobre usted.
      </p>
      <p>
        En el caso de los residentes de California, esta Plataforma no prestaba servicios en 2023, por lo que no hay
        información de transparencia disponible según se exige en la CCPA.
      </p>
    </section>

    <section style="padding: 8px 0">
      <h2>Derecho de apelación para residentes de Colorado</h2>
      <p>
        En el caso de que no esté de acuerdo, ya sea en su totalidad o en partes, con el tratamiento que le hayamos dado
        a su solicitud de datos en carácter de titular, es posible que pueda apelar nuestra decisión. Para ello, póngase
        en contacto con nosotros a través de los medios que se detallan más arriba. Si denegamos su apelación, es
        posible que además pueda ponerse en contacto con la Oficina del Fiscal General de su estado a fin de presentar
        una queja formal. Procesaremos las solicitudes de apelación en los plazos que marca la legislación aplicable. En
        general, este plazo es de 45 días.
      </p>
    </section>
  </body>
</html>

    `,
    postbookingTermsHtmlEn: `
<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <title></title>
    <meta
      name="viewport"
      content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
    />
  </head>
  <body>
    <h1>Terms of Use</h1>
    <p>
      These Terms of Use define the terms by which you may use the EasyVax scheduler, platform and services provided by
      EasyVax and are an agreement between you, Lextech Global Services Corporation, the application developer and GSK,
      LLC the application owner and their subsidiaries (“Company”, “we” or “us”). These Terms of Use apply to your use
      of the EasyVax scheduler, The scheduler can be used by end users (“End Users”) and licensed healthcare
      professionals (“Professional Users”) (referred to collectively as “you” or “Users”). The
      <a id="postbooking-privacy" href="/a/privacy">Privacy Notice</a> also forms part of these Terms of Use. Any
      reference to our Terms of Use includes all such policies.
    </p>
    <p>
      By using the EasyVax scheduler, you expressly acknowledge that you have read, accept, and agree to be bound by
      them, as well as any applicable laws hereunder. You should not use the EasyVax scheduler if you do not agree to
      these Terms of Use.
    </p>

    <ol>
      <li>
        CHANGES TO THE TERMS OF SERVICE. We reserve the right, at our sole discretion, to change, modify or alter the
        Terms of Use, inclusive of the Privacy Notice, at any time. Such changes shall become effective immediately upon
        the posting thereof. You must review the Terms of Use on a regular basis to keep yourself apprised of any
        changes.
      </li>
      <li>
        ELIGIBILITY TO USE OUR SERVICES. Only adults, who are at least eighteen (18) years of age, located in the United
        States of America, are eligible to use our scheduler. You may use the scheduler to facilitate obtaining or
        obtain healthcare services for a minor if you are the minor’s parent or legal guardian. In addition, you must be
        fully competent to enter into and to comply with these Terms of Use. You represent and warrant that you have the
        right, authority, and capacity to enter into these Terms of Use and can abide by all of the terms and conditions
        set forth herein.
      </li>
      <li>
        COMPANY DOES NOT PROVIDE MEDICAL ADVICE. The scheduler enables consumers and healthcare providers to schedule
        appointments for vaccinations at retailers. The scheduler does not constitute medical advice or endorse any
        course of treatment. Company expressly disclaims all liability with respect to any healthcare information you
        obtain through the scheduler and any actions taken or not taken based on your use of our scheduler. Any reliance
        on such scheduler is at your own risk. No licensed medical professional/patient relationship is created between
        you and Company when you use our scheduler.
      </li>
      <li>
        RELATIONSHIPS BETWEEN RETAIL OUTLETS AND END USERS. RETAIL OUTLETS relationships with End Users, including
        patients and non-patients, is directly between the RETAIL OUTLET and the End Users.
      </li>
      <li>
        RETAIL OUTLETS AND USERS COMPLIANCE WITH APPLICABLE LAWS, REGULATION AND LICENSURE REQUIREMENTS. To the extent
        Company provides information, template documents or forms to assist Retail Outlets allowing patients to schedule
        appointments, Company does so solely as a convenience to Retail Outlets. Company is not providing legal or
        business advice to Retail Outlets. Retail Outlets should seek qualified legal counsel to confirm applicability
        or and/or customize any template documents before utilizing such document in the provision of healthcare
        services. Retail Outlets assume sole liability and responsibility for complying with all relevant state and
        federal laws, reimbursement rules and third party payor agreements.
      </li>
      <li>
        LIMITATIONS AND CANCELLATIONS. We may at any time create limitations of your use of our scheduler including, but
        not limited to, the number of times you can access the scheduler. All Users are prohibited from and may be
        blocked from using the Scheduler if:
        <ol type="a">
          <li>you have abused your rights to use the scheduler;</li>
          <li>
            you have breached any of the terms specified in this document, including but not limited to acted in a
            manner that violates our prohibited conduct policy;
          </li>
          <li>you submit false information;</li>
          <li>you have performed any act or omission that violates any applicable law, rules, or regulations;</li>
          <li>
            you have performed any act or omission which is harmful or likely to be harmful to us, or any other third
            party, including other users and partners of Company;
          </li>
          <li>
            you made use of our scheduler to perform an illegal act, or for the purpose of enabling, facilitating,
            assisting or inducing the performance of such an act;
          </li>
          <li>you use the scheduler for any private commercial purpose without our express consent;</li>
        </ol>
      </li>
      <li>
        PROHIBITED CONDUCT. The scheduler should be used only for lawful purposes. We specifically prohibit you from
        engaging in any of the following conduct:
        <ol type="a">
          <li>Any activity that produces software viruses or code harmful to other computers;</li>
          <li>
            Submitting to the scheduler any information in which you impersonate or claim to be any third party, or in
            which you misrepresent your affiliation with another person or entity;
          </li>
          <li>Sharing information that you are under an obligation not to disclose;</li>
          <li>
            Taking any action that disrupts, tampers with, interferes with, or imposes an unreasonable burden on the
            scheduler infrastructure, servers, data, or network or those of any third party via our Services;
          </li>
          <li>
            Using or attempting to use any engine, software tool, agent, or other device or mechanism (including without
            limitation browsers, spiders, robots, avatars, or intelligent agents) to navigate or search the scheduler
            other than generally available third party browsers;
          </li>
          <li>
            Using any means of automatically searching or mining data from the scheduler, or in any way attempting to
            interfere with the proper working of the scheduler;
          </li>
          <li>Collecting or storing personal information about any customer or visitor of the scheduler;</li>
          <li>
            Using any scheduling, communications, or transactional services on the scheduler in an abusive or improper
            manner or in any way that disrupts or impedes the intended use of such features by other users and/or
            providers.
          </li>
        </ol>
        You agree not to decompile or reverse engineer or otherwise attempt to discover any source code contained in the
        scheduler. Unless you receive explicit permission, you agree not to reproduce, duplicate, copy, sell, resell or
        exploit for any commercial purposes, any aspect of the scheduler.
      </li>
      <li>
        SCHEDULING. If a Retail Outlet is enabled, you will be able to submit a general request for an appointment. The
        scheduler collects the information requested by the Retail Outlet. By using this functionality, you expressly
        authorize us to provide to the Retail Outlet the health information you give to us. If you obtain healthcare
        from a provider by using our scheduler you are entirely responsible for your healthcare expenses. You must
        resolve any dispute between you and a Retail Outlet or healthcare provider directly with the Retail Outlet and
        healthcare provider.
      </li>
      <li>
        OPERATION OF THE SERVICES. We use commercially reasonable efforts to maintain our scheduler and to keep them
        operating with high availability and free from bugs, errors, technical problems, or defects. If, at any time in
        the future, we identify any bugs, errors, technical problems, or defects, then we will assign technicians to
        address and resolve the issue. We cannot guarantee that your access to the scheduler will be uninterrupted, or
        that the scheduler will be available at all times. We can assume no liability or responsibility for any delay,
        interruption, or downtime. We use commercially reasonable efforts to ensure that our scheduler is protected from
        viruses and other destructive software, but we cannot guarantee that the scheduler will at all times be free
        from viruses. We reserve the right to discontinue operation of our scheduler at any time for any reason at our
        sole discretion, and to make modifications to the operation of the scheduler at any time at our sole discretion.
      </li>
      <li>
        INTELLECTUAL PROPERTY. We, our partner, affiliates, or our licensors shall retain all respective right, title,
        and interest in the marks, logos, codes, databases, Content, text, designs, photos, and other materials posted
        to our scheduler (“Intellectual Property”). Except as otherwise expressly stated herein, you may only display
        and view the Intellectual property posted to the scheduler, and you may not reproduce, display, distribute,
        create derivative works of, misappropriate, or otherwise use for any purpose any portion of our scheduler
        without the express written permission of us or our licensors as appropriate. Using the Intellectual Property on
        our scheduler on any other website or in any other services or product for any commercial purpose is expressly
        prohibited.
      </li>
      <li>
        WARRANTY. The use of our scheduler or your reliance on any of the foregoing shall be at your own risk. We can
        make no warranty that the scheduler will meet your needs or that their operation will be continuous,
        uninterrupted, bug-free, error-free, virus-free, free of defects, free of technical problems.
      </li>
      <li>
        CONSEQUENTIAL DAMAGES; LIMITATION OF LIABILITY. UNDER NO CIRCUMSTANCES SHALL WE BE LIABLE FOR DIRECT, INDIRECT,
        INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES, RESULTING FROM ANY ASPECT OF YOUR USE OF THE WEBSITE OR SERVICES
        INCLUDING BUT NOT LIMITED TO DAMAGES THAT ARISE FROM YOUR INABILITY TO USE THE WEBSITE OR THE SERVICE; LOST
        PROFITS; LOST GOODWILL; LOST DATA; ANY INTERRUPTION, MODIFICATION, OR TERMINATION OF THE WEBSITE OR ANY SERVICE
        OR PART THEREOF; OR OTHER INTANGIBLE LOSSES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES).
        Our liability to you shall in no event exceed the total aggregate amount of $500 for all claims. Some
        jurisdictions do not allow the limitation of liability, so these limitations may not apply to you.
      </li>
      <li>
        RELEASE OF CLAIMS. To the maximum extent permitted by applicable law, you hereby release and waive all claims
        against us and our officers, directors, employees, independent contractors, representatives, affiliates and
        agents and from any and all liability for claims, damages (actual and or consequential), costs and expenses
        (including litigation costs and attorneys’ fees) of every kind and nature arising from or in any way related to
        our Services. If applicable, you waive your rights under California Civil Code Section 1542, which states, “A
        general release does not extend to claims which the creditor does not know or suspect to exist in his or her
        favor at the time of executing the release, which if known by him or her must have materially affected his
        settlement with the debtor.” You understand that any fact relating to any matter covered by this release may be
        found to be other than now believed to be true, and accept and assume the risk of such possible differences in
        fact. In addition, you expressly waive and relinquish any and all rights which you may have had under any other
        state or federal statute or common law principle of similar effect, to the fullest extent permitted by law.
      </li>
      <li>
        INDEMNIFICATION. You agree to indemnify and hold us and our officers, directors, subsidiaries, affiliates,
        successors, assigns, agents, service providers, suppliers and employees, harmless from any third party claim or
        demand arising from your use of the scheduler; (c) your violation of the Terms of Use; (d) your breach of any of
        the representations and warranties herein; (e) your violation of any rights of another, including but not
        limited to any liability or expenses arising from any and all claims, demands, losses, damages, costs, expenses,
        suits, judgments, litigation costs, or reasonable attorney fees and court costs; or (f) your violation of any
        law, regulation, or guidelines imposed by any administrative body.
      </li>
      <li>
        GOVERNING LAW; DISPUTE RESOLUTION. These Terms of Service shall be governed by and construed in accordance with
        the laws of the State of New York, without regard to conflicts of law principles. All disputes with the Company
        arising under this Agreement shall be submitted to binding arbitration under the Commercial Rules of the
        American Arbitration Association in New York by one arbitrator mutually agreed upon by you and us in accordance
        with the aforementioned Rules. The costs of arbitration, including administrative and arbitrators’ fees, shall
        be shared equally by you and us. The arbitration award shall be final and each of us shall comply in good faith
        to the entry of the arbitrator’s award in any court having jurisdiction. If judicial enforcement or review is
        sought, then the prevailing party shall be entitled to costs and reasonable attorney’s fees. All claims you
        bring against us or the Services must be resolved in accordance with this Section. All claims filed or brought
        against us contrary to the terms of this Section shall be considered improperly filed. Should you file a claim
        contrary to this Section, you agree that we may recover attorneys’ fees and costs for the improperly filed
        claim, provided that we have notified you in writing of the issue and you have failed to properly withdraw the
        claim.
      </li>
    </ol>
  </body>
</html>

    `,
    postbookingTermsHtmlEs: `
    <!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8" />
    <title></title>
    <meta
      name="viewport"
      content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
    />
  </head>
  <body>
    <h1>Condiciones de uso</h1>
    <p>
      En estas Condiciones de uso se definen las condiciones para el uso del programador EasyVax, la plataforma y los
      servicios proporcionados por EasyVax. Esta Condiciones constituyen además un acuerdo entre usted,
      Lextech Global Services Corporation, el desarrollador de la aplicación, GSK, LLC, el propietario de la aplicación,
      y las demás empresas controladas (en adelante, la «Empresa», «nosotros» o «nos»). Estas Condiciones de uso rigen
      su uso del programador EasyVax. Además, el programador EasyVax está destinado al uso por parte de usuarios finales
      (en adelante, los «Usuarios finales») y profesionales de la salud con licencia (en adelante, los «Usuarios
      profesionales») (en adelante y de forma colectiva, «usted» o los «Usuarios», respectivamente). El <a
        id="postbooking-privacy"
        href="/a/privacy"
        >Aviso de prácticas de privacidad</a
      >
      también forma parte de estas Condiciones de uso. De este modo, toda referencia a nuestras Condiciones de uso
      incluye todas estas políticas.
    </p>
    <p>
      Al usar el programador EasyVax, reconoce expresamente que ha leído, acepta y se compromete a respetar las
      Condiciones de uso, así como cualquier ley aplicable, en virtud del presente. Si no está de acuerdo con estas
      Condiciones de uso, no debe usar el programador EasyVax.
    </p>

    <ol>
      <li>
        CAMBIOS A ESTAS CONDICIONES DE USO. Nos reservamos el derecho, a nuestra entera discreción, a cambiar, modificar
        o, de cualquier otro modo, alterar las Condiciones de uso, incluido el Aviso de prácticas de privacidad, en
        cualquier momento. Cualquiera de cambios entrarán en vigor inmediatamente después de la publicación. A fin de
        mantenerse informado de cualquier cambio aplicado, debe revisar estas Condiciones de uso de forma regular.
      </li>
      <li>
        IDONEIDAD PARA EL USO DE NUESTROS SERVICIOS. Aquellas personas que tengan dieciocho (18) años, como mínimo, y
        que se encuentran en Estados Unidos de América reúnen los requisitos para el uso de nuestro programador. Si
        usted es el padre o tutor legal de un menor, puede usar el programador para acceder a servicios de atención
        médica destinados a ese menor, o bien para facilitar tal acceso. Además, debe estar en pleno uso de sus
        facultades para celebrar y cumplir con estas Condiciones de uso. Por el presente, declara y asegura que tiene el
        derecho, la autoridad y la capacidad para celebrar estas Condiciones de uso, así como que puede cumplir con
        todos los términos y condiciones que se establecen en este documento.
      </li>
      <li>
        LA EMPRESA NO BRINDA CONSEJO MÉDICO. El programador permite que consumidores y proveedores de atención de la
        salud programen una cita para vacunarse en tiendas minoristas. El programador no tiene por objetivo brindar
        consejo médico ni respalda ningún curso de tratamiento. La Empresa renuncia expresamente a toda responsabilidad
        en relación a cualquier información de atención de la salud que se obtenga a través del programador. Renuncia
        además a cualquier acción tomada producto del uso que usted realice de nuestro programador, o bien a las
        acciones que no se hayan tomado por el mismo motivo. Cualquier decisión que se tome basada en la confianza en el
        programador corre por su propia cuenta. El uso de nuestro programador no implica ni genera ninguna relación de
        tipo profesional de atención de la salud-paciente entre usted y la Empresa.
      </li>
      <li>
        RELACIONES ENTRE LOS PUNTOS DE VENTA MINORISTAS Y LOS USUARIOS FINALES. Las relaciones entre los PUNTOS DE VENTA
        MINORISTAS y los Usuarios finales, incluidos pacientes y no pacientes, se establecen directamente entre un PUNTO
        DE VENTA MINORISTA y el Usuario final.
      </li>
      <li>
        CUMPLIMIENTO DE LAS LEYES APLICABLES, LOS REGLAMENTOS Y LOS REQUISITOS DE LICENCIA POR PARTE DE LOS PUNTOS DE
        VENTA MINORISTAS Y LOS USUARIOS. En el caso de que la Empresa proporcione información, plantillas de documentos
        o formularios con el objetivo de ayudar a los Puntos de venta minoristas a que los pacientes programen citas, se
        deja constancia de que la Empresa lo hace únicamente para comodidad de los Puntos de venta minoristas. Se
        entiende, por lo tanto, que la Empresa no brinda ningún tipo de asesoramiento legal o comercial a los Puntos de
        venta minoristas. Los Puntos de venta minoristas deben buscar asesoramiento legal calificado a fin de confirmar
        la idoneidad de uso o para adaptar cualquier documento de plantilla antes de usar tal documento para la
        prestación de servicios de atención de la salud. Los Puntos de venta minoristas asumen la responsabilidad
        exclusiva de cumplir con todas las leyes estatales y federales pertinentes, las reglas de reembolso y los
        acuerdos de terceros pagadores.
      </li>
      <li>
        LIMITACIONES Y CANCELACIONES. Podemos, en cualquier momento, crear limitaciones de su uso para nuestro
        programador, incluido, entre otros, la cantidad de veces que puede acceder a esta herramienta. En cualquiera de
        los siguientes casos, los Usuarios tienen prohibido el uso del programador y, de hacerlo, pueden quedar
        bloqueados:
        <ol type="a">
          <li>cuando se haya abusado de los derechos de uso del programador;</li>
          <li>
            cuando se haya incumplido cualquiera de las condiciones que se indican en este documento, incluido, entre
            otros, actuar de una manera que infrinja nuestra política de conductas prohibidas;
          </li>
          <li>cuando se envíe información falsa;</li>
          <li>
            cuando se haya realizado algún acto que infrinja cualquier ley, regla o reglamento aplicables, o bien no
            haberlo hecho para cumplir con estos;
          </li>
          <li>
            cuando se haya realizado algún acto que sea perjudicial, o que pueda serlo, para nosotros o para cualquier
            otro tercero, incluidos otros usuarios y socios de la Empresa, o bien no haberlo hecho para evitar tal daño;
          </li>
          <li>
            cuando haya usado nuestro programador para realizar un acto penado por la ley, o bien con el propósito de
            permitir, facilitar, ayudar o inducir la realización de tal acto;
          </li>
          <li>
            cuando haya usado nuestro programador para cualquier propósito comercial privado, sin antes contar con
            nuestro consentimiento expreso.
          </li>
        </ol>
      </li>
      <li>
        CONDUCTAS PROHIBIDAS. El programador se debe usar únicamente para fines legales. Por medio del presente, queda
        expresamente prohibido participar en cualquiera de las siguientes conductas:
        <ol type="a">
          <li>cualquier actividad que produzca virus de software o códigos dañinos en otros equipos informáticos;</li>
          <li>
            enviar al programador cualquier información en la que se haga pasar un tercero o en la que afirme serlo, así
            como cualquier información que tergiverse su grado de afiliación con otra persona o entidad;
          </li>
          <li>compartir información sujeta a restricciones de divulgación que haya aceptado cumplir;</li>
          <li>
            llevar a cabo cualquier acción que interrumpa, altere, interfiera o imponga una carga excesiva en la
            infraestructura, los servidores, los datos o la red del programador o los de cualquier tercero a través de
            nuestros Servicios;
          </li>
          <li>
            usar, o intentar usar, cualquier motor, herramienta de software, agente o cualquier otro dispositivo o
            mecanismo —incluido, entre otros, navegadores, arañas, robots, avatares o agentes inteligentes— para navegar
            por el programador o realizar una búsqueda en este que no sean navegadores de terceros de uso general y
            habitual;
          </li>
          <li>
            usar cualquier medio de búsqueda o extracción automática de datos en el programador o, de cualquier manera,
            interferir con el funcionamiento adecuado del programador;
          </li>
          <li>recopilar o almacenar información personal de cualquier cliente o visitante del programador;</li>
          <li>
            usar cualquier método de programación o comunicación o servicio comercial en el programador de manera
            abusiva o inapropiada, o bien hacerlo de cualquier otra manera que interrumpa o impida el uso previsto de
            tales funciones por parte de otros usuarios o proveedores.
          </li>
        </ol>
        Acepta no descompilar, realizar ingeniería inversa ni intentar descubrir ningún código fuente que se incluya en
        el programador. A menos que reciba una autorización explícita, acepta no reproducir, duplicar, copiar, vender,
        revender o explotar para ningún aspecto del programador para propósito comercial.
      </li>
      <li>
        PROGRAMACIÓN. Si un Punto de venta minorista se encuentra debidamente habilitado, podrá enviar una solicitud
        general para programar una cita. El programador recopila la información solicitada por el Punto de venta
        minorista. Al usar esta funcionalidad, nos autoriza expresamente a brindar al Punto de venta minorista la
        información de salud que nos facilita. Si obtiene algún servicio de atención de la salud de un proveedor
        mediante el uso de nuestro programador, usted asume la responsabilidad total por los gastos de atención médica
        pertinentes. Debe resolver cualquier controversia que surja entre usted y un Punto de venta minorista o
        proveedor de atención de la salid directamente con ese Punto de venta minorista y ese proveedor de atención de
        la salud.
      </li>
      <li>
        FUNCIONAMIENTO DE LOS SERVICIOS. Tomamos todas las medidas razonables desde el punto de vista comercial para
        mantener operativo nuestro programador, a fin de que brinde servicio con una alta disponibilidad y libre de
        errores, fallos, problemas técnicos o defectos. Si, en algún momento en el futuro, identificamos algún error,
        problema técnico o defecto, asignaremos personal técnico para abordar y resolver ese problema. No podemos
        garantizar un acceso ininterrumpido al programador, así como tampoco que esté disponible en todo momento. En el
        mismo sentido, no asumimos responsabilidad alguna por ningún retraso, interrupción o tiempo de inactividad.
        Tomamos todas las medidas razonables desde el punto de vista comercial para garantizar que nuestro programador
        esté protegido contra virus y otros software destructivos, pero no podemos garantizar que esté libre de virus en
        todo momento. Nos reservamos el derecho a interrumpir el funcionamiento, o realizar modificaciones en el
        funcionamiento, de nuestro programador en cualquier momento y por cualquier motivo a nuestra entera discreción.
      </li>
      <li>
        PROPIEDAD INTELECTUAL. Nosotros, nuestros socios, empresas controladas o nuestros licenciantes conservaremos
        todos los derechos, títulos e intereses respectivos sobre las marcas, logotipos, códigos, bases de datos,
        contenido, texto, diseños, fotografías y otros materiales que se publiquen en nuestro programador (en adelante,
        la «Propiedad intelectual»). Salvo que se indique expresamente lo contrario en este documento, solo puede
        mostrar y ver la Propiedad intelectual publicada en el programador; por lo tanto, no puede reproducir, mostrar,
        distribuir, crear obras derivadas, apropiarse indebidamente ni, de cualquier otra manera, usar ninguna parte de
        nuestro programador para ningún propósito sin antes contar con una autorización expresa por escrito de nosotros
        o nuestros licenciantes, según corresponda. Queda expresamente prohibido el uso de la Propiedad intelectual
        publicada en nuestro programador en cualquier otro sitio web o en cualquier otro servicio o producto para
        cualquier propósito comercial.
      </li>
      <li>
        GARANTÍA. El uso de nuestro programador, o cualquier decisión que se tome basada en la confianza en este, para
        cualquiera de los puntos mencionados más arriba corre bajo su propio riesgo. No ofrecemos ninguna garantía de
        que el programador satisfaga sus necesidades o que el funcionamiento de esta herramienta sea ininterrumpido y
        continuo ni que esté libre de errores, virus, defectos y problemas técnicos.
      </li>
      <li>
        DAÑOS CONSECUENTES; LIMITACIÓN DE RESPONSABILIDAD. BAJO NINGUNA CIRCUNSTANCIA ASUMIMOS RESPONSABILIDAD ALGUNA
        POR NINGÚN DAÑO DIRECTO, INDIRECTO, INCIDENTAL, ESPECIAL O CONSECUENTE QUE SURJAN DE CUALQUIER ASPECTO
        RELACIONADO CON EL USO QUE REALICE DEL SITIO WEB O LOS SERVICIOS, INCLUIDOS, ENTRE OTROS, DAÑOS RELACIONADOS CON
        SU FALTA DE CAPACIDAD PARA USAR EL SITIO WEB O EL SERVICIO; PÉRDIDA DE GANANCIAS, DE DATOS O DE BIENES
        INMATERIALES; CUALQUIER INTERRUPCIÓN, MODIFICACIÓN O CANCELACIÓN DEL SITIO WEB O DE CUALQUIER SERVICIO O PARTE
        DE ESTOS; ASÍ COMO CUALQUIER OTRA PÉRDIDA INTANGIBLE (INCLUSO CUANDO HAYAMOS SIDO ADVERTIDOS DE LA POSIBILIDAD
        DE TALES DAÑOS). Nuestra obligación de garantía no superará en ningún caso el importe total de $500 para las
        reclamaciones de cualquier tipo. En algunas jurisdicciones no se permite la limitación de responsabilidad, por
        ello es posible que estas limitaciones no se apliquen a su caso.
      </li>
      <li>
        EXENCIÓN DE RECLAMACIONES. En la medida máxima permitida por la ley aplicable, por el presente exime y renuncia
        a todas las reclamaciones contra nosotros y nuestros funcionarios, directores, empleados, contratistas
        independientes, representantes, empresas controladas y agentes. Además, nos exime de toda responsabilidad por
        cualquier reclamación, daño —ya sea real o consecuente—, costo y gasto —incluidos los costos de litigios y los
        honorarios de abogados— de todo tipo y naturaleza que surjan de o, de cualquier manera, estén relacionados con
        nuestros Servicios. Si corresponde, renuncia a sus derechos en virtud de la sección 1542 del Código Civil de
        California, en la que se establece: «una exención general no se extiende a reclamaciones que el acreedor o la
        parte exonerante no conoce o sospecha que existan a su favor al momento de hacer la exención y que, de haberlas
        conocido, habrían afectado materialmente su acuerdo con el deudor». usted comprende que cualquier hecho
        relacionado con cualquier asunto cubierto por esta exención se puede considerar de forma distinta de lo que
        ahora se considere cierto y acepta y asume el riesgo de tales posibles diferencias de hecho. Además, renuncia y
        cede expresamente a todos y cada uno de los derechos que pueda haber tenido en virtud de cualquier otro estatuto
        estatal o federal o principio de derecho consuetudinario de efecto similar, en la máxima medida permitida por la
        ley.
      </li>
      <li>
        INDEMNIZACIÓN. Acepta eximirnos de toda responsabilidad a nosotros y a nuestros funcionarios, directores,
        subsidiarias, empresas controladas, sucesores, cesionarios, agentes, proveedores de servicios, proveedores y
        empleados por cualquier reclamación o demanda de terceros que surja del uso del programador por su parte;
        (c) por cualquier infracción que cometa de las Condiciones de uso; (d) por cualquier falta cumplimiento que
        cometa de cualquiera de las declaraciones y garantías que se incluyen en este documento; (e) por cualquier
        infracción que cometa de cualquier derecho de terceros, incluido, entre otros, cualquier responsabilidad o gasto
        que surja de todas y cada una de las reclamaciones, demandas, pérdidas, daños, costos, gastos, procesos
        judiciales, sentencias, costos de litigios o honorarios razonables de abogados y costas judiciales; o (f) por
        cualquier infracción que cometa de cualquier ley, reglamento o pauta impuesta por cualquier organismo
        administrativo.
      </li>
      <li>
        LEGISLACIÓN APLICABLE; RESOLUCIÓN DE CONTROVERSIAS. Estos Términos de servicio se regirán e interpretarán de
        acuerdo con las leyes del Estado de Nueva York, sin tener en cuenta los conflictos de principios legales. Todas
        las controversias de la Empresa que surjan en virtud de este Acuerdo se someterán a un procedimiento de
        arbitraje vinculante, de acuerdo con las normas comerciales de la Asociación Americana de Arbitraje de
        Nueva York. Este arbitraje estará a cargo de un árbitro que se haya elegido de común acuerdo entre usted y
        nosotros, al amparo de las normas antes mencionadas. Los costos del arbitraje, incluidos los costos
        administrativos y los honorarios de los árbitros, se compartirán por igual entre usted y nosotros. El laudo
        arbitral será definitivo y cada uno de nosotros cumplirá de buena fe con la presentación de este en cualquier
        tribunal que tenga jurisdicción. En el caso de que se solicite una ejecución o revisión judicial, la parte
        ganadora tendrá derecho a reclamar las costas y los honorarios razonables de los abogados. Todas las
        reclamaciones que presente contra nosotros o los Servicios se deben resolver de acuerdo con lo establecido en
        esta sección. Cualquier reclamación que se presente contra nosotros y que contravenga los términos de esta
        sección se considerará presentada incorrectamente. En el caso de que presente una reclamación que contravenga lo
        establecido en esta sección, acepta que podemos reclamar las costas y los honorarios de los abogados en concepto
        de presentación incorrecta, siempre que le hayamos notificado por escrito el problema y que no haya retirado tal
        reclamación de forma debida.
      </li>
    </ol>
  </body>
</html>

    `,
  };
}
