import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { LocationIntegrationLevelBaseComponent } from '../location-integration-level-base/location-integration-level-base.component';

@Component({
  selector: 'app-location-integration-level-one',
  standalone: true,
  imports: [CommonModule, TranslateModule, LocationIntegrationLevelBaseComponent],
  templateUrl: './location-integration-level-one.component.html',
  styleUrls: ['./location-integration-level-one.component.scss'],
})
export class LocationIntegrationLevelOneComponent extends LocationIntegrationLevelBaseComponent {}
