import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TradeDeskPixelService {
  private renderer: Renderer2;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  trackEvent(eventId: string) {
    if (!environment.tradeDeskPixelIsSupported) {
      // Only track for the environments that need to support tracking
      return;
    }

    if (!eventId) {
      console.warn('Trade Desk Event ID is missing.');
      return;
    }

    try {
      // https://partner.thetradedesk.com/v3/portal/data/doc/TrackingTagsStatic
      const nocache = new Date().getTime();
      const pixelUrl = `${environment.tradeDeskStaticPixelUrl}/?adv=bfo0cyt&ct=0:${eventId}&fmt=3&nocache=${nocache}`;

      const img = this.renderer.createElement('img');
      img.src = pixelUrl;
      img.height = 1;
      img.width = 1;
      img.style.borderStyle = 'none';
      img.alt = '';

      this.renderer.appendChild(document.body, img);

      // Cleanup: Remove the pixel after a short delay (prevents memory leaks)
      setTimeout(() => img.remove(), 500);
    } catch (error) {
      console.error('Error while sending Static Pixel event:', error);
    }
  }
}
