import { inject } from '@angular/core';
import { Route } from '@angular/router';

import { AppointmentConfirmedComponent } from './appointment/appointment-confirmed/appointment-confirmed.component';
import { AppointmentReservationErrorComponent } from './appointment/appointment-reservation-error/appointment-reservation-error.component';
import { AppointmentReviewComponent } from './appointment/appointment-review/appointment-review.component';
import { AppointmentSearchComponent } from './appointment/appointment-search/appointment-search.component';
import { EnvironmentGuard } from './common/guards/environment.guard';
import { LocationDetailComponent } from './location-detail/location-detail.component';
import { ConfigErrorComponent } from './config-error/config-error.component';
import { VaccineSelectionComponent } from './vaccine-selection/vaccine-selection.component';
import { CoadminSelectionComponent } from './coadmin-selection/coadmin-selection.component';

export const DEFAULT_START_ROUTE = '0';

export const ROUTES: Route[] = [
  {
    path: ':campaignId',
    title: 'EasyVax Scheduler | Vaccines',
    canActivate: [() => inject(EnvironmentGuard).canActivateServices()],
    data: {
      languagePickerButtonEnabled: true,
    },
    loadComponent: () =>
      import('./service-selection/service-selection.component').then(
        (m) => m.ServiceSelectionComponent
      ),
  },
  {
    path: ':campaignId/coadmin',
    title: 'EasyVax Scheduler | Additional Vaccines',
    canActivate: [() => inject(EnvironmentGuard).canActivateCoadmin()],
    data: {
      languagePickerButtonEnabled: true,
    },
    component: CoadminSelectionComponent,
  },
  {
    path: ':campaignId/vaccines',
    title: 'EasyVax Scheduler | Vaccine Brands',
    canActivate: [() => inject(EnvironmentGuard).canActivateVaccines()],
    data: {
      languagePickerButtonEnabled: true,
    },
    component: VaccineSelectionComponent,
  },
  {
    path: ':campaignId/appointments',
    title: 'EasyVax Scheduler | Pharmacies',
    canActivate: [
      () => inject(EnvironmentGuard).canActivateAppointments(),
      () => inject(EnvironmentGuard).canActivateReservationFlow(),
    ],
    data: {
      languagePickerButtonEnabled: true,
    },
    component: AppointmentSearchComponent,
    runGuardsAndResolvers: 'always',
  },
  {
    path: ':campaignId/appointments/:locationId',
    title: 'EasyVax Scheduler | Pharmacy Detail',
    canActivate: [
      () => inject(EnvironmentGuard).canActivateAppointments(),
      () => inject(EnvironmentGuard).canActivateReservationFlow(),
    ],
    data: {
      languagePickerButtonEnabled: true,
    },
    component: LocationDetailComponent,
  },
  {
    path: ':campaignId/reservation',
    title: 'EasyVax Scheduler | Required Information',
    canActivate: [
      () => inject(EnvironmentGuard).canActivateReservation(),
      () => inject(EnvironmentGuard).canActivateReservationFlow(),
    ],
    data: {
      languagePickerButtonEnabled: false,
    },
    loadComponent: () =>
      import('./appointment/appointment-reservation/appointment-reservation.component').then(
        (m) => m.AppointmentReservationComponent
      ),
  },
  {
    path: ':campaignId/review',
    title: 'EasyVax Scheduler | Review',
    canActivate: [
      () => inject(EnvironmentGuard).canActivateReviewAndConfirmed(),
      () => inject(EnvironmentGuard).canActivateReservationFlow(),
    ],
    data: {
      languagePickerButtonEnabled: false,
    },
    component: AppointmentReviewComponent,
  },
  {
    path: ':campaignId/reservation-error',
    title: 'EasyVax Scheduler | Reserve Appointment Error',
    data: {
      languagePickerButtonEnabled: true,
    },
    component: AppointmentReservationErrorComponent,
  },
  {
    path: ':campaignId/confirmation',
    title: 'EasyVax Scheduler | Confirmation',
    canActivate: [() => inject(EnvironmentGuard).canActivateReviewAndConfirmed()],
    data: {
      languagePickerButtonEnabled: false,
    },
    component: AppointmentConfirmedComponent,
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: DEFAULT_START_ROUTE,
  },
  {
    path: ':campaignId/config-error',
    title: 'EasyVax Scheduler | Error',
    data: {
      languagePickerButtonEnabled: true,
    },
    component: ConfigErrorComponent,
  },
  {
    path: '**',
    title: 'EasyVax Scheduler | Error',
    data: {
      languagePickerButtonEnabled: true,
    },
    component: ConfigErrorComponent,
  },
];
