import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentDateTime } from 'src/app/common/model/appointment';
import { DateOption } from 'src/app/common/model/picker';
import { DATE_TIME_PICKER_TIME_WIDTH } from 'src/app/common/constants/general';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-date-picker',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit, OnChanges {
  @ViewChild('datesContainer', { static: true }) datesContainerRef!: ElementRef;
  @Input() title = '';
  @Input() dates: DateOption[] = [];
  @Input() appointments: AppointmentDateTime[] = [];
  @Output() selectedDate = new EventEmitter<string>();

  filteredDates: DateOption[] = [];
  selectedStartDate = '';
  showAllDates = false;
  numDatesPerRow = 0;
  numDateRows = 0;
  readonly numMinDateRows = 2;

  ngOnInit() {
    this.selectFirstDateSlot();
    this.calculateVisibleDates();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['dates']) {
      this.filterDates();
    }
  }

  // Listen for resize changes so we can calculate the availble date slots
  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    this.calculateVisibleDates();
  }

  calculateVisibleDates() {
    const datesContainer = this.datesContainerRef.nativeElement;
    const datesItemWidth = DATE_TIME_PICKER_TIME_WIDTH;
    const datesComputedStyle = datesContainer ? window.getComputedStyle(datesContainer) : null;
    const gap = datesComputedStyle
      ? datesComputedStyle.getPropertyValue('gap').split(' ')[0]
      : '0px';

    /**
     * Calculate how many dates can fit on one row
     * Here we need to take the container width and add the gap to that prior to dividing
     * because the end date doesn't have gap applied and we need to accommodate that
     * otherwise it's off by one
     */
    this.numDatesPerRow = Math.floor(
      (datesContainer.offsetWidth + Number(gap.slice(0, gap.length - 2))) /
        (datesItemWidth + Number(gap.slice(0, gap.length - 2)))
    );

    const previousNumDateRows = this.numDateRows; // track previous so we can determine if we need to reset the More button
    this.numDateRows = Math.ceil(this.dates.length / this.numDatesPerRow);
    // if the new number of rows is greater than previous we reset so the the button displays again
    if (previousNumDateRows < this.numDateRows) {
      this.showAllDates = false;
    }

    this.filterDates();
  }

  filterDates() {
    // show all dates if number of rows is less than or equal to our limit
    if (this.numDateRows <= this.numMinDateRows) {
      this.showAllDates = true;
    }

    const { dates } = this;
    if (this.showAllDates) {
      this.filteredDates = dates;
    } else {
      // here we multiply numDatesPerRow by numMinDateRows to accomodate our maximum number of rows
      // to display in the truncated state, we subtract one to accomodate the more button display
      this.filteredDates = dates.slice(0, this.numDatesPerRow * this.numMinDateRows - 1);
    }
  }

  selectFirstDateSlot() {
    const { dates } = this;
    if (dates.length > 0) {
      this.selectDate(dates[0].date);
    }
  }

  selectDate(date: string) {
    this.selectedStartDate = date;
    this.selectedDate.emit(date);
  }

  toggleDateDisplay() {
    this.showAllDates = !this.showAllDates;
    this.filterDates();
  }
}
