import { DateTime } from 'luxon';
import { VaccineEligibilityQuestionType } from 'src/app/common/constants/general';
import { VaccineEligibilityQuizSection } from 'src/app/common/model/vaccine-eligibility';

const localDate = DateTime.local();
const currentYear = localDate.year.toString();
const lastYear = localDate.minus({ years: 1 }).year.toString();
const nextYear = localDate.plus({ years: 1 }).year.toString();
const comparedDate = DateTime.fromISO(`${currentYear}-09-21`);
const fallYear = localDate >= comparedDate ? currentYear : lastYear;
const springYear = localDate >= comparedDate ? nextYear : currentYear;
const tdapYear = localDate.minus({ years: 10 }).year.toString();

export const OPTION_NONE_VALUE = 'NONE';

export const AGE_SECTION_API_KEY = 'age';
export enum AGE_SECTION_API_VALUES {
  AGE_0_17 = 'AGE_0_17',
  AGE_18_26 = 'AGE_18_26',
  AGE_27_49 = 'AGE_27_49',
  AGE_50_59 = 'AGE_50_59',
  AGE_60_64 = 'AGE_60_64',
  AGE_65_PLUS = 'AGE_65_PLUS',
}

export const PREGNANT_SECTION_API_KEY = 'pregnant';
export enum PREGNANT_SECTION_API_VALUES {
  PREGNANT_NO = 'PREGNANT_NO',
  PREGNANT_YES = 'PREGNANT_YES',
}

export const PREVIOUS_VACCINATIONS_API_KEY = 'previousVaccinations';
export enum PREVIOUS_VACCINATIONS_API_VALUES {
  CHICKENPOX = 'CHICKENPOX',
  COVID = 'COVID',
  FLU = 'FLU',
  HEPATITIS_A = 'HEPATITIS_A',
  HEPATITIS_B = 'HEPATITIS_B',
  HPV = 'HPV',
  MMR = 'MMR',
  PNEUMONIA = 'PNEUMONIA',
  RSV = 'RSV',
  SHINGLES = 'SHINGLES',
  TDAP = 'TDAP',
  NONE = 'NONE',
}

export const SECTIONS: VaccineEligibilityQuizSection[] = [
  {
    isOpen: true,
    isVisible: true,
    question: {
      id: 1,
      type: VaccineEligibilityQuestionType.SINGLE,
      apiKey: AGE_SECTION_API_KEY,
      textTC: 'VACCINE_ELIGIBILITY.QUESTIONS.AGE.TITLE',
      whyDisclaimerTitleTC: 'VACCINE_ELIGIBILITY.QUESTIONS.AGE.WHY_DISCLAIMER_TITLE',
      whyDisclaimerTC: 'VACCINE_ELIGIBILITY.QUESTIONS.AGE.WHY_DISCLAIMER',
      options: [
        {
          displayTC: 'VACCINE_ELIGIBILITY.QUESTIONS.AGE.OPTION_1',
          value: AGE_SECTION_API_VALUES.AGE_0_17,
          errorTC: 'VACCINE_ELIGIBILITY.QUESTIONS.AGE.ADULT_DISCLAIMER',
        },
        {
          displayTC: 'VACCINE_ELIGIBILITY.QUESTIONS.AGE.OPTION_2',
          value: AGE_SECTION_API_VALUES.AGE_18_26,
        },
        {
          displayTC: 'VACCINE_ELIGIBILITY.QUESTIONS.AGE.OPTION_3',
          value: AGE_SECTION_API_VALUES.AGE_27_49,
        },
        {
          displayTC: 'VACCINE_ELIGIBILITY.QUESTIONS.AGE.OPTION_4',
          value: AGE_SECTION_API_VALUES.AGE_50_59,
        },
        {
          displayTC: 'VACCINE_ELIGIBILITY.QUESTIONS.AGE.OPTION_5',
          value: AGE_SECTION_API_VALUES.AGE_60_64,
        },
        {
          displayTC: 'VACCINE_ELIGIBILITY.QUESTIONS.AGE.OPTION_6',
          value: AGE_SECTION_API_VALUES.AGE_65_PLUS,
        },
      ],
      answer: null as string | null,
    },
  },
  {
    isOpen: false,
    isVisible: false,
    question: {
      id: 2,
      type: VaccineEligibilityQuestionType.SINGLE,
      apiKey: PREGNANT_SECTION_API_KEY,
      textTC: 'VACCINE_ELIGIBILITY.QUESTIONS.PREGNANT.TITLE',
      whyDisclaimerTitleTC: 'VACCINE_ELIGIBILITY.QUESTIONS.PREGNANT.WHY_DISCLAIMER_TITLE',
      whyDisclaimerTC: 'VACCINE_ELIGIBILITY.QUESTIONS.PREGNANT.WHY_DISCLAIMER',
      options: [
        {
          displayTC: 'VACCINE_ELIGIBILITY.QUESTIONS.PREGNANT.OPTION_1',
          value: PREGNANT_SECTION_API_VALUES.PREGNANT_NO,
        },
        {
          displayTC: 'VACCINE_ELIGIBILITY.QUESTIONS.PREGNANT.OPTION_2',
          value: PREGNANT_SECTION_API_VALUES.PREGNANT_YES,
        },
      ],
      answer: null as string | null,
    },
  },
  {
    isOpen: false,
    isVisible: false,
    question: {
      id: 3,
      type: VaccineEligibilityQuestionType.MULTIPLE,
      apiKey: PREVIOUS_VACCINATIONS_API_KEY,
      textTC: 'VACCINE_ELIGIBILITY.QUESTIONS.PREVIOUS_VACCINATIONS.TITLE',
      disclaimerTC: 'VACCINE_ELIGIBILITY.QUESTIONS.PREVIOUS_VACCINATIONS.DISCLAIMER',
      whyDisclaimerTitleTC:
        'VACCINE_ELIGIBILITY.QUESTIONS.PREVIOUS_VACCINATIONS.WHY_DISCLAIMER_TITLE',
      whyDisclaimerTC: 'VACCINE_ELIGIBILITY.QUESTIONS.PREVIOUS_VACCINATIONS.WHY_DISCLAIMER',
      options: [
        {
          displayTC: 'VACCINE_ELIGIBILITY.QUESTIONS.PREVIOUS_VACCINATIONS.PNEUMONIA',
          value: PREVIOUS_VACCINATIONS_API_VALUES.PNEUMONIA,
        },
        {
          displayTC: 'VACCINE_ELIGIBILITY.QUESTIONS.PREVIOUS_VACCINATIONS.SHINGLES',
          value: PREVIOUS_VACCINATIONS_API_VALUES.SHINGLES,
        },
        {
          displayTC: 'VACCINE_ELIGIBILITY.QUESTIONS.PREVIOUS_VACCINATIONS.TDAP',
          value: PREVIOUS_VACCINATIONS_API_VALUES.TDAP,
          descriptionTC: 'VACCINE_ELIGIBILITY.QUESTIONS.PREVIOUS_VACCINATIONS.TDAP_DESCRIPTION',
          injectedValues: { year: tdapYear },
        },
        {
          displayTC: 'VACCINE_ELIGIBILITY.QUESTIONS.PREVIOUS_VACCINATIONS.COVID',
          value: PREVIOUS_VACCINATIONS_API_VALUES.COVID,
          descriptionTC: 'VACCINE_ELIGIBILITY.QUESTIONS.PREVIOUS_VACCINATIONS.COVID_DESCRIPTION',
          injectedValues: { year: currentYear },
        },
        {
          displayTC: 'VACCINE_ELIGIBILITY.QUESTIONS.PREVIOUS_VACCINATIONS.FLU',
          value: PREVIOUS_VACCINATIONS_API_VALUES.FLU,
          descriptionTC: 'VACCINE_ELIGIBILITY.QUESTIONS.PREVIOUS_VACCINATIONS.FLU_DESCRIPTION',
          injectedValues: { fallYear, springYear },
        },
        {
          displayTC: 'VACCINE_ELIGIBILITY.QUESTIONS.PREVIOUS_VACCINATIONS.RSV',
          value: PREVIOUS_VACCINATIONS_API_VALUES.RSV,
        },
        {
          displayTC: 'VACCINE_ELIGIBILITY.QUESTIONS.PREVIOUS_VACCINATIONS.CHICKENPOX',
          value: PREVIOUS_VACCINATIONS_API_VALUES.CHICKENPOX,
        },
        {
          displayTC: 'VACCINE_ELIGIBILITY.QUESTIONS.PREVIOUS_VACCINATIONS.HEPATITIS_A',
          value: PREVIOUS_VACCINATIONS_API_VALUES.HEPATITIS_A,
        },
        {
          displayTC: 'VACCINE_ELIGIBILITY.QUESTIONS.PREVIOUS_VACCINATIONS.HEPATITIS_B',
          value: PREVIOUS_VACCINATIONS_API_VALUES.HEPATITIS_B,
        },
        {
          displayTC: 'VACCINE_ELIGIBILITY.QUESTIONS.PREVIOUS_VACCINATIONS.HPV',
          value: PREVIOUS_VACCINATIONS_API_VALUES.HPV,
        },
        {
          displayTC: 'VACCINE_ELIGIBILITY.QUESTIONS.PREVIOUS_VACCINATIONS.MMR',
          value: PREVIOUS_VACCINATIONS_API_VALUES.MMR,
        },
        {
          displayTC: 'VACCINE_ELIGIBILITY.QUESTIONS.PREVIOUS_VACCINATIONS.NONE',
          value: PREVIOUS_VACCINATIONS_API_VALUES.NONE,
          stickToBottom: true,
        },
      ],
      optionsSorted: false,
      answer: [] as string[], // Multiple choice answer (array)
    },
  },
];
