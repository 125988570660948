import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnvironmentService } from '../environment/environment.service';
import {
  VaccineEligibilityRequest,
  VaccineEligibilityResponse,
} from 'src/app/common/model/vaccine-eligibility';
import { getEmbedded, VaccineEligibilityStates } from 'src/app/common/constants/general';

@Injectable({
  providedIn: 'root',
})
export class VaccineEligibilityService {
  private isEligibilityToastOpenObj = new BehaviorSubject<boolean>(false);
  private isEligibilityQuizOpenObj = new BehaviorSubject<boolean>(false);
  private vaccineEligibilityStateObj = new BehaviorSubject<VaccineEligibilityStates>(
    VaccineEligibilityStates.QUIZ
  );
  suppressVaccineEligibility = getEmbedded(); // Whether or not vaccine eligibility is supported
  vaccineEligibilityResponse: VaccineEligibilityResponse | null = null;

  constructor(private http: HttpClient, private environmentService: EnvironmentService) {}

  set isEligibilityToastOpen(isOpen: boolean) {
    this.isEligibilityToastOpenObj.next(isOpen);
  }

  get isEligibilityToastOpen() {
    return this.isEligibilityToastOpenObj.getValue();
  }

  set isEligibilityQuizOpen(isOpen: boolean) {
    if (isOpen) {
      this.markElementsAsInert(true);
    } else {
      this.markElementsAsInert(false);
    }

    this.isEligibilityQuizOpenObj.next(isOpen);
  }

  get isEligibilityQuizOpen(): boolean {
    return this.isEligibilityQuizOpenObj.getValue();
  }

  set vaccineEligibilityState(state: VaccineEligibilityStates) {
    this.vaccineEligibilityStateObj.next(state);
  }

  get vaccineEligibilityState(): VaccineEligibilityStates {
    return this.vaccineEligibilityStateObj.getValue();
  }

  getVaccineEligibilityState(): Observable<VaccineEligibilityStates> {
    return this.vaccineEligibilityStateObj.asObservable();
  }

  getIsEligibilityQuizOpen(): Observable<boolean> {
    return this.isEligibilityQuizOpenObj.asObservable();
  }

  restartFlow() {
    this.vaccineEligibilityState = VaccineEligibilityStates.QUIZ;
    this.vaccineEligibilityResponse = null;
    this.isEligibilityToastOpen = false;
  }

  setEligibilityResponse(resp: VaccineEligibilityResponse | null) {
    this.vaccineEligibilityResponse = resp;

    if (resp && resp.recommendations && resp.recommendations.length > 0) {
      this.isEligibilityToastOpen = true;
    } else {
      this.isEligibilityToastOpen = false;
    }
  }

  toggleElibilityQuizIsOpen() {
    this.isEligibilityQuizOpen = !this.isEligibilityQuizOpen;
  }

  markElementsAsInert(inert: boolean) {
    const elementsToInert = document.querySelectorAll('[appInert]');

    elementsToInert.forEach((element) => {
      element.setAttribute('appInert', inert.toString());
    });
  }

  checkVaccineEligibility(payload: VaccineEligibilityRequest) {
    const { apiBaseUrl } = this.environmentService;
    const url = `${apiBaseUrl}/eligibility`;
    return this.http.post<any>(url, payload);
  }
}
