<div class="d-flex flex-column">
  <h2 class="text-primary pb-3">{{ title }}</h2>

  <!-- Allows user to set the startDate for location search -->
  <div #datesContainer id="dates-container" class="d-flex flex-wrap gap-2">
    <ng-container *ngFor="let date of filteredDates">
      <button
        tabindex="0"
        [attr.aria-label]="date.dayLabel + ' ' + date.dateLabel"
        (click)="selectDate(date.date)"
        [class]="date.date === selectedStartDate ? 'active' : ''"
        class="btn-outline-primary btn d-flex flex-column flex-fill align-items-center text-uppercase gap-0"
      >
        {{ date.dayLabel }}
        <span class="text-tertiary" aria-hidden="true">
          {{ date.dateLabel }}
        </span>
      </button>
    </ng-container>

    <button
      (click)="toggleDateDisplay()"
      *ngIf="numDateRows > numMinDateRows && filteredDates.length !== dates.length"
      class="btn btn-outline-primary d-flex flex-column flex-fill justify-content-center text-uppercase align-items-center"
    >
      <span
        class="day-label"
        [innerText]="
          filteredDates.length === dates.length
            ? ('APPOINTMENT.SEARCH.DATE_PICKER_COLLAPSE_BUTTON' | translate)
            : ('APPOINTMENT.SEARCH.DATE_PICKER_EXPAND_BUTTON' | translate)
        "
      >
      </span>
    </button>
  </div>
</div>
