import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * Skeleton loading of the LocationComponent design
 */
@Component({
  selector: 'app-loading-location',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loading-location.component.html',
  styleUrls: ['./loading-location.component.scss'],
})
export class LoadingLocationComponent {}
