<div class="footer d-flex flex-row gap-2 justify-content-start">
  <button
    *ngIf="leftButtonName"
    type="button"
    id="left-button"
    class="btn btn-outline-primary text-uppercase fit-content h-100 py-0"
    (click)="leftButtonClick()"
    [disabled]="leftButtonDisabled"
    [attr.aria-label]="leftButtonName"
  >
    <app-loading *ngIf="leftButtonLoading"></app-loading>
    <span *ngIf="!leftButtonLoading">
      {{ leftButtonName }}
    </span>
  </button>

  <button
    #rightButton
    *ngIf="rightButtonName"
    type="button"
    id="right-button"
    [class]="rightButtonClass"
    class="btn text-uppercase w-100 h-100 py-0"
    (click)="rightButtonClick()"
    [disabled]="rightButtonDisabled"
    [attr.aria-label]="rightButtonName"
  >
    <app-loading *ngIf="rightButtonLoading"></app-loading>
    <span *ngIf="!rightButtonLoading">
      {{ rightButtonName }}
    </span>
  </button>
</div>
