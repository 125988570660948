import { Appointment } from './appointment';

export interface Location {
  locationId: string;
  locationGroupId: string;
  locationName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  lat: number;
  lng: number;
  timeZone?: string;
  distance: number;
  supportsApiScheduling: boolean;
  informationalUrl: string;
  bookingUrl?: string;
  logoPath?: string;
  appointments: Array<Appointment>;
}

export enum LocationIntegrationLevel {
  Level1 = 1,
  Level2 = 2,
  Level2Half = 25,
  Level3 = 3,
  Level4 = 4,
}

export class Location implements Location {
  locationId: string;
  locationGroupId: string;
  locationName: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  phone: string;
  lat: number;
  lng: number;
  timeZone?: string;
  distance: number;
  supportsApiScheduling: boolean;
  informationalUrl: string;
  bookingUrl?: string;
  logoPath?: string;
  appointments: Array<Appointment>;

  constructor(location: Location) {
    this.locationId = location.locationId;
    this.locationGroupId = location.locationGroupId;
    this.locationName = location.locationName;
    this.address = location.address;
    this.city = location.city;
    this.state = location.state;
    this.zip = location.zip;
    this.phone = location.phone;
    this.lat = location.lat;
    this.lng = location.lng;
    this.timeZone = location.timeZone;
    this.distance = location.distance;
    this.supportsApiScheduling = location.supportsApiScheduling;
    this.informationalUrl = location.informationalUrl;
    this.bookingUrl = location.bookingUrl;
    this.logoPath = location.logoPath;
    this.appointments = location.appointments;
  }

  getIntegrationLevel(): LocationIntegrationLevel {
    if (this.supportsApiScheduling) {
      // Supports backbone scheduling so location is level 4
      return LocationIntegrationLevel.Level4;
    } else if (
      !this.supportsApiScheduling &&
      this.appointments.find((appointment) => appointment.times.find((time) => time.bookingUrl))
    ) {
      // Doesn't support backbone scheduling
      // Appointment times have a bookingUrl
      return LocationIntegrationLevel.Level3;
    } else if (
      !this.supportsApiScheduling &&
      this.bookingUrl &&
      !this.appointments.find((appointment) => appointment.times.find((time) => time.bookingUrl)) &&
      this.appointments.length > 0
    ) {
      // Doesn't support backbone scheduling
      // Location has a bookingUrl
      // Location has appointments
      // Appointment times do not have a bookingUrl
      return LocationIntegrationLevel.Level2Half;
    } else if (
      !this.supportsApiScheduling &&
      this.bookingUrl &&
      !this.appointments.find((appointment) => appointment.times.find((time) => time.bookingUrl))
    ) {
      // Doesn't support backbone scheduling
      // Location has a bookingUrl
      // Appointment times do not have a bookingUrl
      return LocationIntegrationLevel.Level2;
    } else {
      return LocationIntegrationLevel.Level1;
    }
  }
}
