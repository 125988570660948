import { TranslateLoader } from '@ngx-translate/core';
import { Observable, retry } from 'rxjs';
import { TranslationService } from './translation.service';
import { API_RETRY_LIMIT } from '../../constants/general';

export class VaxTranslationLoader implements TranslateLoader {
  constructor(private translationService: TranslationService) {}

  getTranslation(lang: string): Observable<any> {
    return this.translationService.loadTranslations(lang).pipe(retry(API_RETRY_LIMIT));
  }
}
