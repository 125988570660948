import { animate, animation, keyframes, style } from '@angular/animations';

export const FADE_OUT_ANIMATION = animation([
  style({
    transition: 'opacity ease-in-out',
    opacity: 1,
  }),
  animate(
    '100ms',
    style({
      transition: 'opacity ease-in-out',
      opacity: 0,
    })
  ),
]);

export const FADE_IN_ANIMATION = animation([
  style({
    transition: 'opacity ease-in-out',
    opacity: 0,
  }),
  animate('200ms', keyframes([style({ opacity: 0 }), style({ opacity: 1 })])),
]);
