import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, retry } from 'rxjs';
import { Configuration } from '../../model/configuration';
import { EnvironmentService } from '../environment/environment.service';
import { API_RETRY_LIMIT } from '../../constants/general';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService {
  constructor(private http: HttpClient, private environmentService: EnvironmentService) {}

  /**
   * Gets the configuration information for a campaign
   *
   * Documentation: https://gskfindvax.docs.apiary.io/#reference/0/configuration/get-configuration
   * @returns Observable of Configuration
   */
  getConfiguration(): Observable<Configuration> {
    const { apiBaseUrl, campaignId, locale, isLocaleOverride } = this.environmentService;
    let url = `${apiBaseUrl}/configuration/${campaignId}`;
    if (locale) {
      url += `?locale=${locale}`;
    }
    url += `&override=${isLocaleOverride}`;
    return this.http.get<Configuration>(url).pipe(retry(API_RETRY_LIMIT));
  }
}
