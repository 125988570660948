import { DateTime } from 'luxon';

export class DateUtil {
  static minDatePickerDate = DateTime.fromObject({
    year: 1900,
    month: 1,
    day: 1,
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  static currentDate = DateTime.local();
  static currentDateUTC = DateTime.local().toUTC();
  static currentDateYearMonthDay = DateTime.local().toFormat('yyyy-MM-dd');
  static tomorrowDateYearMonthDay = DateTime.local().plus({ days: 1 }).toFormat('yyyy-MM-dd');
  static localTimeZoneAbbr = DateTime.local().toFormat('ZZZZ');

  static formatTime = (time: string): string => {
    return DateTime.fromISO(time).toFormat('t');
  };

  static formatDate = (date: string): string => {
    const today = DateUtil.currentDateYearMonthDay;
    const parsedDate = DateTime.fromISO(date);

    if (date === today) {
      return 'APPOINTMENT.SEARCH.TODAY'; // return localization string for translation support
    } else {
      let formattedDate = parsedDate.toLocaleString({
        weekday: 'short',
        month: '2-digit',
        day: '2-digit',
      });

      // Remove the comma from the formatted date string
      formattedDate = formattedDate.replace(',', '');

      return formattedDate;
    }
  };

  static formatMonthDayYearDateToJsDate = (date: string): Date => {
    const parsedDate = DateTime.fromFormat(date, 'yyyy-MM-dd');
    return parsedDate.toJSDate();
  };

  static formatYearMonthDayToMonthDayYear = (date: string): string => {
    return DateTime.fromFormat(date, 'yyyy-MM-dd').toFormat('MM/dd/yyyy');
  };

  static formatDateLong = (date: string): string => {
    const today = DateUtil.currentDateYearMonthDay;
    const tomorrow = DateUtil.tomorrowDateYearMonthDay;
    const parsedDate = DateTime.fromISO(date);

    if (date === today) {
      return 'APPOINTMENT.SEARCH.TODAY'; // return localization string for translation support
    } else if (date === tomorrow) {
      return 'APPOINTMENT.SEARCH.TOMORROW'; // return localization string for translation support
    } else {
      let formattedDate = parsedDate.toLocaleString({
        weekday: 'long',
        month: '2-digit',
        day: '2-digit',
      });

      // Remove the comma from the formatted date string
      formattedDate = formattedDate.replace(',', '');

      return formattedDate;
    }
  };

  static formatDayOfWeek = (date: string): string => {
    const parsedDate = DateTime.fromISO(date);
    return parsedDate.toLocaleString({ weekday: 'long' });
  };

  static formatDateFromIso = (date: string): DateTime => {
    return DateTime.fromISO(date);
  };

  static formatDateFromIsoAddingHours = (date: string, hoursToAdd: number): DateTime => {
    return DateTime.fromISO(date).plus({ hour: hoursToAdd });
  };

  static formatDateFromIsoAddingMinutes = (date: string, minutesToAdd: number): DateTime => {
    return DateTime.fromISO(date).plus({ minutes: minutesToAdd });
  };

  static formatDateIsoToYearMonthDay = (date: string): string => {
    return DateTime.fromISO(date).toFormat('yyyy-MM-dd');
  };

  static formatDateFromJsToMonthDayYear = (date: any): string => {
    return DateTime.fromJSDate(date).toFormat('MM/dd/yyyy');
  };

  static formatDateStartOfDay = (date: string): string => {
    return DateTime.fromFormat(date, 'yyyy-MM-dd').startOf('day').toUTC().toISO() as string;
  };

  static formatDateEndOfDay = (date: string): string => {
    return DateTime.fromFormat(date, 'yyyy-MM-dd').endOf('day').toUTC().toISO() as string;
    // .plus({ days: 1 }) if we want this functionality back add it after endOf() above
  };

  static dateIsJsDate = (date: any): boolean => {
    // Check if the input is a JavaScript Date object
    if (date instanceof Date) {
      return true;
    }

    // Check if the input is a string and can be parsed by Luxon
    if (typeof date === 'string') {
      return DateTime.fromISO(date).isValid;
    }

    // The input is neither a Date object nor a valid string format
    return false;
  };

  static dateIsToday = (date: string): boolean => {
    const today = DateUtil.currentDateYearMonthDay;

    return date === today;
  };

  static dateIsValidMonthDayYear = (date: string): boolean => {
    if (!date) {
      return false;
    }
    return DateTime.fromFormat(date, 'MM/dd/yyyy', { locale: 'en-US' }).isValid; // Check if input value has a valid format
  };

  static nearestHourTime = (): string => {
    // Get the current time
    const currentTime = DateTime.now();
    // Check if current time is past the halfway point of the hour
    const isPastHalfway = currentTime.minute >= 30;
    // Round the current time to the nearest hour, considering the halfway point
    const nearestHour = isPastHalfway
      ? currentTime.plus({ hours: 1 }).startOf('hour')
      : currentTime.startOf('hour');
    // Format the nearest hour in military time format (HH:mm)
    return nearestHour.toFormat('HH:mm');
  };

  static nextHalfHourTime = (): string => {
    // Get the current time
    const currentTime = DateTime.local();

    // Round up to the next half hour
    const nextHalfHour = currentTime
      .plus({ minutes: 30 - (currentTime.minute % 30) })
      .startOf('minute');

    // Format the next half hour option
    return nextHalfHour.toFormat('HH:mm');
  };
}
