<main class="container p-3">
  <header class="text-center">
    <h1 class="text-primary">{{ "ERROR.TITLE" | translate }}</h1>
    <h2 class="text-tertiary mb-0">{{ "ERROR.SUBTITLE" | translate }}</h2>
  </header>

  <div id="error-body" class="text-center">
    <img
      class="img-fluid"
      [src]="awwShucksImageUrl"
      [alt]="'IMAGE.ROBOT' | translate"
    />

    <p
      class="text-tertiary mb-0"
      [innerText]="'ERROR.DESCRIPTION' | translate"
    ></p>

    <button
      (click)="retry()"
      class="btn btn-primary btn-lg text-uppercase w-100"
      [disabled]="(loading$ | async) === true"
    >
      <app-loading *ngIf="(loading$ | async) === true"></app-loading>
      <span *ngIf="(loading$ | async) === false">
        {{ "ERROR.BUTTON" | translate }}
      </span>
    </button>
  </div>
</main>
