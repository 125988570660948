import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { EnvironmentService } from '../environment/environment.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  constructor(private environmentService: EnvironmentService) {}

  public loadTranslations(lang: string) {
    const { resources } = this.environmentService;
    return of(resources);
  }
}
