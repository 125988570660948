<div class="d-flex flex-column mt-5">
  <h2 class="text-primary pb-3">{{ title }}</h2>

  <!-- Allows user to set the startDate for location search -->
  <div id="times-container" class="d-flex flex-wrap justify-content-start gap-2">
    <ng-container *ngIf="appointments.length > 0">
      <ng-container *ngFor="let appointment of appointments">
        <button
          (click)="selectTime(appointment)"
          [class]="appointment.time === selectedStartTime ? 'active' : ''"
          class="btn-outline-primary btn d-flex flex-column flex-fill align-items-center justify-content-center text-uppercase gap-0"
        >
          {{ appointment.prettyTime }}
        </button>
      </ng-container>
    </ng-container>

    <!-- No appointment times available -->
    <ng-container *ngIf="noAppointments && !loading">
      <p class="text-uppercase text-tertiary text-small mb-0">
        {{ 'APPOINTMENT.LOCATION.DETAIL_NO_RESULTS' | translate }}
      </p>
    </ng-container>
  </div>
</div>
