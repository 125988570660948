import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslationService } from 'src/app/common/services/translation/translation.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-vaccine-eligibility-disclaimer-card',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './vaccine-eligibility-disclaimer-card.component.html',
  styleUrls: ['./vaccine-eligibility-disclaimer-card.component.scss'],
})
export class VaccineEligibilityDisclaimerCardComponent implements OnInit, AfterViewInit {
  @ViewChild('textElement') textElement?: ElementRef;
  @Input() titleTC: string | undefined = '';
  @Input() disclaimerTC: string | undefined = '';

  isExpanded = false; // State variable to toggle the display of full text
  isTextTruncated = false; // State variable as to whether or not text is actually truncated

  safeDisclaimerHtml: SafeHtml = '';

  get hasTranslatedText(): boolean {
    const titleText = this.titleTC ? this.translateService.instant(this.titleTC) : '';
    const disclaimerText = this.disclaimerTC
      ? this.translateService.instant(this.disclaimerTC)
      : '';

    return !!titleText || !!disclaimerText;
  }

  constructor(private translateService: TranslateService, private sanitizer: DomSanitizer) {}

  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    this.checkIfTextIsTruncated();
  }
  toggleView() {
    this.isExpanded = !this.isExpanded; // Toggle the state
    this.checkIfTextIsTruncated(); // Recheck the truncation after expanding
  }

  ngOnInit(): void {
    if (this.disclaimerTC) {
      const translatedHtml = this.translateService.instant(this.disclaimerTC);
      this.safeDisclaimerHtml = this.sanitizer.bypassSecurityTrustHtml(translatedHtml);
    }
  }

  ngAfterViewInit() {
    this.checkIfTextIsTruncated();
  }

  checkIfTextIsTruncated() {
    setTimeout(() => {
      if (this.textElement && this.textElement.nativeElement) {
        const textElement = this.textElement.nativeElement;
        this.isTextTruncated = textElement.scrollHeight > textElement.clientHeight;
      }
    }, 0);
  }
}
