<main
  class="container px-0"
  [appInert]="(isAlertPromptVisible$ | async) || (isEligibilityQuizOpen$ | async)"
  [ngClass]="{ 'main-embedded': isEmbedded }"
  [class.vaccine-eligibility-widget-padding]="
    suppressTermsAndPrivacy && !suppressVaccineEligibility
  "
>
  <header class="pt-4 px-3 flex-shrink-0">
    <app-hero
      [currentStep]="2"
      [selectedVaccineName]="selectedVaccineName"
      [suppressHero]="isEmbedded"
    ></app-hero>

    <app-vaccine-eligibility-toast
      *ngIf="!suppressVaccineEligibility"
      [currentScreen]="'appointment-search'"
      [ngClass]="{
        'd-block pt-4 pb-2': isEligibilityToastOpen,
      }"
    ></app-vaccine-eligibility-toast>

    <h1
      id="search-title"
      [ngClass]="{
        'border-none pt-3': isEmbedded,
        'pt-5': !isEmbedded,
        'mt-4': !isEligibilityToastOpen && !suppressVaccineEligibility
      }"
      class="text-primary"
      *ngIf="!suppressSearchHeader"
    >
      {{ 'APPOINTMENT.SEARCH.TITLE' | translate }}
    </h1>

    <p
      class="text-tertiary mt-4 mb-3 d-flex flex-wrap gap-1 align-items-center"
      *ngIf="!suppressSearchHeader"
    >
      {{ 'APPOINTMENT.SEARCH.SUBTITLE' | translate }}

      <button
        [attr.aria-label]="'APPOINTMENT.SEARCH.BACK_BUTTON_ARIA_LABEL' | translate"
        class="btn btn-link-primary text-medium p-0"
        (click)="back()"
        [innerHtml]="selectedVaccineName"
        *ngIf="!suppressSearchHeader"
      ></button>
    </p>

    <p
      class="text-tertiary mt-3 mt-1"
      *ngIf="(loading$ | async) === false && !addressOrCoordinatesEntered"
    >
      {{ 'APPOINTMENT.SEARCH.HELPER_TEXT_SUBTITLE' | translate }}
    </p>

    <div class="row mt-3">
      <div class="d-flex flex-nowrap p-2 gap-2">
        <!-- Address entry -->
        <div
          class="badge rounded-pill pill-group w-100 position-relative"
          [class.focused]="addressHasFocus"
          [class]="addressOrCoordinatesEntered ? '' : 'flex-fill'"
          [class.error]="
            locations.length <= 0 && addressOrCoordinatesEntered && (loading$ | async) === false
          "
        >
          <button
            (click)="searchByAddress('icon')"
            class="btn search-button p-0"
            type="button"
            tabindex="0"
            [attr.aria-label]="'ICON.SEARCH' | translate"
          >
            <svg
              width="24"
              height="24"
              fill="none"
              aria-hidden="true"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 18C11.775 17.9996 13.4988 17.4054 14.897 16.312L19.293 20.708L20.707 19.294L16.311 14.898C17.405 13.4997 17.9996 11.7754 18 10C18 5.589 14.411 2 10 2C5.589 2 2 5.589 2 10C2 14.411 5.589 18 10 18ZM10 4C13.309 4 16 6.691 16 10C16 13.309 13.309 16 10 16C6.691 16 4 13.309 4 10C4 6.691 6.691 4 10 4Z"
                fill="var(--vax-text-secondary)"
              />
            </svg>
          </button>

          <label for="address" class="form-label visually-hidden">
            {{ 'APPOINTMENT.SEARCH.PLACEHOLDER' | translate }}
          </label>

          <ng-template
            #addressTypeaheadListTemplate
            let-matches="matches"
            let-query="query"
            let-typeaheadTemplateMethods
          >
            <ul class="typeahead-list">
              <li
                role="button"
                *ngFor="let match of matches"
                (click)="typeaheadTemplateMethods.selectMatch(match, $event)"
              >
                <span
                  class="text-primary"
                  *ngIf="match.item.name"
                  [innerHTML]="highlightMatchedTypeaheadText(match.item.name)"
                ></span>
                <span
                  class="text-secondary"
                  *ngIf="match.item.address"
                  [innerHTML]="highlightMatchedTypeaheadText(match.item.address)"
                ></span>
              </li>
            </ul>
          </ng-template>

          <input
            #addressInput
            class="form-control text-secondary"
            [placeholder]="'APPOINTMENT.SEARCH.PLACEHOLDER' | translate"
            [attr.aria-placeholder]="'APPOINTMENT.SEARCH.PLACEHOLDER' | translate"
            [attr.aria-label]="'APPOINTMENT.SEARCH.PLACEHOLDER' | translate"
            aria-describedby="no-results-error"
            tabindex="0"
            type="search"
            id="address"
            autocomplete="off"
            name="notASearchField"
            [formControl]="address"
            [typeahead]="addressSuggestions$"
            [optionsListTemplate]="addressTypeaheadListTemplate"
            [typeaheadMinLength]="3"
            [typeaheadWaitMs]="500"
            [typeaheadOptionField]="'address'"
            [typeaheadIsFirstItemActive]="false"
            [typeaheadSelectFirstItem]="false"
            [adaptivePosition]="true"
            (typeaheadOnSelect)="selectTypeaheadAddress($event)"
            (blur)="addressHasFocus = false"
            (focus)="addressHasFocus = true"
            (keyup)="searchTypeaheadAddress($event); searchByAddress($event)"
          />

          <button
            class="btn search-button p-0"
            tabindex="0"
            role="button"
            [attr.aria-label]="
              'APPOINTMENT.SEARCH.SEARCH_CURRENT_LOCATION_BUTTON_ARIA_LABEL' | translate
            "
            [disabled]="(loading$ | async) === true || geoLocationAccessDenied"
            (click)="searchByCurrentLocation()"
          >
            <svg
              aria-hidden="true"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 2C7.589 2 4.00001 5.589 4.00001 9.995C3.97101 16.44 11.696 21.784 12 22C12 22 20.029 16.44 20 10C20 5.589 16.411 2 12 2ZM12 14C9.79 14 8.00001 12.21 8.00001 10C8.00001 7.79 9.79 6 12 6C14.21 6 16 7.79 16 10C16 12.21 14.21 14 12 14Z"
                fill="var(--vax-primary)"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <ng-container *ngIf="locations.length <= 0">
      <div class="row">
        <span
          class="text-danger"
          id="no-results-error"
          role="alert"
          *ngIf="(loading$ | async) === false && addressOrCoordinatesEntered"
        >
          {{ 'APPOINTMENT.SEARCH.NO_RESULTS_TITLE' | translate }}&nbsp;
          {{ 'APPOINTMENT.SEARCH.NO_RESULTS_SUBTITLE' | translate }}
        </span>
      </div>
    </ng-container>

    <div class="d-flex justify-content-start">
      <button
        *ngIf="
          (!addressOrCoordinatesEntered || locations.length <= 0) && (loading$ | async) === false
        "
        (click)="searchByAddress('icon')"
        [disabled]="!address.valid"
        class="btn btn-primary btn-lg text-uppercase w-100 mt-3"
      >
        {{ 'APPOINTMENT.SEARCH.SEARCH_BUTTON' | translate }}
      </button>
    </div>
  </header>

  <div class="appointment-container px-3 pt-2 d-flex flex-column flex-grow-1">
    <!-- Loading Indicator -->
    <app-loading-location *ngIf="loading$ | async"></app-loading-location>

    <!-- Locations -->
    <ng-container *ngIf="locations.length > 0 && (loading$ | async) === false">
      <ng-container *ngFor="let location of locations">
        <app-location-card
          role="group"
          [location]="location"
          (sendAnalyticsEvent)="sendAnalyticsEvent()"
          (openExternalUrl)="openExternalUrl($event)"
          (viewLocationDetail)="doViewLocationDetail($event)"
          (selectedAppointment)="doSelectAppointment($event)"
        ></app-location-card>
      </ng-container>
    </ng-container>
  </div>
</main>

<div
  class="container text-center pb-2"
  *ngIf="!suppressTermsAndPrivacy"
  [class.vaccine-eligibility-widget-padding]="!suppressVaccineEligibility"
>
  <div class="d-flex flex-row justify-content-center align-items-center">
    <button
      class="btn btn-link-primary text-medium"
      (click)="showTerms()"
      [innerText]="'APPOINTMENT.SEARCH.TERMS_CONDITIONS_BUTTON' | translate"
    ></button>

    <div class="divider"></div>

    <button
      class="btn btn-link-primary text-medium"
      (click)="showPrivacy()"
      [innerText]="'APPOINTMENT.SEARCH.PRIVACY_POLICY_BUTTON' | translate"
    ></button>
  </div>
</div>

<app-alert-prompt
  *ngIf="isAlertPromptVisible$ | async"
  [titleTC]="alertPrompt?.titleTC"
  [subtitleTC]="alertPrompt?.subtitleTC"
  [cancelTextTC]="alertPrompt?.cancelTextTC"
  [continueTextTC]="alertPrompt?.continueTextTC"
  (cancelAction)="alertPromptCancel()"
  (continueAction)="alertPromptContinue()"
></app-alert-prompt>

<app-terms-and-privacy-modal
  *ngIf="showTermsAndPrivacyModal"
  [modalType]="modalType"
  [@fadeIn]="showTermsAndPrivacyModal"
  (dismissModal)="dismissTermsAndPrivacyModal()"
  (emitShowTerms)="showTerms($event)"
  (emitShowPrivacy)="showPrivacy($event)"
></app-terms-and-privacy-modal>

<app-vaccine-eligibility-quiz
  *ngIf="!suppressVaccineEligibility"
  [currentScreen]="'appointment-search'"
></app-vaccine-eligibility-quiz>
