<div
  *ngIf="!suppressSearchHeader"
  class="container pb-2 px-3 pt-5 d-flex gap-3 align-items-center justify-content-between"
>
  <app-logo></app-logo>

  <!-- Language Picker Button that presents the modal -->
  <app-language-picker-button
    *ngIf="shouldShowLanguagePickerButton && languagePickerButtonEnabled"
  ></app-language-picker-button>
</div>

<!-- Language Picker Modal -->
<app-language-picker
  *ngIf="
    (shouldShowLanguagePicker$ | async) && languagePickerButtonEnabled && !suppressSearchHeader
  "
></app-language-picker>

<router-outlet></router-outlet>
