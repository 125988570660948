import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withRouterConfig } from '@angular/router';

import { ROUTES } from './app.routes';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule, MissingTranslationHandler } from '@ngx-translate/core';
import { AsteriskInterceptor } from './common/interceptors/asterisk.interceptor';
import { RequestInterceptor } from './common/interceptors/request.interceptor';
import { VaxMissingTranslationHandler } from './common/services/translation/vax-missing-translation-loader';
import { AppInitService } from './app-init.service';
import { EnvironmentGuard } from './common/guards/environment.guard';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AlertModule } from 'ngx-bootstrap/alert';

export const appConfig: ApplicationConfig = {
  providers: [
    AppInitService,
    EnvironmentGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: (appInitService: AppInitService) => async () =>
        await appInitService
          .init()
          .catch((error) => console.log(error))
          .then(() => appInitService.configureUrl()),
      deps: [AppInitService],
      multi: true,
    },
    provideRouter(
      ROUTES,
      withRouterConfig({
        onSameUrlNavigation: 'reload', // Enable reloading for the same URL
      })
    ),
    importProvidersFrom(BsDatepickerModule.forRoot()),
    importProvidersFrom(AccordionModule.forRoot()),
    importProvidersFrom(AlertModule.forRoot()),
    importProvidersFrom(CollapseModule.forRoot()),
    importProvidersFrom(BrowserAnimationsModule),
    importProvidersFrom(
      TranslateModule.forRoot({
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: VaxMissingTranslationHandler, // EasyVax custom missing translation handler
        },
      })
    ),
    importProvidersFrom(TypeaheadModule.forRoot()),
    importProvidersFrom(HttpClientModule),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AsteriskInterceptor,
      multi: true,
    },
  ],
};
