import { EventEmitter, Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import {
  DEFAULT_LOCALE,
  SUPPORTED_LOCALES,
  getEmbedded,
  getEmbeddedCampaignId,
} from '../../constants/general';
import { Configuration } from '../../model/configuration';
import { Service } from '../../model/service';
import { Vaccine } from '../../model/vaccine';
import { environment } from 'src/environments/environment';

// ngx-bootstrap locale support for form date picker
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale } from 'ngx-bootstrap/locale';
import { DEFAULT_START_ROUTE } from 'src/app/app.routes';
import { BehaviorSubject, Observable } from 'rxjs';
defineLocale('es', esLocale);

@Injectable({
  providedIn: 'root',
})
export class EnvironmentService {
  private _apiBaseUrl = ''; // set below after environment is determined
  private _apiKey = ''; // set below after environment is determined
  private _campaignId = DEFAULT_START_ROUTE;
  private _configuration: Configuration | null = null;
  private _environment = ''; // set below after environment is determined
  private _locale = DEFAULT_LOCALE;
  private _resources: any = {};
  private _selectedService: Service | undefined;
  private _selectedVaccine: Vaccine | undefined;
  private _selectedZip: string | undefined;
  private _shouldShowLanguagePickerObj: BehaviorSubject<boolean> =
    new BehaviorSubject(false);

  public onrampVersion = '';
  public userId: string = uuidv4(); // userId that is included in all backbone API request headers
  public supportedLocales = SUPPORTED_LOCALES; // when adding a new locale you need to defineLocale above for ngx-bootstrap
  public localeChanged: EventEmitter<void> = new EventEmitter<void>();
  /**
   * Whether or not the user changed the language via language picker
   */
  public isLocaleOverride = false;
  /**
   * Whether or not to give the user the button to trigger language picker
   */
  public shouldShowLanguagePickerButton = false;
  /**
   * Whether or not the language picker modal is open
   */
  public shouldShowLanguagePicker$: Observable<boolean>;

  public get apiBaseUrl(): string {
    return this._apiBaseUrl;
  }

  public set apiBaseUrl(value: string) {
    this._apiBaseUrl = value;
  }

  public get apiKey(): string {
    return this._apiKey;
  }

  public set apiKey(value: string) {
    this._apiKey = value;
  }

  public get campaignId(): string {
    return this._campaignId;
  }

  public set campaignId(value: string) {
    this._campaignId = value;
  }

  public get configuration(): Configuration | null {
    return this._configuration;
  }

  public set configuration(value: Configuration | null) {
    this._configuration = value;
  }

  public get environment(): string {
    return this._environment;
  }

  public set environment(value: string) {
    this._environment = value;
  }

  public get locale(): string {
    return this._locale;
  }

  public set locale(value: string) {
    this._locale = value;
  }

  public get resources(): any {
    return this._resources;
  }

  public set resources(value: any) {
    this._resources = value;
  }

  public get selectedService(): Service | undefined {
    return this._selectedService;
  }

  public set selectedService(value: Service | undefined) {
    this._selectedService = value;
  }

  public get selectedVaccineName(): string | undefined {
    return this._selectedVaccine?.vaccineNameTC;
  }

  public get selectedVaccine(): Vaccine | undefined {
    return this._selectedVaccine;
  }

  public set selectedVaccine(value: Vaccine | undefined) {
    this._selectedVaccine = value;
  }

  public get selectedZip(): string | undefined {
    return this._selectedZip;
  }

  public set selectedZip(value: string | undefined) {
    this._selectedZip = value;
  }

  public get suppressSearchHeader(): boolean {
    return this._configuration?.suppressSearchHeader || false;
  }

  public get suppressTermsAndPrivacy(): boolean {
    return this._configuration?.suppressTermsAndPrivacy || false;
  }

  constructor() {
    this.shouldShowLanguagePicker$ =
      this._shouldShowLanguagePickerObj.asObservable();
  }

  public pathnameParts(): string[] {
    return window.location.pathname.split('/');
  }

  /**
   * This method determines what environment should be used for API requests.
   * It also sets the users locale and the apps campaignId
   */
  public configure() {
    this.environment = environment.name;
    this.apiBaseUrl = environment.apiBaseUrl;
    this.apiKey = environment.apiKey;
    this.onrampVersion = environment.version;

    // Check if the user's locale is supported
    const userLanguage = navigator.language.substring(0, 2);
    const isLocaleSupported = this.supportedLocales.find(
      (lang) => lang.localeCode === userLanguage
    );
    if (isLocaleSupported) {
      this.locale = userLanguage;
      this.hideLanguagePicker();
    } else {
      this.showLanguagePicker();
    }

    const embeddedCampaignId = getEmbeddedCampaignId();

    if (getEmbedded() && embeddedCampaignId) {
      this.campaignId = embeddedCampaignId;
    } else {
      // Parse paths
      const pathParts = this.pathnameParts();
      // Use the first path param to get the campaignId
      if (pathParts[1]) {
        this.campaignId = pathParts[1];
      }
    }
  }

  public showLanguagePicker() {
    // once we show this we want to persist the button so they can change it
    this.shouldShowLanguagePickerButton = true;

    if (this._shouldShowLanguagePickerObj.getValue() === false) {
      this._shouldShowLanguagePickerObj.next(true);
    }
  }
  public hideLanguagePicker() {
    if (this._shouldShowLanguagePickerObj.getValue() === true) {
      this._shouldShowLanguagePickerObj.next(false);
    }
  }

  /**
   * Recreates the userId that is included for all backbone API request headers
   */
  public recreateUserId() {
    this.userId = uuidv4();
  }
}
