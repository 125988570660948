<div *ngIf="hasTranslatedText" class="why-disclaimer p-3 d-flex flex-column gap-2">
  <p
    class="text-primary text-bold text-small m-0"
    *ngIf="titleTC"
    [innerHTML]="titleTC | translate"
  ></p>
  <div class="collapsable-container" *ngIf="disclaimerTC">
    <span
      #textElement
      [innerHTML]="safeDisclaimerHtml"
      [class.collapsed]="!isExpanded"
      [class.expanded]="isExpanded"
      class="collapsable-text text-primary text-small m-0"
    ></span>
    <button
      class="btn btn-clear-primary btn-sm text-small text-regular p-0"
      *ngIf="isTextTruncated || isExpanded"
      (click)="toggleView()"
    >
      {{
        (isExpanded
          ? 'VACCINE_ELIGIBILITY.VIEW_LESS_BUTTON'
          : 'VACCINE_ELIGIBILITY.VIEW_MORE_BUTTON'
        ) | translate
      }}
    </button>
  </div>
</div>
