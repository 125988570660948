import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AsteriskInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if asterisk is present in localStorage for non-Prod environments
    if (!environment.production) {
      if (localStorage.getItem(environment.apiLocalStorageErrorKey)?.includes('*')) {
        return throwError(() => new Error('Asterisk (*) detected in localStorage'));
      } else if (localStorage.getItem(environment.apiLocalStorageErrorKey)?.includes('500')) {
        return throwError(
          () =>
            new HttpErrorResponse({
              status: 500,
              statusText: 'Internal Server Error',
              url: request.url,
              error: { message: 'Asterisk (*) detected in localStorage - Simulated 500 Error' },
            })
        );
      }
    }

    // Pass the request to the next interceptor or the final handler
    return next.handle(request);
  }
}
