import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { EnvironmentService } from '../services/environment/environment.service';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(private environmentService: EnvironmentService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        onramp: 'web',
        'onramp-version': this.environmentService.onrampVersion,
        apiKey: this.environmentService.apiKey,
        'user-id': this.environmentService.userId,
      },
    });

    return next.handle(req).pipe(catchError((error) => throwError(() => error))) as Observable<
      HttpEvent<any>
    >;
  }
}
