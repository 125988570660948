export function arraysAreEqualIgnoreOrder(arr1?: any[], arr2?: any[]): boolean {
  if (!arr1 && !arr2) return true; // Both are undefined
  if (!arr1 || !arr2) return false; // One is undefined, the other is not
  if (arr1.length !== arr2.length) return false; // Length mismatch

  const set1 = new Set(arr1);
  const set2 = new Set(arr2);

  return arr1.every((item) => set2.has(item)) && arr2.every((item) => set1.has(item));
}
