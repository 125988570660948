import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { AppointmentDateTime } from 'src/app/common/model/appointment';

@Component({
  selector: 'app-time-picker',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnChanges {
  @Input() title = '';
  @Input() startDate = '';
  @Input() loading = false;
  @Input() appointments: AppointmentDateTime[] = [];
  @Output() selectedAppointmentTime = new EventEmitter<AppointmentDateTime>();

  selectedStartTime = '';
  noAppointments = false;

  ngOnChanges(changes: SimpleChanges): void {
    const { startDate } = changes;

    this.noAppointments = this.appointments.length <= 0;

    if (startDate && this.selectedStartTime) {
      if (this.selectedStartTime) {
        this.selectedStartTime = '';
        this.selectedAppointmentTime.emit(undefined);
      }
    }
  }

  selectTime(appointment: AppointmentDateTime) {
    this.selectedStartTime = appointment.time;
    this.selectedAppointmentTime.emit(appointment);
  }
}
