import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-alert-prompt',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './alert-prompt.component.html',
  styleUrls: ['./alert-prompt.component.scss'],
})
export class AlertPromptComponent implements OnInit, OnDestroy {
  @Input() titleTC?: string;
  @Input() subtitleTC?: string;
  @Input() cancelTextTC?: string;
  @Input() continueTextTC?: string;
  @Output() cancelAction: EventEmitter<void> = new EventEmitter();
  @Output() continueAction: EventEmitter<void> = new EventEmitter();

  public cancel() {
    this.cancelAction.emit();
  }

  public continue() {
    this.continueAction.emit();
  }

  ngOnInit() {
    // set modal-open on html so the content below the modal doesn't scroll
    document.body.setAttribute('data-modal-open', 'true');
    this.markElementsAsInert(true);
  }

  ngOnDestroy() {
    // remove the data-modal-open attribute so content can scroll again
    document.body.removeAttribute('data-modal-open');
    this.markElementsAsInert(false);
  }

  private markElementsAsInert(inert: boolean) {
    const elementsToInert = document.querySelectorAll('[appInert]');

    elementsToInert.forEach((element) => {
      element.setAttribute('appInert', inert.toString());
    });
  }
}
