import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import * as enTranslations from 'src/assets/translations/en.json';
import * as esTranslations from 'src/assets/translations/es.json';

export class VaxMissingTranslationHandler implements MissingTranslationHandler {
  handle(params: MissingTranslationHandlerParams) {
    const lang = params.translateService.currentLang;
    let translations: any;

    // Load the local translation based on the requested language
    switch (lang) {
      case 'en':
        translations = enTranslations;
        break;
      case 'es':
        translations = esTranslations;
        break;
      default: // default to english
        translations = enTranslations;
        break;
    }

    const translatedValue = this.getTranslationValue(
      params.key,
      translations,
      params.interpolateParams
    );
    return translatedValue;
  }

  private getTranslationValue(
    key: string,
    translations: any,
    interpolateParams?: any
  ): string | undefined {
    const keys = key.split('.');
    let value = translations;

    for (const k of keys) {
      if (!value || typeof value !== 'object') {
        return undefined;
      }
      value = value[k];
    }

    // handle interpolation params
    if (interpolateParams && value && typeof value === 'string') {
      value = this.interpolateValue(value, interpolateParams);
    }

    return value;
  }

  private interpolateValue(value: string, interpolateParams: any): string {
    let interpolatedValue = value;

    Object.keys(interpolateParams).forEach((key) => {
      const pattern = `{{\\s*${key}\\s*}}`;
      const regex = new RegExp(pattern, 'g');
      interpolatedValue = interpolatedValue.replace(regex, interpolateParams[key]);
    });

    return interpolatedValue;
  }
}
