<div class="modal p-3" aria-modal="true" role="dialog" tabindex="0">
  <div class="modal-header d-flex align-items-center justify-content-end">
    <!-- Cancel button -->
    <button
      class="btn p-0"
      tabindex="0"
      (click)="dismiss()"
      [attr.aria-label]="'ICON.CLOSE' | translate"
    >
      <img
        [src]="closeIconUrl"
        [alt]="'ICON.CLOSE' | translate"
        [attr.aria-label]="'ICON.CLOSE' | translate"
      />
    </button>
  </div>

  <div class="modal-body d-flex">
    <div
      *ngIf="!loading && !error"
      class="text-primary"
      [innerHtml]="htmlBody"
    ></div>

    <div
      class="d-flex align-items-center justify-content-center w-100 primary"
      *ngIf="loading || error"
    >
      <app-loading *ngIf="loading"></app-loading>
      <ng-container *ngIf="error">
        <p class="text-tertiary">{{ error }}</p>
      </ng-container>
    </div>
  </div>
</div>
