import { Injectable, OnDestroy } from '@angular/core';
import {
  Event,
  IsActiveMatchOptions,
  Navigation,
  NavigationBehaviorOptions,
  NavigationExtras,
  OnSameUrlNavigation,
  RouteReuseStrategy,
  Router,
  RouterState,
  Routes,
  TitleStrategy,
  UrlCreationOptions,
  UrlHandlingStrategy,
  UrlSerializer,
  UrlTree,
} from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EmbeddedRouter implements OnDestroy {
  currentRoute = new BehaviorSubject<string>('');

  setCurrentRoute(route: string) {
    this.currentRoute.next(route);
  }

  get currentRoute$(): Observable<string> {
    return this.currentRoute.asObservable();
  }

  get events(): Observable<Event> {
    throw new Error('Method not implemented.');
  }
  // routerState: RouterState;
  // errorHandler: (error: any) => any;
  // malformedUriErrorHandler: (error: URIError, urlSerializer: UrlSerializer, url: string) => UrlTree;
  // navigated: boolean;
  // urlHandlingStrategy: UrlHandlingStrategy;
  // routeReuseStrategy: RouteReuseStrategy;
  titleStrategy?: TitleStrategy | undefined;
  // onSameUrlNavigation: OnSameUrlNavigation;
  // paramsInheritanceStrategy: "emptyOnly" | "always";
  // urlUpdateStrategy: "deferred" | "eager";
  // canceledNavigationResolution: "replace" | "computed";
  // config: Routes;
  // componentInputBindingEnabled: boolean;
  initialNavigation(): void {
    console.log('initialNavigation');
    throw new Error('Method not implemented.');
  }
  setUpLocationChangeListener(): void {
    console.log('setUpLocationChangeListener');
    throw new Error('Method not implemented.');
  }
  get url(): string {
    console.log('url');
    throw new Error('Method not implemented.');
  }
  getCurrentNavigation(): Navigation | null {
    console.log('getCurrentNavigation');
    throw new Error('Method not implemented.');
  }
  get lastSuccessfulNavigation(): Navigation | null {
    console.log('lastSuccessfulNavigation');
    throw new Error('Method not implemented.');
  }
  resetConfig(config: Routes): void {
    console.log('resetConfig');
    throw new Error('Method not implemented.');
  }
  ngOnDestroy(): void {
    return;
  }

  dispose(): void {
    throw new Error('Method not implemented.');
  }
  createUrlTree(
    commands: any[],
    navigationExtras?: UrlCreationOptions | undefined
  ): UrlTree {
    console.log('createUrlTree');
    throw new Error('Method not implemented.');
  }
  navigateByUrl(
    url: string | UrlTree,
    extras?: NavigationBehaviorOptions | undefined
  ): Promise<boolean> {
    console.log('navigateByUrl');
    throw new Error('Method not implemented.');
  }
  navigate(
    commands: any[],
    extras?: NavigationExtras | undefined
  ): Promise<boolean> {
    // console.log('commands', commands, extras);

    this.setCurrentRoute(commands.join('/'));

    return new Promise((resolve) => {
      resolve(true);
    });
  }
  serializeUrl(url: UrlTree): string {
    console.log('serializeUrl');
    throw new Error('Method not implemented.');
  }
  parseUrl(url: string): UrlTree {
    console.log('parseUrl');
    throw new Error('Method not implemented.');
  }
  isActive(url: string | UrlTree, exact: boolean): boolean;
  isActive(url: string | UrlTree, matchOptions: IsActiveMatchOptions): boolean;
  isActive(url: unknown, matchOptions: unknown): boolean {
    console.log('isActive');
    throw new Error('Method not implemented.');
  }
}
