import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { getEmbedded, getEmbeddedAssetsUrl } from 'src/app/common/constants/general';
import { TranslateModule } from '@ngx-translate/core';
import { RouteService } from 'src/app/common/services/route/route.service';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { AppointmentService } from 'src/app/common/services/appointment/appointment.service';
import { AppointmentSelection } from 'src/app/common/model/appointment';
import { LocationAddressComponent } from '../location-address/location-address.component';
import { DateUtil } from 'src/app/common/utils/date';

@Component({
  selector: 'app-hero',
  standalone: true,
  imports: [CommonModule, TranslateModule, LocationAddressComponent],
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss'],
})
export class HeroComponent implements OnInit {
  @Input() currentStep = 1;
  @Input() selectedVaccineName?: string;
  @Input() selectedAppointment?: AppointmentSelection;
  @Input() suppressHero? = false;
  @Input() suppressEdit? = false;
  @Input() isConfirmation? = false;

  prettyDate = '';
  prettyTime = '';

  constructor(
    private routeService: RouteService,
    private environmentService: EnvironmentService,
    private appointmentService: AppointmentService
  ) {}
  ngOnInit() {
    if (this.selectedAppointment) {
      const { date, time } = this.selectedAppointment;

      const formattedDate = DateUtil.formatDateIsoToYearMonthDay(date);
      this.prettyDate = DateUtil.formatDateLong(formattedDate);

      // set prettyTime based on reservation date
      const formattedTime = DateUtil.formatTime(time);
      const localTimeZone = DateUtil.localTimeZoneAbbr;
      this.prettyTime = `${formattedTime} ${localTimeZone}`;
    }
  }

  // Utility to check if a given step is active
  isActive(step: number): boolean {
    if (this.isConfirmation) {
      return false;
    }

    return this.currentStep === step;
  }

  isStepComplete(step: number): boolean {
    if (this.isConfirmation) {
      return true;
    }

    return this.currentStep > step;
  }

  backToVaccinePicker() {
    // clear current state
    this.appointmentService.selectedAppointment = undefined;
    this.environmentService.selectedService = undefined;
    this.environmentService.selectedVaccine = undefined;

    const router = this.routeService.getRouter();
    router.navigate([this.routeService.getServicesRoute()]);
  }

  backToAppointmentSearch() {
    this.appointmentService.selectedAppointment = undefined;

    // keep the patient form data (additionalData) so we can attempt to auto-populate
    const additionalData = this.appointmentService.reservation?.additionalData;
    this.appointmentService.reservation = {
      duration: 0,
      additionalData: additionalData,
    };

    this.appointmentService.clearError();

    const router = this.routeService.getRouter();
    router.navigate([this.routeService.getAppointmentsRoute()]);
  }
}
