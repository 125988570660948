<ng-container [ngSwitch]="locationIntegrationLevel">
  <app-location-integration-level-one
    [location]="location"
    (sendAnalyticsEvent)="sendAnalyticsEvent.emit()"
    (openExternalUrl)="openExternalUrl.emit($event)"
    (viewLocationDetail)="viewLocationDetail.emit(location)"
    *ngSwitchCase="LocationIntegrationLevel.Level1"
  ></app-location-integration-level-one>

  <app-location-integration-level-two
    [location]="location"
    (sendAnalyticsEvent)="sendAnalyticsEvent.emit()"
    (openExternalUrl)="openExternalUrl.emit($event)"
    (viewLocationDetail)="viewLocationDetail.emit(location)"
    *ngSwitchCase="LocationIntegrationLevel.Level2"
  ></app-location-integration-level-two>

  <app-location-integration-level-twohalf
    #extendedComponent
    [location]="location"
    (sendAnalyticsEvent)="sendAnalyticsEvent.emit()"
    (openExternalUrl)="openExternalUrl.emit($event)"
    (viewLocationDetail)="viewLocationDetail.emit(location)"
    *ngSwitchCase="LocationIntegrationLevel.Level2Half"
  ></app-location-integration-level-twohalf>

  <app-location-integration-level-three
    #extendedComponent
    [location]="location"
    (sendAnalyticsEvent)="sendAnalyticsEvent.emit()"
    (selectedAppointment)="selectedAppointment.emit($event)"
    (viewLocationDetail)="viewLocationDetail.emit(location)"
    *ngSwitchCase="LocationIntegrationLevel.Level3"
  ></app-location-integration-level-three>

  <app-location-integration-level-four
    #extendedComponent
    [location]="location"
    (sendAnalyticsEvent)="sendAnalyticsEvent.emit()"
    (selectedAppointment)="selectedAppointment.emit($event)"
    (viewLocationDetail)="viewLocationDetail.emit(location)"
    *ngSwitchCase="LocationIntegrationLevel.Level4"
  ></app-location-integration-level-four>

  <div *ngSwitchDefault>
    <!-- Handle the default case if needed -->
  </div>
</ng-container>
