import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import { Language } from 'src/app/common/model/language';

@Component({
  selector: 'app-language-picker-button',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './language-picker-button.component.html',
  styleUrls: ['./language-picker-button.component.scss'],
})
export class LanguagePickerButtonComponent {
  languages: Language[];
  suppressSearchHeader = false;

  get formattedLanguage(): Language | undefined {
    return this.languages.find(
      (language) => language.localeCode == this.selectedLanguage
    );
  }

  get selectedLanguage(): string {
    return this.environmentService.locale;
  }

  constructor(private environmentService: EnvironmentService) {
    const { supportedLocales, suppressSearchHeader } = this.environmentService;
    this.languages = supportedLocales;
    this.suppressSearchHeader = suppressSearchHeader;
  }
  public presentLanguagePicker() {
    this.environmentService.showLanguagePicker();
  }
}
