export const environment = {
  name: 'qa',
  production: false,
  version: '2.1.0',
  apiBaseUrl: 'https://iyliwrdtbmehfbm.easyvax.org/api',
  apiLocalStorageErrorKey: 'vax-qa', // update localStorage to include this key with a value of * to force API errors for testing
  apiKey: 'cR62P-StY!%KhFUH', // API key for backbone
  recaptchaKey: '6LeRoQ8pAAAAABUHLjEtzM7LG-df0jVZ7rMswd8i',
  recaptchaAction: 'reserve',
};
