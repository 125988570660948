<section>
  <div class="d-flex flex-column gap-2 placeholder-text-block col-8 col-md-5 placeholder-glow">
    <span class="placeholder bg-light-gray col-8 col-md-5 placeholder-lg"></span>
  </div>

  <div class="row placeholder-check-row m-0 gap-2 placeholder-glow">
    <div
      class="col-md-4 placeholder-check-item pb-3 pt-3 ps-3 d-flex flex-row justify-content-start align-items-center gap-2 placeholder"
    ></div>

    <div
      class="col-md-4 placeholder-check-item pb-3 pt-3 ps-3 d-flex flex-row justify-content-start align-items-center gap-2 placeholder"
    ></div>

    <div
      class="col-md-4 placeholder-check-item pb-3 pt-3 ps-3 d-flex flex-row justify-content-start align-items-center gap-2 placeholder"
    ></div>

    <div
      class="col-md-4 placeholder-check-item pb-3 pt-3 ps-3 d-flex flex-row justify-content-start align-items-center gap-2 placeholder"
    ></div>

    <div
      class="col-md-4 placeholder-check-item pb-3 pt-3 ps-3 d-flex flex-row justify-content-start align-items-center gap-2 placeholder"
    ></div>

    <div
      class="col-md-4 placeholder-check-item pb-3 pt-3 ps-3 d-flex flex-row justify-content-start align-items-center gap-2 placeholder"
    ></div>
  </div>
</section>

<section class="padding-top-large">
  <div class="d-flex flex-column gap-2 placeholder-text-block col-8 col-md-5 placeholder-glow">
    <span class="placeholder bg-light-gray col-8 col-md-5 placeholder-lg"></span>
  </div>

  <div class="row placeholder-check-row m-0 gap-2 placeholder-glow">
    <div
      class="col-md-4 placeholder-check-item pb-3 pt-3 ps-3 d-flex flex-row justify-content-start align-items-center gap-2 placeholder"
    ></div>

    <div
      class="col-md-4 placeholder-check-item pb-3 pt-3 ps-3 d-flex flex-row justify-content-start align-items-center gap-2 placeholder"
    ></div>

    <div
      class="col-md-4 placeholder-check-item pb-3 pt-3 ps-3 d-flex flex-row justify-content-start align-items-center gap-2 placeholder"
    ></div>

    <div
      class="col-md-4 placeholder-check-item pb-3 pt-3 ps-3 d-flex flex-row justify-content-start align-items-center gap-2 placeholder"
    ></div>

    <div
      class="col-md-4 placeholder-check-item pb-3 pt-3 ps-3 d-flex flex-row justify-content-start align-items-center gap-2 placeholder"
    ></div>

    <div
      class="col-md-4 placeholder-check-item pb-3 pt-3 ps-3 d-flex flex-row justify-content-start align-items-center gap-2 placeholder"
    ></div>
  </div>
</section>
