<app-location-integration-level-base
  [location]="location"
  [shouldShowLocationFooter]="false"
  [shouldShowPhoneNumber]="false"
  (viewLocationDetail)="viewLocationDetail.emit(location)"
  *ngIf="location"
>
  <!-- Show Check website for availability if we have bookingUrl with no appointments -->
  <div
    *ngIf="location.bookingUrl && filteredAppointments.length <= 0"
    class="position-relative d-inline-flex"
  >
    <p class="text-uppercase text-tertiary text-small mb-0 mt-4">
      {{ 'APPOINTMENT.LOCATION.APPOINTMENT_WEBSITE_CHECK_AVAILABILITY' | translate }}
    </p>
  </div>

  <!-- Results -->
  <div
    #appointmentRow
    *ngIf="filteredAppointments.length > 0"
    class="appointment-row mt-4 d-inline-flex"
  >
    <div>
      <p class="text-uppercase text-tertiary text-small mb-0">
        {{
          'APPOINTMENT.LOCATION.AVAILABLE_APPOINTMENTS'
            | translate : { appointmentsLength: numAppointments }
        }}
      </p>

      <ng-container
        *ngFor="let appointment of filteredAppointments.slice(0, 3); let last = last; index as i"
      >
        <span class="text-bold text-heading text-secondary">{{ appointment.prettyTime }}</span>
        <span class="text-bold text-heading text-secondary" *ngIf="!appointment.isToday">
          {{ getAppointmentDayOfWeek(appointment.date) }}
        </span>
        <span class="text-heading text-secondary" *ngIf="i <= 1 && !last">,&nbsp;</span>
      </ng-container>

      <span class="text-heading text-secondary text-bold" *ngIf="numAppointments > 3">
        {{ 'APPOINTMENT.LOCATION.AVAILABLE_APPOINTMENTS_OVERFLOW' | translate }}
      </span>
    </div>
  </div>

  <!-- Level 2.5 Buttons -->
  <div class="d-flex flex-row gap-3">
    <button
      *ngIf="location.bookingUrl"
      [attr.aria-label]="
        location.locationName +
        ' ' +
        ('APPOINTMENT.LOCATION.APPOINTMENT_WEBSITE_BUTTON' | translate)
      "
      (click)="
        setAnalyticsEvent('website-transfer', 'location-booking-url');
        setAlertPrompt('pharmacy-website');
        openExternalUrl.emit(location.bookingUrl)
      "
      class="btn btn-lg btn-outline-primary d-flex flex-column flex-fill align-items-center justify-content-center text-uppercase w-100 mt-4"
    >
      {{ 'APPOINTMENT.LOCATION.APPOINTMENT_WEBSITE_BUTTON' | translate }}
    </button>
  </div>
</app-location-integration-level-base>
