import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListSelectComponent } from '../components/list-select/list-select.component';
import { ListSelectItem } from '../common/model/list-select';
import { Vaccine } from '../common/model/vaccine';
import { EnvironmentService } from '../common/services/environment/environment.service';
import { Router } from '@angular/router';
import { FooterComponent } from '../components/footer/footer.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RouteService } from '../common/services/route/route.service';
import { Title } from '@angular/platform-browser';
import { getEmbedded } from '../common/constants/general';

@Component({
  selector: 'app-vaccine-selection',
  standalone: true,
  imports: [
    CommonModule,
    ListSelectComponent,
    FooterComponent,
    TranslateModule,
  ],
  templateUrl: './vaccine-selection.component.html',
})
export class VaccineSelectionComponent implements OnInit {
  items: Array<ListSelectItem> = [];
  get leftButtonName(): string {
    return this.translateService.instant('BUTTON.BACK');
  }
  get rightButtonName(): string {
    return this.translateService.instant(
      'VACCINE.NEXT_STEP_APPOINTMENT_BUTTON'
    );
  }
  get selectedServiceName(): string {
    // Set the selected service name
    if (this.environmentService.selectedService?.serviceNameTC) {
      return this.translateService.instant(
        this.environmentService.selectedService?.serviceNameTC
      );
    }
    return '';
  }
  selectedVaccine: Vaccine | undefined;
  vaccines: Array<Vaccine> | undefined = [];

  constructor(
    private route: Router,
    private routeService: RouteService,
    private environmentService: EnvironmentService,
    private translateService: TranslateService,
    private titleService: Title
  ) {
    // Set the vaccines based on the selected service
    this.vaccines = this.environmentService.selectedService?.vaccines;
  }

  ngOnInit(): void {
    if (!getEmbedded()) {
      this.titleService.setTitle(
        this.translateService.instant('PAGES.VACCINE_BRANDS.TITLE')
      );
    }

    if (this.vaccines) {
      this.createItems();
    }
  }

  private createItems() {
    if (this.vaccines) {
      this.items = this.vaccines.map((item) => {
        return new ListSelectItem(
          item.vaccineNameTC,
          item.vaccineDescriptionTC,
          false,
          item.vaccineCode
        );
      });
    }
  }

  public leftButtonClick() {
    this.environmentService.selectedVaccine = undefined;
    this.route.navigate([this.routeService.getServicesRoute()]);
  }

  public rightButtonClick() {
    this.environmentService.selectedVaccine = this.selectedVaccine;
    this.route.navigate([this.routeService.getAppointmentsRoute()]);
  }

  public setItem(event: any) {
    if (event) {
      this.selectedVaccine = this.vaccines?.find(
        (v) => v.vaccineCode == event.code
      );
    } else {
      this.selectedVaccine = undefined;
    }
  }
}
