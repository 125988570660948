import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { getEmbeddedAssetsUrl } from 'src/app/common/constants/general';
import { VaccineEligibilityService } from 'src/app/common/services/vaccine-eligibility/vaccine-eligibility.service';
import { VaccineEligibilityRecommendationSection } from 'src/app/common/model/vaccine-eligibility';
import { Service } from 'src/app/common/model/service';
import { SECTION } from './vaccine-eligibility-recommendations.constants';
import { VaccineEligibilityDisclaimerCardComponent } from '../vaccine-eligibility-disclaimer-card/vaccine-eligibility-disclaimer-card.component';
import { LoadingComponent } from '../../loading/loading.component';

@Component({
  selector: 'app-vaccine-eligibility-recommendations',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    VaccineEligibilityDisclaimerCardComponent,
    LoadingComponent,
  ],
  templateUrl: './vaccine-eligibility-recommendations.component.html',
  styleUrls: ['./vaccine-eligibility-recommendations.component.scss'],
})
export class VaccineEligibilityRecommendationsComponent implements OnInit {
  @Input() loading: boolean | null = false;
  @Output() handleSubmit: EventEmitter<Service> = new EventEmitter();
  @Output() restart: EventEmitter<void> = new EventEmitter();

  section: VaccineEligibilityRecommendationSection = SECTION;
  vaccineEligibilityRecommendations: Service[] = [];
  selectedVaccineRecommendation = '';

  vaccinationArmSmallUrl = 'assets/vaccination-arm-sm.jpg';
  vaccinationArmLargeUrl = 'assets/vaccination-arm.jpg';

  constructor(
    private translateService: TranslateService,
    private vaccineEligibilityService: VaccineEligibilityService
  ) {}

  ngOnInit(): void {
    this.vaccinationArmSmallUrl = `${getEmbeddedAssetsUrl()}/assets/vaccination-arm-sm.jpg`;
    this.vaccinationArmLargeUrl = `${getEmbeddedAssetsUrl()}/assets/vaccination-arm.jpg`;
    this.vaccineEligibilityRecommendations =
      this.vaccineEligibilityService.vaccineEligibilityResponse?.recommendations || [];
  }

  restartQuiz() {
    this.restart.emit();
  }

  toggleIsOpen() {
    this.vaccineEligibilityService.toggleElibilityQuizIsOpen();
  }

  submitForm(): void {
    const payload = this.vaccineEligibilityRecommendations.find(
      (service) => service.serviceCode === this.selectedVaccineRecommendation
    );
    this.handleSubmit.emit(payload);
  }
}
