import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentService } from 'src/app/common/services/appointment/appointment.service';
import { Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RouteService } from 'src/app/common/services/route/route.service';
import { Title } from '@angular/platform-browser';
import { getEmbedded } from 'src/app/common/constants/general';

@Component({
  selector: 'app-appointment-reservation-error',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './appointment-reservation-error.component.html',
  styleUrls: ['./appointment-reservation-error.component.scss'],
})
export class AppointmentReservationErrorComponent implements OnInit {
  constructor(
    private appointmentService: AppointmentService,
    private route: Router,
    private routeService: RouteService,
    private titleService: Title,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    if (!getEmbedded()) {
      this.titleService.setTitle(
        this.translateService.instant('PAGES.RESERVE_APPOINTMENT_ERROR.TITLE')
      );
    }
  }

  selectNewTime() {
    const { reservation, selectedAppointment } = this.appointmentService;

    if (reservation && selectedAppointment) {
      // Route to location detail screen
      const { location } = selectedAppointment;

      const serializedParams = encodeURIComponent(JSON.stringify(location));
      this.route.navigate(
        [this.routeService.getLocationDetailsRoute(location.locationId)],
        {
          queryParams: { location: serializedParams },
        }
      );
    }
  }

  selectNewLocation() {
    const { reservation, selectedAppointment } = this.appointmentService;

    if (reservation && selectedAppointment) {
      // Route to appointment search screen
      this.route.navigate([this.routeService.getAppointmentsRoute()]);
    }
  }
}
