import { Injectable } from '@angular/core';
import { AlertPromptService } from './alert-prompt.service';
import { AlertPromptBase } from '../../model/alert-prompt/alert-prompt-base';

@Injectable({
  providedIn: 'root',
})
export class AlertPromptExternalUrlService extends AlertPromptService {
  alertPrompt?: AlertPromptBase<any>;
  externalUrlToOpen?: string;

  constructor() {
    super();
  }

  override openAlertPrompt(url: string) {
    this.setExternalUrl(url);

    super.showAlertPrompt();
  }

  override hideAlertPrompt() {
    super.hideAlertPrompt();

    this.clearExternalUrl();
    this.clearAlertPrompt();
  }

  private setExternalUrl(url: string) {
    this.externalUrlToOpen = url;
  }

  private clearExternalUrl() {
    this.externalUrlToOpen = '';
  }

  private clearAlertPrompt() {
    this.alertPrompt = undefined;
  }

  setAlertPrompt(prompt: AlertPromptBase<any>) {
    this.alertPrompt = prompt;
  }

  getAlertPrompt(): AlertPromptBase<any> | undefined {
    return this.alertPrompt;
  }

  continueToExternalUrl() {
    if (this.externalUrlToOpen) {
      window.open(this.externalUrlToOpen);
    }
  }
}
