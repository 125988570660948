export interface ListSelectItem {
  title: string;
  subtitle?: string;
  selected: boolean;
  code: string;
}

export class ListSelectItem implements ListSelectItem {
  title: string;
  subtitle?: string;
  selected: boolean;
  code: string;

  constructor(title: string, subtitle: string, selected: boolean, code: string) {
    this.title = title;
    this.subtitle = subtitle;
    this.selected = selected;
    this.code = code;
  }
}
