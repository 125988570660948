<main class="container py-4 px-3">
  <header class="mb-4">
    <h1 class="text-primary" [innerText]="'APPOINTMENT.RESERVATION_ERROR.TITLE' | translate"></h1>
  </header>

  <div class="justify-content-center align-items-center d-flex flex-column">
    <img class="img-fluid" [alt]="'IMAGE.SAD_FACE' | translate" src="assets/feeling-blue.svg" />

    <p
      class="mt-3 text-tertiary"
      [innerText]="'APPOINTMENT.RESERVATION_ERROR.DISCLAIMER' | translate"
    ></p>

    <p class="mt-4">
      {{ 'APPOINTMENT.RESERVATION_ERROR.OPTION_1_REGULAR' | translate }}
      <strong>{{ 'APPOINTMENT.RESERVATION_ERROR.OPTION_1_BOLD' | translate }}</strong>
    </p>
    <button
      (click)="selectNewTime()"
      class="btn btn-outline-primary text-uppercase text-semibold d-flex flex-column flex-fill align-items-center w-100 h-100 m-auto justify-content-center"
    >
      {{ 'APPOINTMENT.RESERVATION_ERROR.OPTION_1_BUTTON' | translate }}
    </button>

    <p class="mt-5">
      {{ 'APPOINTMENT.RESERVATION_ERROR.OPTION_2_REGULAR' | translate }}
      <strong>{{ 'APPOINTMENT.RESERVATION_ERROR.OPTION_2_BOLD' | translate }}</strong>
    </p>
    <button
      (click)="selectNewLocation()"
      class="btn btn-outline-primary text-uppercase text-semibold d-flex flex-column flex-fill align-items-center w-100 h-100 m-auto justify-content-center"
    >
      {{ 'APPOINTMENT.RESERVATION_ERROR.OPTION_2_BUTTON' | translate }}
    </button>
  </div>
</main>
