export class ThemeUtil {
  static isDarkMode = () => {
    const dark = window.matchMedia('(prefers-color-scheme: dark)');
    return dark.matches;
  };

  static hexToRgb = (hex: string): { r: number; g: number; b: number } => {
    const bigint = parseInt(hex.slice(1), 16);
    return {
      r: (bigint >> 16) & 255,
      g: (bigint >> 8) & 255,
      b: bigint & 255,
    };
  };

  static applyHexAsRgbToCssVar = (
    hex: string,
    variablePrefix: string,
    element: HTMLElement = document.documentElement
  ): void => {
    const rgb = this.hexToRgb(hex);

    // Set individual RGB channels as CSS variables on the provided element
    element.style.setProperty(`--${variablePrefix}-r`, rgb.r.toString());
    element.style.setProperty(`--${variablePrefix}-g`, rgb.g.toString());
    element.style.setProperty(`--${variablePrefix}-b`, rgb.b.toString());
  };
}
