<main
  class="container py-4 px-3"
  [class.vaccine-eligibility-widget-padding]="!suppressVaccineEligibility"
  [appInert]="isEligibilityQuizOpen$ | async"
>
  <header>
    <app-hero
      [currentStep]="3"
      [selectedVaccineName]="selectedVaccineName"
      [selectedAppointment]="selectedAppointment"
      [suppressHero]="isEmbedded"
      [suppressEdit]="true"
      [isConfirmation]="true"
    ></app-hero>

    <app-vaccine-eligibility-toast
      *ngIf="!suppressVaccineEligibility"
      (isLoading)="setIsLoadingForEligibility($event)"
      [currentScreen]="'appointment-confirmation'"
      [ngClass]="{
        'd-block pt-4 pb-2': isEligibilityToastOpen,
      }"
    ></app-vaccine-eligibility-toast>
  </header>

  <div
    id="confirmed-container"
    [ngClass]="{
      'border-none pt-3': isEmbedded,
      'pt-5': !isEmbedded,
      'mt-4': !isEligibilityToastOpen && !suppressVaccineEligibility && !isEmbedded
    }"
  >
    <h1 class="text-primary">
      {{ 'APPOINTMENT.CONFIRMATION.TITLE' | translate }}
    </h1>
    <p class="text-tertiary">{{ 'APPOINTMENT.CONFIRMATION.SUBTITLE' | translate }}</p>

    <h5 id="disclaimer" class="mb-4 d-inline-block">
      {{
        'APPOINTMENT.CONFIRMATION.CANCEL_DISCLAIMER' | translate : { locationName: locationName }
      }}
    </h5>

    <app-appointment-detail-card></app-appointment-detail-card>

    <app-vaccine-eligibility-toast
      *ngIf="!suppressVaccineEligibility"
      (isLoading)="setIsLoadingForEligibility($event)"
      [ngClass]="{
        'd-block pb-2': isEligibilityToastOpen,
      }"
    ></app-vaccine-eligibility-toast>

    <app-footer
      [leftButtonName]="'APPOINTMENT.CONFIRMATION.DONE_BUTTON' | translate"
      [leftButtonLoading]="(loading$ | async) === true"
      [leftButtonDisabled]="(loading$ | async) === true"
      (leftButtonClicked)="done()"
      [rightButtonName]="'APPOINTMENT.CONFIRMATION.SCHEDULE_ANOTHER_BUTTON' | translate"
      [rightButtonLoading]="(loading$ | async) === true"
      [rightButtonDisabled]="(loading$ | async) === true"
      (rightButtonClicked)="done()"
    ></app-footer>
  </div>
</main>

<app-vaccine-eligibility-quiz
  *ngIf="!suppressVaccineEligibility"
  (isLoading)="setIsLoadingForEligibility($event)"
  [currentScreen]="'appointment-confirmation'"
></app-vaccine-eligibility-quiz>
