<div
  *ngIf="isMultiSelect"
  class="row check-row m-0 gap-2"
  role="group"
  [attr.aria-label]="listCategoryTC || '' | translate"
>
  <div
    *ngFor="let item of items"
    tabindex="-1"
    [ngClass]=""
    class="col-md-4 pb-3 pt-3 ps-3 d-flex flex-row justify-content-start align-items-center gap-2"
    [ngClass]="{
      selected: item.selected,
      disabled: selectedCode === item.code,
      'card-item': isCardStyle,
      'list-item': !isCardStyle
    }"
    (click)="multiSelectItem(item)"
    (keydown.space)="selectItem(item, $event)"
  >
    <label
      [for]="'list-item-' + item.code"
      class="d-flex flex-row justify-content-start align-items-center gap-2"
    >
      <input
        class="form-check-input m-0"
        type="checkbox"
        role="checkbox"
        [attr.aria-label]="(item.title | translate) + ' ' + (item.subtitle ?? '' | translate)"
        [id]="'list-item-' + item.code"
        [checked]="item.selected || selectedCode === item.code"
        [disabled]="selectedCode === item.code"
        (click)="multiSelectItem(item)"
      />
      <div aria-hidden="true" class="d-flex flex-column">
        <span class="m-0 text-primary">
          <strong>{{ item.title | translate }}</strong>
        </span>
        <span
          class="m-0 text-tertiary text-small"
          *ngIf="item.subtitle && (item.subtitle | translate).length > 0"
        >
          {{ item.subtitle | translate }}
        </span>
      </div>
    </label>
  </div>
</div>

<div
  *ngIf="!isMultiSelect"
  class="row check-row m-0 gap-2"
  role="radiogroup"
  [attr.aria-label]="listCategoryTC || '' | translate"
>
  <div
    *ngFor="let item of items"
    tabindex="-1"
    class="col-md-4 pb-3 pt-3 ps-3 d-flex flex-row justify-content-start align-items-center gap-2"
    [ngClass]="{ selected: item.selected, 'card-item': isCardStyle, 'list-item': !isCardStyle }"
    (click)="selectItem(item)"
    (keydown.space)="selectItem(item, $event)"
  >
    <label
      [for]="'list-item-' + item.code"
      class="d-flex flex-row justify-content-start align-items-center gap-2"
    >
      <input
        class="form-check-input m-0"
        type="radio"
        role="radio"
        [attr.aria-label]="(item.title | translate) + ' ' + (item.subtitle ?? '' | translate)"
        [name]="item.title | translate"
        [id]="'list-item-' + item.code"
        [checked]="item.selected"
        (click)="selectItem(item)"
      />
      <div aria-hidden="true" class="d-flex flex-column">
        <span class="m-0 text-primary" [class.text-bold]="isCardStyle">
          {{ item.title | translate }}
        </span>
        <span
          class="m-0 text-tertiary text-small"
          *ngIf="item.subtitle && (item.subtitle | translate).length > 0"
        >
          {{ item.subtitle | translate }}
        </span>
      </div>
    </label>
  </div>
</div>
