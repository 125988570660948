import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { EnvironmentService } from '../services/environment/environment.service';
import { AppointmentService } from '../services/appointment/appointment.service';
import { RouteService } from '../services/route/route.service';
import { DEFAULT_START_ROUTE } from 'src/app/app.routes';
import { AppInitService } from 'src/app/app-init.service';

/**
 *  The purpose of this class is to prevent users from accessing certain routes if the application environment
 * is not properly configured. The EnvironmentGuard class has several methods that check the application
 * environment state and return a boolean indicating whether or not the user can access the route
 */
@Injectable()
export class EnvironmentGuard {
  constructor(
    private environmentService: EnvironmentService,
    private appointmentService: AppointmentService,
    private router: Router,
    private routeService: RouteService,
    private appInitService: AppInitService,
    private location: Location
  ) {}

  canActivateAppointments(): boolean {
    const { selectedService, selectedVaccine } = this.environmentService;
    if (selectedService != undefined && selectedVaccine != undefined) {
      return true;
    } else {
      this.router.navigate([this.routeService.getServicesRoute()]);
      return false;
    }
  }

  canActivateVaccines(): boolean {
    const { selectedService } = this.environmentService;
    if (selectedService != undefined) {
      return true;
    } else {
      this.router.navigate([this.routeService.getServicesRoute()]);
      return false;
    }
  }

  canActivateServices(): boolean {
    const services = this.environmentService.configuration?.services;
    if (services != undefined) {
      return true;
    } else {
      this.router.navigate([this.routeService.getConfigErrorRoute()]);
      return false;
    }
  }

  canActivateCoadmin(): boolean {
    const { selectedService, selectedVaccine } = this.environmentService;
    const { selectedAppointment } = this.appointmentService;
    if (
      selectedService != undefined &&
      selectedVaccine != undefined &&
      selectedAppointment != undefined &&
      selectedAppointment.location.coadminEnabled
    ) {
      return true;
    } else {
      this.router.navigate([this.routeService.getAppointmentsRoute()]);
      return false;
    }
  }

  // Used to determine if we can activate the Reservation Form and Review Screens
  // This is used to handle browser back - we're checking for the presence of a confirmationCode
  // on the reservation object
  async canActivateReservationFlow(): Promise<boolean> {
    const { reservation } = this.appointmentService;
    if (reservation?.confirmationCode) {
      this.appointmentService.reservation = undefined;
      this.environmentService.selectedService = undefined;
      this.environmentService.selectedVaccine = undefined;
      this.environmentService.campaignId = DEFAULT_START_ROUTE;

      // create a new session for the user
      this.environmentService.recreateUserId();

      // update the path in the url so when we configure in the next step we're routed properly to default
      this.location.replaceState(`${DEFAULT_START_ROUTE}`);

      // init the app again because we have to call the config service again since we are switching campaigns
      try {
        await this.appInitService.init(false).then(() => {
          this.appInitService.configureUrl();
        });
      } catch (error) {
        console.log(error);
        // TODO: do something with this error
      }

      this.router.navigate([this.routeService.getServicesRoute()]);
      return false;
    }
    return true;
  }

  canActivateReservation(): boolean {
    const { selectedService, selectedVaccine } = this.environmentService;
    const { selectedAppointment } = this.appointmentService;
    if (
      selectedService != undefined &&
      selectedVaccine != undefined &&
      selectedAppointment != undefined
    ) {
      return true;
    } else {
      this.router.navigate([this.routeService.getAppointmentsRoute()]);
      return false;
    }
  }

  canActivateReviewAndConfirmed(): boolean {
    const { selectedService, selectedVaccine } = this.environmentService;
    const { reservation } = this.appointmentService;
    if (
      selectedService != undefined &&
      selectedVaccine != undefined &&
      reservation?.appointmentId != undefined
    ) {
      return true;
    } else {
      this.router.navigate([this.routeService.getAppointmentsRoute()]);
      return false;
    }
  }
}
