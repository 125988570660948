<main class="container py-4 px-3">
  <header class="mb-3">
    <app-hero
      [currentStep]="3"
      [selectedVaccineName]="selectedVaccineName"
      [selectedAppointment]="selectedAppointment"
      [suppressHero]="isEmbedded"
    ></app-hero>
  </header>

  <div id="review-container" [class.border-none]="isEmbedded" [class.mt-4]="!isEmbedded">
    <h1 class="text-primary" [class.pt-5]="!isEmbedded">
      {{ 'APPOINTMENT.REVIEW.TITLE' | translate }}
    </h1>

    <app-appointment-detail-card></app-appointment-detail-card>

    <!-- Recaptcha error -->
    <div *ngIf="recaptchaError">
      <h5 class="disclaimer-error d-inline-block">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.953 2C6.465 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.493 2 11.953 2ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.567 4 11.953 4C16.391 4 20 7.589 20 12C20 16.411 16.411 20 12 20Z"
            fill="white"
          />
          <path d="M11 7H13V14H11V7ZM11 15H13V17H11V15Z" fill="white" />
        </svg>

        {{ recaptchaError }}
      </h5>
    </div>

    <!-- Service error -->
    <ng-container *ngIf="errors">
      <div *ngFor="let error of errors">
        <h5 id="disclaimer-error" class="d-inline-block">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.953 2C6.465 2 2 6.486 2 12C2 17.514 6.486 22 12 22C17.514 22 22 17.514 22 12C22 6.486 17.493 2 11.953 2ZM12 20C7.589 20 4 16.411 4 12C4 7.589 7.567 4 11.953 4C16.391 4 20 7.589 20 12C20 16.411 16.411 20 12 20Z"
              fill="white"
            />
            <path d="M11 7H13V14H11V7ZM11 15H13V17H11V15Z" fill="white" />
          </svg>

          {{ error.reason }}
        </h5>
      </div>
    </ng-container>

    <app-footer
      [leftButtonName]="'BUTTON.BACK' | translate"
      [leftButtonDisabled]="(loading$ | async) === true"
      (leftButtonClicked)="leftButtonClick()"
      [rightButtonLoading]="(loading$ | async) === true"
      [rightButtonDisabled]="
        (loading$ | async) === true || recaptchaRetryCount >= recaptchaRetryLimit
      "
      [rightButtonName]="'APPOINTMENT.REVIEW.CONFIRM_APPOINTMENT_BUTTON' | translate"
      [rightButtonIncludeRecaptcha]="true"
      (rightButtonClicked)="rightButtonClick($event)"
    ></app-footer>

    <div
      id="recaptcha-privacy"
      class="text-tertiary mb-4 mt-4"
      [innerHTML]="safeRecaptchaHtml"
    ></div>
  </div>
</main>
