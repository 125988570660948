<app-location-integration-level-base
  [location]="location"
  [shouldShowLocationFooter]="true"
  [shouldShowPhoneNumber]="false"
  (viewLocationDetail)="viewLocationDetail.emit(location)"
  *ngIf="location"
>
  <!-- Results -->
  <div
    #appointmentRow
    class="appointment-row mt-4 d-inline-flex"
    *ngIf="filteredAppointments.length > 0"
  >
    <ng-container *ngFor="let appointment of filteredAppointments.slice(0, numAppointmentsPerRow)">
      <button
        [attr.aria-label]="appointment.prettyTime + ' ' + (appointment.prettyDate | translate)"
        class="btn d-flex flex-column align-items-center time-button"
        [ngClass]="{
          'btn-primary': isEmbedded,
          'btn-outline-primary': !isEmbedded
        }"
        (click)="selectedAppointmentTime(appointment)"
      >
        {{ appointment.prettyTime }}
        <span
          [ngClass]="{
            'text-tertiary': !isEmbedded,
            'text-uppercase text-bold': isEmbedded
          }"
          aria-hidden="true"
        >
          {{ appointment.prettyDate | translate }}
        </span>
      </button>
    </ng-container>
  </div>

  <!-- No Results -->
  <div *ngIf="filteredAppointments.length <= 0" class="d-flex flex-column gap-3">
    <p class="text-uppercase text-tertiary text-small mb-0 mt-4">
      {{ 'APPOINTMENT.LOCATION.NO_RESULTS' | translate }}
    </p>

    <button
      *ngIf="appointments.length <= 0"
      (click)="getAppointmentTimes()"
      [disabled]="(loading$ | async) === true"
      [class.btn-dashed]="(loading$ | async) === true"
      class="btn btn-lg btn-outline-primary d-flex flex-column flex-fill align-items-center justify-content-center text-uppercase w-100 mt-2"
    >
      <ng-container *ngIf="(loading$ | async) === false">
        <span>{{ 'APPOINTMENT.LOCATION.RETRY' | translate }}</span>
      </ng-container>

      <ng-container *ngIf="(loading$ | async) === true">
        {{ 'APPOINTMENT.LOCATION.IN_PROGRESS' | translate }}
      </ng-container>
    </button>
  </div>
</app-location-integration-level-base>
