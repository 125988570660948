import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppointmentService } from 'src/app/common/services/appointment/appointment.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RouteService } from 'src/app/common/services/route/route.service';
import { Title } from '@angular/platform-browser';
import { getEmbedded, getEmbeddedAssetsUrl } from 'src/app/common/constants/general';

@Component({
  selector: 'app-appointment-reservation-error',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './appointment-reservation-error.component.html',
  styleUrls: ['./appointment-reservation-error.component.scss'],
})
export class AppointmentReservationErrorComponent implements OnInit {
  feelingBlueImageUrl = 'assets/feeling-blue.svg';

  constructor(
    private appointmentService: AppointmentService,
    private routeService: RouteService,
    private titleService: Title,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    if (!getEmbedded()) {
      this.titleService.setTitle(
        this.translateService.instant('PAGES.RESERVE_APPOINTMENT_ERROR.TITLE')
      );
    } else {
      this.feelingBlueImageUrl = `${getEmbeddedAssetsUrl()}/assets/feeling-blue.svg`;
    }
  }

  selectNewTime() {
    const { selectedAppointment } = this.appointmentService;

    if (selectedAppointment) {
      // Route to location detail screen
      const { location } = selectedAppointment;
      const router = this.routeService.getRouter();
      router.navigate([this.routeService.getLocationDetailsRoute(location.locationId)]);
    }
  }

  selectNewLocation() {
    const { selectedAppointment } = this.appointmentService;

    if (selectedAppointment) {
      // Route to appointment search screen
      const router = this.routeService.getRouter();
      router.navigate([this.routeService.getAppointmentsRoute()]);
    }
  }
}
