<div id="alert-prompt" class="modal p-3" aria-modal="true" role="dialog">
  <h2 *ngIf="titleTC" class="text-primary">{{ titleTC | translate }}</h2>
  <p *ngIf="subtitleTC" class="text-primary">{{ subtitleTC | translate }}</p>

  <div class="d-flex justify-content-between gap-3 pt-4">
    <button
      *ngIf="cancelTextTC"
      tabindex="0"
      (click)="cancel()"
      [innerText]="cancelTextTC | translate"
      class="btn btn-outline-primary text-uppercase d-flex flex-column flex-fill align-items-center m-auto justify-content-center"
    ></button>
    <button
      *ngIf="continueTextTC"
      tabindex="0"
      (click)="continue()"
      [innerText]="continueTextTC | translate"
      class="btn btn-primary text-uppercase d-flex flex-column flex-fill align-items-center w-100 m-auto justify-content-center"
    ></button>
  </div>
</div>
