import { Language } from '../model/language';

export let EMBEDDED = false;
export let EMBEDDED_CAMPAIGN_ID = '0';
export let EMBEDDED_ASSETS_URL = '';

export function setEmbedded(value: boolean) {
  EMBEDDED = value;
}

export function getEmbedded() {
  return EMBEDDED;
}

export function setEmbeddedCampaignId(campaignId: string) {
  EMBEDDED_CAMPAIGN_ID = campaignId;
}

export function getEmbeddedCampaignId() {
  return EMBEDDED_CAMPAIGN_ID;
}

export function setEmbeddedAssetsUrl(url: string) {
  EMBEDDED_ASSETS_URL = url;
}

export function getEmbeddedAssetsUrl() {
  return EMBEDDED_ASSETS_URL;
}

export const DEFAULT_LOCALE = 'en';
export const DEFAULT_APPOINTMENT_DURATION = 30;
// Locales the app supports for translations
export const SUPPORTED_LOCALES: Language[] = [
  {
    localeCode: 'en',
    localeNativeTranslation: 'English - EN',
  },
  {
    localeCode: 'es',
    localeNativeTranslation: 'Español - ES',
  },
];
export const APPOINTMENT_ITEM_WIDTH = 145; // width of the Appointment Time button within each LocationComponent
export const DATE_TIME_PICKER_TIME_WIDTH = 145; // width of the time button on the DateTimePickerComponent
export const DATE_TIME_PICKER_DEFAULT_WINDOW_DAYS = 14;
export const DATE_TIME_PICKER_DEFAULT_MIN_HOUR = 8;
export const DATE_TIME_PICKER_DEFAULT_MAX_HOUR = 20;
export const DATE_TIME_PICKER_EXTENDED_MIN_HOUR = 0;
export const DATE_TIME_PICKER_EXTENDED_MAX_HOUR = 23;
export const API_RETRY_LIMIT = 3; // number of times to retry failed API requests
export const RECAPTCHA_RETRY_LIMIT = 2;
export const VACCINE_ELIGIBILITY_WIDTH_BREAKPOINT = 632;

// https://lextech.atlassian.net/wiki/spaces/GSKVAX/pages/2928869389/Retailer+Adaptor+API+Error+States
export enum ServiceErrorCodes {
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
  UNAUTHORIZED = 401,
  SUBCODE_VALIDATION_ERROR = 1000,
  SUBCODE_APPOINTMENT_NOT_AVAILABLE = 1001,
  SUBCODE_RETAILER_ERROR = 1002,
  SUBCODE_INVALID_SEARCH_ADDRESS = 1003,
  SUBCODE_RECAPTCHA_ERROR = 1004,
  SUBCODE_PRESCRIPTION_REQUIRED_ERROR = 1005,
  SUBCODE_SEASONAL_VACCINE_NOT_AVAILABLE = 1006,
  SUBCODE_PATIENT_AGE_RESTRICTION = 1007,
  SUBCODE_PATIENT_INELIGIBLE = 1008,
  SUBCODE_INCOMPATIBLE_VACCINES = 1009,
}

export enum VaccineEligibilityQuestionType {
  SINGLE,
  MULTIPLE,
}

export enum VaccineEligibilityStates {
  QUIZ,
  RECOMMENDATION,
}

export enum VaccineEligibilityWidgetStates {
  FULL,
  MEDIUM,
  SMALL,
}
