<div class="mt-4 mb-4" [appInert]="isAlertPromptVisible$ | async">
  <!-- Vaccination -->
  <h3
    id="service-subheader"
    class="text-tertiary text-regular text-semibold text-uppercase pt-2 mb-2"
  >
    {{ 'APPOINTMENT.REVIEW.VACCINATION_SUBHEADER' | translate }}
  </h3>

  <h2 id="service-name" class="text-primary mb-1">
    {{ selectedVaccineName }}
  </h2>

  <!-- Day and Time-->
  <h3
    id="datetime-subheader"
    class="text-tertiary text-regular text-semibold text-uppercase mb-2 pt-4"
  >
    {{ 'APPOINTMENT.REVIEW.DATE_SUBHEADER' | translate }}
  </h3>

  <h2 id="appointment-date" class="text-primary mb-1">
    {{ prettyDate | translate }}, {{ prettyTime }}
  </h2>

  <!-- Add to Calendar Button -->
  <button
    *ngIf="reservation?.confirmationCode"
    id="add-to-calendar-button"
    (click)="addToCalendar()"
    class="btn btn-outline-primary text-uppercase text-semibold d-flex flex-column flex-fill align-items-center w-100 h-100 justify-content-center mt-4"
  >
    {{ 'APPOINTMENT.CONFIRMATION.CALENDAR_BUTTON' | translate }}
  </button>

  <!-- Location -->
  <h3
    id="location-subheader"
    class="text-tertiary text-regular text-semibold text-uppercase mb-2 pt-4"
  >
    {{ 'APPOINTMENT.REVIEW.LOCATION_SUBHEADER' | translate }}
  </h3>

  <div class="d-flex gap-3 mt-2 mb-1">
    <div *ngIf="location?.logoPath">
      <img class="logo" [src]="location.logoPath" alt="" />
    </div>
    <div>
      <h2 id="location-name" class="mb-0 text-primary">{{ location.locationName }}</h2>

      <app-location-address
        [address]="location.address"
        [distance]="location.distance"
        [city]="location.city"
        [state]="location.state"
      ></app-location-address>
    </div>
  </div>

  <app-location-contacts
    *ngIf="reservation?.confirmationCode"
    [location]="location"
    [hideDirections]="false"
    (openExternalUrl)="openExternalUrl($event)"
    (sendAnalyticsEvent)="sendAnalyticsEvent()"
  ></app-location-contacts>

  <!-- For -->
  <h3 id="for-header" class="text-tertiary text-regular text-semibold text-uppercase mb-2 pt-4">
    {{ 'APPOINTMENT.REVIEW.FOR' | translate }}
  </h3>

  <h2 class="mb-0 text-primary">
    {{ reservation?.additionalData?.firstName }} {{ reservation?.additionalData?.lastName }}
  </h2>

  <p class="text-primary m-0">
    {{ reservation?.additionalData?.email }}
  </p>
  <p class="text-primary">
    {{ reservation?.additionalData?.phone }}
  </p>
</div>

<app-alert-prompt
  *ngIf="isAlertPromptVisible$ | async"
  [titleTC]="alertPrompt?.titleTC"
  [subtitleTC]="alertPrompt?.subtitleTC"
  [cancelTextTC]="alertPrompt?.cancelTextTC"
  [continueTextTC]="alertPrompt?.continueTextTC"
  (cancelAction)="alertPromptCancel()"
  (continueAction)="alertPromptContinue()"
></app-alert-prompt>
