<app-location-integration-level-base
  [location]="location"
  [shouldShowLocationFooter]="false"
  [shouldShowPhoneNumber]="true"
  (viewLocationDetail)="viewLocationDetail.emit(location)"
  *ngIf="location"
>
  <div class="position-relative d-inline-flex">
    <!-- Show Call to check if we have the phone -->
    <p *ngIf="location.phone" class="text-uppercase text-tertiary text-small mb-0 mt-4">
      {{ 'APPOINTMENT.LOCATION.CALL_CHECK_AVAILABILITY' | translate }}
    </p>
    <!-- Show Check website for availability if we have informationalUrl with no phone -->
    <p
      *ngIf="!location.phone && location.informationalUrl"
      class="text-uppercase text-tertiary text-small mb-0 mt-4"
    >
      {{ 'APPOINTMENT.LOCATION.INFORMATIONAL_WEBSITE_CHECK_AVAILABILITY' | translate }}
    </p>
  </div>

  <!-- Level 1 Buttons -->
  <div class="d-flex flex-row gap-3">
    <!-- PHONE BUTTON -->
    <a
      *ngIf="location.phone"
      [attr.aria-label]="('APPOINTMENT.LOCATION.CALL' | translate) + ' ' + location.locationName"
      (click)="
        setAnalyticsEvent('call-initiated', 'phone-link');
        sendAnalyticsEvent.emit();
        sendGTMEvent('DC-15065329/evax000/callphar+unique');
        sendTTDEvent('u02kcea')
      "
      href="tel:{{ location.phone }}"
      class="btn btn-lg btn-outline-primary d-flex flex-column flex-wrap flex-fill gap-1 align-items-center text-uppercase justify-content-center w-100 mt-4"
    >
      <span>
        <svg
          class="icon"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.707 10.2932C15.6142 10.2003 15.504 10.1265 15.3827 10.0762C15.2614 10.0259 15.1313 10 15 10C14.8687 10 14.7386 10.0259 14.6173 10.0762C14.496 10.1265 14.3858 10.2003 14.293 10.2932L12.699 11.8872C11.96 11.6672 10.581 11.1672 9.707 10.2932C8.833 9.4192 8.333 8.0402 8.113 7.3012L9.707 5.7072C9.79994 5.61441 9.87368 5.50421 9.92399 5.38289C9.9743 5.26158 10.0002 5.13153 10.0002 5.0002C10.0002 4.86887 9.9743 4.73882 9.92399 4.61751C9.87368 4.49619 9.79994 4.38599 9.707 4.2932L5.707 0.293201C5.61421 0.200255 5.50401 0.126518 5.38269 0.0762068C5.26138 0.0258961 5.13133 0 5 0C4.86866 0 4.73862 0.0258961 4.6173 0.0762068C4.49599 0.126518 4.38579 0.200255 4.293 0.293201L1.581 3.0052C1.201 3.3852 0.986998 3.9072 0.994998 4.4402C1.018 5.8642 1.395 10.8102 5.293 14.7082C9.191 18.6062 14.137 18.9822 15.562 19.0062H15.59C16.118 19.0062 16.617 18.7982 16.995 18.4202L19.707 15.7082C19.7999 15.6154 19.8737 15.5052 19.924 15.3839C19.9743 15.2626 20.0002 15.1325 20.0002 15.0012C20.0002 14.8699 19.9743 14.7398 19.924 14.6185C19.8737 14.4972 19.7999 14.387 19.707 14.2942L15.707 10.2932ZM15.58 17.0052C14.332 16.9842 10.062 16.6492 6.707 13.2932C3.341 9.9272 3.015 5.6422 2.995 4.4192L5 2.4142L7.586 5.0002L6.293 6.2932C6.17546 6.41065 6.08904 6.55554 6.04155 6.71477C5.99406 6.874 5.987 7.04256 6.021 7.2052C6.045 7.3202 6.632 10.0472 8.292 11.7072C9.952 13.3672 12.679 13.9542 12.794 13.9782C12.9565 14.0132 13.1253 14.0067 13.2846 13.9593C13.444 13.9119 13.5889 13.8252 13.706 13.7072L15 12.4142L17.586 15.0002L15.58 17.0052Z"
          />
        </svg>
        {{ 'APPOINTMENT.LOCATION.CALL' | translate }}
      </span>
    </a>

    <!-- INFORMATIONAL WEBSITE BUTTON -->
    <button
      *ngIf="location.informationalUrl"
      [attr.aria-label]="
        location.locationName +
        ' ' +
        ('APPOINTMENT.LOCATION.INFORMATIONAL_WEBSITE_BUTTON' | translate)
      "
      (click)="
        sendGTMEvent('DC-15065329/evax000/pharsite+unique');
        sendTTDEvent('k7gh3oq');
        setAnalyticsEvent('website-transfer', 'informational-url');
        setAlertPrompt('pharmacy-website');
        openExternalUrl.emit(location.informationalUrl)
      "
      class="btn btn-outline-primary btn-lg d-flex flex-column flex-fill align-items-center justify-content-center text-uppercase w-100 mt-4"
    >
      <span>{{ 'APPOINTMENT.LOCATION.INFORMATIONAL_WEBSITE_BUTTON' | translate }}</span>
    </button>
  </div>
</app-location-integration-level-base>
