import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BehaviorSubject, Observable } from 'rxjs';
import { DateTime } from 'luxon';
import { VaccineEligibilityQuestionsComponent } from './vaccine-eligibility-questions/vaccine-eligibility-questions.component';
import { VaccineEligibilityRecommendationsComponent } from './vaccine-eligibility-recommendations/vaccine-eligibility-recommendations.component';
import { VaccineEligibilityService } from 'src/app/common/services/vaccine-eligibility/vaccine-eligibility.service';
import { RouteService } from 'src/app/common/services/route/route.service';
import { EventService } from 'src/app/common/services/event/event.service';
import { VaccineEligibilityRequest } from 'src/app/common/model/vaccine-eligibility';
import { Service } from 'src/app/common/model/service';
import { AnalyticsEvent } from 'src/app/common/model/event';
import { EnvironmentService } from 'src/app/common/services/environment/environment.service';
import {
  getEmbedded,
  VACCINE_ELIGIBILITY_WIDTH_BREAKPOINT,
  VaccineEligibilityStates,
  VaccineEligibilityWidgetStates,
} from 'src/app/common/constants/general';
import { AppointmentService } from 'src/app/common/services/appointment/appointment.service';
import { DEFAULT_START_ROUTE } from 'src/app/app.routes';
import { AppInitService } from 'src/app/app-init.service';
import { CurrentScreenType } from 'src/app/common/types/current-screen-type';

@Component({
  selector: 'app-vaccine-eligibility-quiz',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    CollapseModule,
    VaccineEligibilityQuestionsComponent,
    VaccineEligibilityRecommendationsComponent,
  ],
  templateUrl: './vaccine-eligibility-quiz.component.html',
  styleUrls: ['./vaccine-eligibility-quiz.component.scss'],
})
export class VaccineEligibilityQuizComponent implements OnInit, OnDestroy {
  @ViewChild('vaccineEligibilityQuestionsComponent')
  vaccineEligibilityQuestionsComponent!: VaccineEligibilityQuestionsComponent;
  @Input() currentScreen: CurrentScreenType = 'vaccine-picker';
  @Output() isLoading = new EventEmitter(false);

  loadingObj: BehaviorSubject<boolean> = new BehaviorSubject(false);
  loading$: Observable<boolean>;

  submittingObj: BehaviorSubject<boolean> = new BehaviorSubject(false);
  submitting$: Observable<boolean>;

  vaccineEligibilityWidgetStateObj = new BehaviorSubject<VaccineEligibilityWidgetStates>(
    VaccineEligibilityWidgetStates.FULL
  );
  vaccineEligibilityWidgetStates = VaccineEligibilityWidgetStates;

  vaccineEligibilityState$: Observable<VaccineEligibilityStates> =
    this.vaccineEligibilityService.getVaccineEligibilityState();
  vaccineEligibilityStates = VaccineEligibilityStates;

  get isOpen(): boolean {
    return this.vaccineEligibilityService.isEligibilityQuizOpen;
  }

  set loading(isLoading: boolean) {
    this.loadingObj.next(isLoading);
    this.isLoading.emit(isLoading);
  }

  get loading(): boolean {
    return this.loadingObj.getValue();
  }

  set submitting(isSubmitting: boolean) {
    this.submittingObj.next(isSubmitting);
  }

  get submitting(): boolean {
    return this.submittingObj.getValue();
  }

  get vaccineEligibilityWidgetState() {
    return this.vaccineEligibilityWidgetStateObj.getValue();
  }

  set vaccineEligibilityWidgetState(state: VaccineEligibilityWidgetStates) {
    this.vaccineEligibilityWidgetStateObj.next(state);
  }

  constructor(
    private vaccineEligibilityService: VaccineEligibilityService,
    private routeService: RouteService,
    private eventService: EventService,
    private environmentService: EnvironmentService,
    private appointmentService: AppointmentService,
    private location: Location,
    private appInitService: AppInitService
  ) {
    this.loading$ = this.loadingObj.asObservable();
    this.submitting$ = this.submittingObj.asObservable();
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    this.updateWidgetState();
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.updateWidgetState();
  }

  ngOnInit(): void {
    this.updateWidgetState();
  }

  updateWidgetState() {
    const { innerWidth, innerHeight, scrollY } = window;
    if (innerWidth > VACCINE_ELIGIBILITY_WIDTH_BREAKPOINT) {
      this.vaccineEligibilityWidgetState =
        scrollY > innerHeight
          ? VaccineEligibilityWidgetStates.MEDIUM
          : VaccineEligibilityWidgetStates.FULL;
    } else {
      this.vaccineEligibilityWidgetState =
        scrollY > innerHeight
          ? VaccineEligibilityWidgetStates.SMALL
          : VaccineEligibilityWidgetStates.MEDIUM;
    }
  }

  submitQuestionsForm(payload: Partial<VaccineEligibilityRequest>) {
    const { campaignId } = this.environmentService;

    const request: VaccineEligibilityRequest = {
      campaignId,
      ...payload,
    };

    this.submitting = true;

    this.vaccineEligibilityService.checkVaccineEligibility(request).subscribe({
      next: (resp) => {
        this.submitting = false;
        this.vaccineEligibilityService.setEligibilityResponse(resp);
        this.vaccineEligibilityService.vaccineEligibilityState =
          VaccineEligibilityStates.RECOMMENDATION;
      },
      error: (error: any) => {
        this.submitting = false;
        this.vaccineEligibilityService.setEligibilityResponse(null);
      },
    });
  }

  async submitRecommendationsForm(payload: Service) {
    this.loading = true;

    // Set the selected service for the analytics event
    this.environmentService.selectedService = payload;
    // if we only have 1 vaccine for the selectedService pre-select it and skip vaccine selection
    if (payload?.vaccines.length === 1) {
      this.environmentService.selectedVaccine = payload?.vaccines[0];
    }

    // Send analytics event
    const eventTimestamp = DateTime.local().toUTC().toString();
    const analyticsEvent: AnalyticsEvent = {
      eventType: 'get-eligibility',
      eventTimestamp: eventTimestamp,
      trigger: 'eligibility-recommendation-navigation',
    };
    this.eventService.setAnalyticsEvent(analyticsEvent);
    this.eventService.sendAnalyticsEvent();

    // Handle confirmation screen requirements
    if (this.currentScreen === 'appointment-confirmation') {
      // create a new session for the user after leaving confirmation
      this.environmentService.recreateUserId();
      // clear current saved reservation
      this.appointmentService.reservation = undefined;
    }

    // if this is an embedded experience, we don't want to redirect the user to the default route
    // they have to stay on the same campaign
    if (!getEmbedded()) {
      // clear current campaign state since we're defaulting to 0
      this.environmentService.configuration = null;
      this.environmentService.selectedService = undefined;
      this.environmentService.selectedVaccine = undefined;

      this.environmentService.campaignId = DEFAULT_START_ROUTE;

      // update the path in the url so when we configure in the next step we're routed properly to default
      this.location.replaceState(`${DEFAULT_START_ROUTE}`);
    }

    // init the app again because we have to call the config service again since we are switching campaigns
    let path = '';

    try {
      await this.appInitService
        .init(false)
        .catch((error) => {
          this.loading = false;
        })
        .then(() => {
          this.appInitService.configureUrl();
          // reconfigure selected vaccines so the routes are correct
          this.environmentService.selectedService = payload;
          path = this.routeService.getVaccinesRoute();
          if (payload?.vaccines.length === 1) {
            this.environmentService.selectedVaccine = payload?.vaccines[0];
            path = this.routeService.getAppointmentsRoute();
          }

          this.loading = false;
        });
    } catch (error) {
      this.loading = false;
    }

    this.vaccineEligibilityService.toggleElibilityQuizIsOpen();

    if (path) {
      const router = this.routeService.getRouter();
      router.navigate([path]);
    }
  }

  toggleIsOpen() {
    this.vaccineEligibilityService.toggleElibilityQuizIsOpen();

    // Send event when the user opens the eligibility widget
    if (this.isOpen) {
      // Analytics event
      const eventTimestamp = DateTime.local().toUTC().toString();
      const analyticsEvent: AnalyticsEvent = {
        eventType: 'get-eligibility',
        eventTimestamp: eventTimestamp,
        trigger: 'eligibility-started',
        currentScreen: this.currentScreen,
      };
      this.eventService.setAnalyticsEvent(analyticsEvent);
      this.eventService.sendAnalyticsEvent();
    }
  }

  ngOnDestroy(): void {
    this.vaccineEligibilityService.markElementsAsInert(false);
  }

  restartFlow() {
    this.restartQuiz();
    this.vaccineEligibilityService.restartFlow();
  }

  restartQuiz() {
    this.vaccineEligibilityQuestionsComponent.restartQuiz();
  }
}
