import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  AnalyticsEventTriggerType,
  AnalyticsEventType,
} from 'src/app/common/types/track-event-type';
import { Location } from 'src/app/common/model/location';
import { EventService } from 'src/app/common/services/event/event.service';
import { AlertPromptType } from 'src/app/common/types/alert-prompt-type';
import { AlertPromptBase } from 'src/app/common/model/alert-prompt/alert-prompt-base';
import { AlertPromptDirections } from 'src/app/common/model/alert-prompt/alert-prompt-directions';
import { AlertPromptPharmacyWebsite } from 'src/app/common/model/alert-prompt/alert-prompt-pharmacy-website';
import { AlertPromptExternalUrlService } from 'src/app/common/services/alert-prompt/alert-prompt-external-url.service';
import { DateTime } from 'luxon';

@Component({
  selector: 'app-location-contacts',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './location-contacts.component.html',
  styleUrls: ['./location-contacts.component.scss'],
})
export class LocationContactsComponent implements OnInit {
  @Input({ required: true }) location!: Location;
  @Input() hideDirections = false; // Whether or not to hide the directions button
  @Output() openExternalUrl: EventEmitter<string> = new EventEmitter<string>();
  @Output() sendAnalyticsEvent: EventEmitter<void> = new EventEmitter<void>();

  directionsLink = '';

  constructor(
    private eventService: EventService,
    private alertPromptExternalUrlService: AlertPromptExternalUrlService
  ) {}

  ngOnInit() {
    const { location } = this;
    if (location?.lat && location?.lng) {
      this.directionsLink = `https://www.google.com/maps/dir/?api=1&destination=${location?.lat},${location?.lng}`;
    }
  }

  /**
   * Analytic Events Handling
   */
  setAnalyticsEvent(eventType: AnalyticsEventType, trigger: AnalyticsEventTriggerType) {
    const eventTimestamp = DateTime.local().toUTC().toString();
    const analyticsEvent = {
      eventType: eventType,
      eventTimestamp: eventTimestamp,
      trigger: trigger,
      location: this.location,
    };

    this.eventService.setAnalyticsEvent(analyticsEvent);
  }

  emitSendAnalyticsEvent() {
    this.sendAnalyticsEvent.emit();
  }

  /**
   * Alert Prompt Handling
   */
  public setAlertPrompt(type: AlertPromptType) {
    let prompt: AlertPromptBase<any> | undefined;
    switch (type) {
      case 'directions':
        prompt = new AlertPromptDirections();
        break;
      case 'pharmacy-website':
        prompt = new AlertPromptPharmacyWebsite();
        break;
      default:
        break;
    }
    if (prompt) {
      this.alertPromptExternalUrlService.setAlertPrompt(prompt);
    }
  }

  public emitOpenExternalUrl(url: string) {
    this.openExternalUrl.emit(url);
  }
}
