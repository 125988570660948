import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-location-address',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './location-address.component.html',
  styleUrls: ['./location-address.component.scss'],
})
export class LocationAddressComponent {
  @Input() address?: string;
  @Input() distance?: number;
  @Input() city?: string;
  @Input() state?: string;
}
