import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-loading-time-picker',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './loading-time-picker.component.html',
  styleUrls: ['./loading-time-picker.component.scss']
})
export class LoadingTimePickerComponent {

}
